<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <h1>List of known names</h1>
      <div class="row">
        <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <app-loader *ngIf="showLoader"></app-loader>
              <table class="table table-stribed" *ngIf="knownNames">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Country</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let k of knownNames">
                    <td>{{ k.name }}</td>
                    <td>
                      <i *ngIf="k.region && k.region != 'All' && k.region != 'unknown'; else unknown" class="flag-icon flag-icon-{{k.region}}"></i>
                      <ng-template #unknown>
                        {{ k.region }}
                      </ng-template>
                    </td>
                    <td><a [routerLink]="'/persons/' + k.id" class="btn btn-sm btn-outline-success">View Person</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>