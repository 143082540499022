import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessedImageService } from 'src/app/services/images.service';
import { Nationality } from 'src/app/model/Nationality';
import { IdentifiedFace } from 'src/app/model/IdentifiedFace';
import { Statistics } from 'src/app/model/Statistics';
import { StatisticsService } from 'src/app/services/statistics.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Person } from 'src/app/model/Person';
import { NotificationsService } from 'src/app/services/notifications.service';
import { FaceLister } from 'src/app/interfaces/FaceLister';
import { VerificationQueueState } from 'src/app/state/VerificationQueueState';
import { TitleService } from 'src/app/services/title.service';
import {ContextMenuComponent, ContextMenuService} from '@perfectmemory/ngx-contextmenu';

@Component({
  selector: 'app-verification-queue',
  templateUrl: './verification-queue.component.html',
  styleUrls: ['./verification-queue.component.css']
})
export class VerificationQueueComponent implements OnInit, FaceLister {

  showLoader = true;
  title = 'Show Pending Verifications';
  regions: Nationality[];

  state: VerificationQueueState;

  allfaces: IdentifiedFace[];
  faces: IdentifiedFace[];

  public selectedPerson: Person;
  statistics: Statistics;

  @ViewChild('faceMenu') contextMenu: ContextMenuComponent<any>;

  constructor(
    private router: Router,
    private imagesService: ProcessedImageService,
    private statisticsService: StatisticsService,
    private authenticationService: AuthenticationService,
    private notifications: NotificationsService,
    private contextMenuService: ContextMenuService<any>,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    const t = localStorage.getItem('state-verification-queue');
    this.titleService.setTitle('Verification Queue');
    if (t) {
      this.state = JSON.parse(t);
    } else {
      this.state = new VerificationQueueState();
    }

    this.statisticsService.getPendingStatistics().subscribe(stats => {
      this.statistics = stats;
      this.showLoader = false;
    });
    this.imagesService.getNationalities().subscribe(n => {
      this.regions = n.filter(r => this.authenticationService.hasAccessTo(r.code));
      this.setRegion(this.regions[0]);
    });
  }


  showFaceContextMenu($event: MouseEvent, face: IdentifiedFace): void {
    console.log('Showing face menu for #' + face.id);
    this.contextMenuService.show(
      this.contextMenu,
      {
      x: $event.x,
      y: $event.y,
      value: face,
    });
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }


  public getPending(code: string): number | Statistics {
    if (this.statistics && this.statistics[code]) {
      return this.statistics[code];
    } else {
      return 0;
    }
  }

  verifyFace(face: IdentifiedFace) {
    face.showLoader = true;
    this.imagesService.verifyFace(face).subscribe(updatedFace => {
      face.personVerified = updatedFace.personVerified;
      face.personVerifiedBy = updatedFace.personVerifiedBy;
      face.personVerifiedDate = updatedFace.personVerifiedDate;

      if (updatedFace.listOfVerified) {
        for (const id of updatedFace.listOfVerified) {
          for (const f of this.allfaces) {
            if (f.id === id) {
              f.personVerified = true;
              f.personVerifiedBy = 'AI';
              f.personVerifiedDate = new Date();
            }
          }
        }
      }


      face.showLoader = false;

      this.updateShown();
    });
  }


  setOrder(order: 'created_asc' | 'created_desc' | 'name') {
    this.state.order = order;

    switch (order) {
      case  'created_asc': {
        this.state.orderText = 'Oldest first';
        break;
      }
      case  'created_desc': {
        this.state.orderText = 'Newest first';
        break;
      }
      case  'name': {
        this.state.orderText = 'Name';
        break;
      }

    }

    this.getQueue();
  }


  unverifyFace(face: IdentifiedFace) {
    face.showLoader = true;
    this.imagesService.unverifyFace(face).subscribe(updatedFace => {
      face.personVerified = updatedFace.personVerified;
      face.personVerifiedBy = updatedFace.personVerifiedBy;
      face.personVerifiedDate = updatedFace.personVerifiedDate;
      face.showLoader = false;

      this.updateShown();
    });
  }

  public setRegion(region: Nationality) {
    this.state.selectedRegion = region;
    this.state.offset = 0;
    this.getQueue();
  }

  public showPerson(person) {
    this.router.navigate(['persons/' + person.id]);
  }

  public loadMoreFaces() {
    this.state.offset += this.state.limit;
    this.showLoader = true;
    this.imagesService.getPendingVerifications(this.state.selectedRegion, this.state.order, this.state.limit, this.state.offset).subscribe(
      (moreFaces) => {
        this.allfaces = this.allfaces.concat(moreFaces);
        this.updateShown();
        this.showLoader = false;
        this.title = 'Pending Verifications';
      }
      );
    }

    public getQueue(): void {
      this.faces = null; // for show spinner each time
      this.showLoader = true;
      this.state.offset = 0;
      this.imagesService.getPendingVerifications(this.state.selectedRegion, this.state.order, this.state.limit, this.state.offset).subscribe(faces => {
        this.allfaces = faces;
        this.updateShown();
        this.showLoader = false;
    });
  }

  public updateShown() {
    if (!this.state.selectedRegion) {
      return;
    }
    this.faces = this.allfaces.filter(f => (this.state.showAlreadyVerified || !f.personVerified));

    const count = this.allfaces.filter(f => !f.personVerified).length;
    this.saveState();
    if (count < 25 && this.getPending(this.state.selectedRegion.code) > 100) {
      this.title = 'Loading more faces to verify...';
      this.loadMoreFaces();
    }
  }

  viewImage(event, imageId: number) {
    if (event.ctrlKey === 1) {
      window.open('images/' + imageId, '_self');
    } else {
      this.router.navigate(['images/' + imageId]);
    }
  }

  deleteFace(face: IdentifiedFace) {
    face.showLoader = true;
    this.imagesService.deleteFace(face).subscribe(e => {
      this.allfaces = this.allfaces.filter((f) => f.id !== face.id);
      this.updateShown();
    });
  }

  setSelectedPerson(p: Person, me: any) {
    console.log('Selected: ' + p.name);
    me.selectedPerson = p;
  }

  saveState() {
    localStorage.setItem('state-verification-queue', JSON.stringify(this.state));
  }


  reprocessFace(face: IdentifiedFace) {
    face.showLoader = true;
    this.imagesService.reprocessFace(face).subscribe((updatedFace) => {
      if (face.person.id === updatedFace.person.id) {
        // still same person
        face.personVerified = updatedFace.personVerified;
        face.personVerifiedBy = updatedFace.personVerifiedBy;
        face.personVerifiedDate = updatedFace.personVerifiedDate;

        const msg = 'Face #' + face.id + ' is still attributed to Person #' + updatedFace.person.id + ': '
        + ((updatedFace.person.name) ? updatedFace.person.name : 'Not yet named');
        this.notifications.showInfo('Face seems matched correctly!', msg, 'info', 'Continue', false);

      } else {
        // face now attributed to new person
        const msg = 'Face from image #' + face.image.id + ' is now attributed to: '
        + ((updatedFace.person && updatedFace.person.name) ? updatedFace.person.name :  'Person ' + updatedFace.person.id);
        this.notifications.showInfo(
          'Face was rematched!',
          msg,
                 'success', 'Continue', false);
        this.allfaces = this.allfaces.filter((f) => f.id !== face.id);
        this.updateShown();
      }
      face.showLoader = false;
    }, (err) => {
      console.error(err);
      face.showLoader = false;
    });
  }

  createNewPerson(face: IdentifiedFace) {
    face.showLoader = true;
    this.imagesService.splitFaceToPerson(face, undefined).subscribe((f) => {
      face.showLoader = false;
      this.router.navigate(['persons/' + f.person.id]);
    });
  }

  assignToKnownPerson(face: IdentifiedFace) {
    if (!this.selectedPerson) {
      this.notifications.showInfo('Select Person first', '', 'warning', 'close', false);
      return;
    }
    face.showLoader = true;
    this.imagesService.splitFaceToPerson(face, this.selectedPerson.id).subscribe((f) => {
      this.allfaces = this.allfaces.filter((fa) => fa.id !== face.id);
      this.updateShown();
      this.notifications.showInfo('Face moved', 'Face #' + f.id + ' is now assigned to ' + this.selectedPerson.name, 'success', 'Close', false);
      face.showLoader = false;
    });
  }

  cancelAssignToKnown() {
    this.selectedPerson = undefined;
  }

  removeFace(id: number) {
    this.allfaces = this.allfaces.filter(f => f.id !== id);
  }

  updateFace(face: IdentifiedFace) {
    this.removeFace(face.id);
    this.allfaces.push(face);
  }

}
