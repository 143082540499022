<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12 grid-margin">
          <div class="card card-statistics">
            <div class="card-body p-0">
              <div *ngIf="!overallStats" class="row">
                <div class="col-md-12 col-lg-12">
                  <app-loader></app-loader>
                </div>
              </div>
              <div *ngIf="overallStats" class="row">
                <div class="col-md-6 col-lg-6">
                  <div  class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ getOverallStats('elvis_images') | numberSuffix }} images updated in DAM</h1>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6">
                  <div  class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ getOverallStats('elvis_faces') | numberSuffix }} faces tagged in DAM</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body p-0">
              <div *ngIf="!overallStats" class="row">
                <div class="col-md-12 col-lg-12">
                  <app-loader></app-loader>
                </div>
              </div>
              <div *ngIf="overallStats" class="row">
                <div class="col-md-6 col-lg-2">
                  <div  class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ getOverallStats('images') | numberSuffix }}</h1>
                      <p class="text-muted mb-0">Images Received in total</p>
                    </div>
                    <i class="icon-picture text-primary icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-2">
                  <div class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ getOverallStats('persons') | numberSuffix  }}</h1>
                      <p class="text-muted mb-0">Persons found</p>
                    </div>
                    <i class="icon-people text-primary icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-2">
                  <div class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ getOverallStats('verified_faces') | numberSuffix  }}</h1>
                      <p class="text-muted mb-0">Verified Faces</p>
                    </div>
                    <i class="icon-user-following text-primary icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-2">
                  <div class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ getOverallStats('no_faces') | numberSuffix  }}</h1>
                      <p class="text-muted mb-0">Images without Faces</p>
                    </div>
                    <i class="icon-user-unfollow text-primary icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-2">
                  <div class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ getOverallStats('elvis_not_found') | numberSuffix  }}</h1>
                      <p class="text-muted mb-0">Images not found in DAM</p>
                    </div>
                    <i class="icon-close text-primary icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-2">
                  <div class="d-flex justify-content-between card-statistics-item">
                    <div>
                      <h1>{{ getOverallStats('elvis_updates') | numberSuffix  }}</h1>
                      <p class="text-muted mb-0">Updates written to DAM</p>
                    </div>
                    <i class="icon-refresh text-primary icon-lg"></i>
                  </div>
                </div>
              </div>
              <div *ngIf="overallStats" class="row">
                <div class="col-md-6 col-lg-2">
                  <div  class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ getOverallStats('persons_named') | number }}</h1>
                      <p class="text-muted mb-0">Manually Named Persons</p>
                    </div>
                    <i class="icon-people text-primary icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-2">
                  <div class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ getOverallStats('persons_named_ai') | number }}</h1>
                      <p class="text-muted mb-0">AI Named Persons</p>
                    </div>
                    <i class="icon-people text-primary icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-2">
                  <div class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ getOverallStats('unnamed_persons') | numberSuffix  }}</h1>
                      <p class="text-muted mb-0">Unnamed Persons</p>
                    </div>
                    <i class="icon-people text-primary icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-2">
                  <div class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ getOverallStats('total_faces') | numberSuffix  }}</h1>
                      <p class="text-muted mb-0">Faces found in total</p>
                    </div>
                    <i class="icon-user text-primary icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-2">
                  <div class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ getOverallStats('ignored_faces') | numberSuffix  }}</h1>
                      <p class="text-muted mb-0">Ignored Faces</p>
                    </div>
                    <i class="icon-user-unfollow text-primary icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-2">
                  <div class="d-flex justify-content-between card-statistics-item">
                    <div>
                      <h1>{{ getOverallStats('ai_verifications') | numberSuffix  }}</h1>
                      <p class="text-muted mb-0">AI Face Verifications</p>
                    </div>
                    <i class="icon-user-following text-primary icon-lg"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Pending Naming</h4>
              <app-loader *ngIf="!pendingNamingStats"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="pending-naming-report" width="100" height="100"></canvas>
              </div>
              <div class="py-4 d-flex justify-content-center align-items-end" *ngIf="pendingNamingStats">
                <h1 class="text-center text-md-left mb-0">{{ getPendingTotal(pendingNamingStats) | numberSuffix }}</h1>
                <p class="text-muted mb-0 ml-2">Persons with more than 20 images are pending naming</p>
              </div>
              <div id="pending-naming-report-legend" class="earning-report-legend"></div>
            </div>
          </div>
        </div>
        <div class="col-md-3 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Pending Manual Verification</h4>
              <app-loader *ngIf="!pendingStats"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="pending-report" width="100" height="100"></canvas>
              </div>
              <div class="py-4 d-flex justify-content-center align-items-end" *ngIf="pendingStats">
                <h1 class="text-center text-md-left mb-0">{{getPendingTotal(pendingStats) | numberSuffix }}</h1>
                <p class="text-muted mb-0 ml-2">Pending for identified persons</p>
              </div>
              <div id="pending-report-legend" class="earning-report-legend"></div>
            </div>
          </div>
        </div>
        <div class="col-md-3 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Unnamed Faces</h4>
              <app-loader *ngIf="!pendingUnnamedStats"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="pending-unnamed-report" width="100" height="100"></canvas>
              </div>
              <div class="py-4 d-flex justify-content-center align-items-end" *ngIf="pendingUnnamedStats">
                <h1 class="text-center text-md-left mb-0">{{getPendingTotal(pendingUnnamedStats) | numberSuffix }}</h1>
                <p class="text-muted mb-0 ml-2">Pending for unnamed persons</p>
              </div>
              <div id="pending-unnamed-report-legend" class="earning-report-legend"></div>
            </div>
          </div>
        </div>
        <div class="col-md-3 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Images Received</h4>
              <app-loader *ngIf="!uploadPerRegionStats"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="uploaded-report" width="100" height="100"></canvas>
              </div>
              <div class="py-4 d-flex justify-content-center align-items-end" *ngIf="uploadPerRegionStats">
                <h1 class="text-center text-md-left mb-0">{{getPendingTotal(uploadPerRegionStats) | numberSuffix }}</h1>
                <p class="text-muted mb-0 ml-2">Images Received</p>
              </div>
              <div id="uploaded-report-legend" class="earning-report-legend"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="row h-100">
              <div class="col-md-4 border-right">
                <div class="card-body">
                    <h4 class="card-title">This month</h4>
                  <app-loader *ngIf="!imageUploadStats || !currentMonthStats"></app-loader>
                  <table class="table table-borderless">
                    <tbody>
                      <tr *ngIf="imageUploadStats">
                        <td>
                          <h6>Images</h6>
                          <p class="text-muted mb-0">{{getCurrentMonthUploadChange()}}% change since last month</p>
                        </td>
                        <td>
                          <h3 class="text-primary">
                            {{getCurrentMonthUploads() | number }}
                          </h3>
                        </td>
                      </tr>
                      <tr *ngIf="currentMonthStats">
                        <td>
                          <h6>New Persons Seen</h6>
                          <p class="text-muted mb-0">Since begining of month</p>
                        </td>
                        <td>
                          <h3 class="text-danger">
                            {{getCurrentMonthStats('new_persons') | number }}
                          </h3>
                        </td>
                      </tr>
                      <tr *ngIf="currentMonthStats">
                        <td>
                          <h6>New Persons Named</h6>
                          <p class="text-muted mb-0">Of the persons discovered this month</p>
                        </td>
                        <td>
                          <h3 class="text-danger">
                            {{getCurrentMonthStats('new_persons_named') | number }}
                          </h3>
                        </td>
                      </tr>
                      <tr *ngIf="currentMonthStats">
                        <td>
                          <h6>AI Verifications</h6>
                          <p class="text-muted mb-0">Handled automatically</p>
                        </td>
                        <td>
                          <h3 class="text-success">
                            {{getCurrentMonthStats('ai_verifications') | number }}
                          </h3>
                        </td>
                      </tr>
                      <tr *ngIf="currentMonthStats">
                        <td>
                          <h6>Manual Verifications</h6>
                          <p class="text-muted mb-0">Done through UI</p>
                        </td>
                        <td>
                          <h3 class="text-warning">
                            {{getCurrentMonthStats('manual_verifications') | number }}
                          </h3>
                        </td>
                      </tr>
                  </tbody>
                </table><br />
                <h4 class="card-title">Total</h4>
                <table class="table table-borderless">
                    <tbody>
                      <tr *ngIf="currentMonthStats">
                        <td>
                          <h6>Pending Verifications</h6>
                          <p class="text-muted mb-0">Both named and not yet named persons</p>
                        </td>
                        <td>
                          <h3 class="text-warning">
                              {{getCurrentMonthStats('total_pending_verifications') | numberSuffix }}
                          </h3>
                        </td>
                      </tr>
                      <tr *ngIf="currentMonthStats">
                        <td>
                          <h6>Pending Naming</h6>
                          <p class="text-muted mb-0">Persons waiting to be named</p>
                        </td>
                        <td>
                          <h3 class="text-primary">
                              {{getCurrentMonthStats('total_pending_identifications') | numberSuffix }}
                          </h3>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-8">
                <div class="card-body d-flex flex-column h-100">
                  <div class="d-flex flex-row">
                    <h4 class="card-title">Receive statistics</h4>
                  </div>
                  <p>Image received per month</p>
                  <canvas id="chart-activity" class="mt-auto"></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Latest Manual Verifications</h4>
              <app-loader *ngIf="!latestUserVerifications"></app-loader>
              <table class="table" *ngIf="latestUserVerifications">
                <thead>
                  <tr>
                    <th></th>
                    <th>Company</th>
                    <th>Person</th>
                    <th>Verified by</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let f of latestUserVerifications">
                    <td>
                      <div>
                        <a [routerLink]="'/images/' + f.image.id">
                          <img [src]="'processed-images/faces/' + f.id + '.png'" class="img-sm mr-4" />
                        </a>
                      </div>
                    </td>
                    <td>
                        <p class="text-muted mb-0"><i class="flag-icon flag-icon-{{f.person.nationality}}" *ngIf="f.person.nationality"></i> {{getRegionName(f.person.nationality)}}</p>
                    </td>
                    <td>
                        <p class="text-muted mb-0"><a [routerLink]="'/persons/' + f.person.id">{{f.person.name}}</a></p>
                    </td>
                    <td>
                        <p class="text-muted mb-0">{{f.personVerifiedBy}}<br />(AI: {{f.personConfidence | number }} %)</p>
                    </td>
                    <td>
                      <p>{{f.personVerifiedDate | date:'yyyy-MMM-dd  HH:mm:ss' }}</p>
                      <p class="text-muted mb-0">{{f.personVerifiedDate | amTimeAgo}}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-6 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Latest AI Verifications</h4>
              <app-loader *ngIf="!latestAIVerifications"></app-loader>
              <table class="table" *ngIf="latestAIVerifications">
                <thead>
                  <tr>
                    <th></th>
                    <th>Company</th>
                    <th>Person</th>
                    <th>Verified by</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let f of latestAIVerifications">
                    <td>
                      <div>
                          <a [routerLink]="'/images/' + f.image.id">
                            <img [src]="'processed-images/faces/' + f.id + '.png'" class="img-sm mr-4" />
                          </a>
                      </div>
                    </td>
                    <td>
                        <p class="text-muted mb-0"><i class="flag-icon flag-icon-{{f.person.nationality}}" *ngIf="f.person.nationality"></i> {{getRegionName(f.person.nationality)}}</p>
                    </td>
                    <td>
                        <p class="text-muted mb-0"><a [routerLink]="'/persons/' + f.person.id">{{f.person.name}}</a></p>
                    </td>
                    <td>
                        <p class="text-muted mb-0">{{f.personVerifiedBy}}: {{f.personConfidence | number }} %</p>
                    </td>
                    <td>
                      <p>{{f.personVerifiedDate | date:'yyyy-MMM-dd  HH:mm:ss' }}</p>
                      <p class="text-muted mb-0">{{f.personVerifiedDate | amTimeAgo}}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
