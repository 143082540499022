<div class="container-fluid page-body-wrapper">
  <div class="main-panel">

    <div class="content-wrapper">
      <div>
        <div class="col-12 grid-margin">
          <button class="btn btn-outline-primary" (click)="addRouteToBoard()">Add Route</button>
        </div>
        <div class="col-12 grid-margin">
          <editor-wrapper #editorWrapper></editor-wrapper>
        </div>
      </div>

    </div>
  </div>
</div>
