<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
      <div class="content-wrapper">
        <h1>Adjust System wide concurrency settings</h1>
        <div class="row">
          <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
            <div class="card">
              <div class="card-body">
                <div class="col-6" *ngIf="true">
                  <h3>System Concurrency Settings</h3>
                  <app-loader *ngIf="showConcurrencyLoader"></app-loader>
                  <div *ngIf="concurrencySettings">

                    <div class="form-group">
                      <label>Concurrent S3 Events</label>
                      <div class="input-group">
                        <input class="form-control" type="number" max="500" step="1" style="text-align: right"
                        [(ngModel)]="concurrencySettings.s3EventsConcurrent"
                        [readOnly]="!authService.hasKeyCoreAccess()" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Concurrent Image Processing Tasks</label>
                      <div class="input-group">
                        <input class="form-control" type="number" max="500" step="1" style="text-align: right"
                        [(ngModel)]="concurrencySettings.faceDetectionConcurrent"
                        [readOnly]="!authService.hasKeyCoreAccess()" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Concurrent High Priority Image Processing Tasks</label>
                      <div class="input-group">
                        <input class="form-control" type="number" max="500" step="1" style="text-align: right"
                        [(ngModel)]="concurrencySettings.priorityFaceDetectionConcurrent"
                        [readOnly]="!authService.hasKeyCoreAccess()" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Concurrent Person Matching Tasks</label>
                      <div class="input-group">
                        <input class="form-control" type="number" max="500" step="1" style="text-align: right"
                        [(ngModel)]="concurrencySettings.personMatcherConcurrent"
                        [readOnly]="!authService.hasKeyCoreAccess()" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Concurrent DAM Updates</label>
                      <div class="input-group">
                        <input class="form-control" type="number" max="500" step="1" style="text-align: right"
                        [(ngModel)]="concurrencySettings.elvisUpdatesConcurrent"
                          [readOnly]="!authService.hasKeyCoreAccess()" />
                      </div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-outline-primary btn-block" (click)="saveConcurrencySettings()"
                          *ngIf="!saveConcurrencyInProgress" type="submit" [disabled]="!authService.hasKeyCoreAccess()"
                          style="margin: 0px;">Save Concurrency Settings</button>
                        <app-loader *ngIf="saveConcurrencyInProgress"></app-loader>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
            <div class="card">
              <div class="card-body">
                <div class="col-12" *ngIf="true">
                  <h3>Queue Processing Settings - Imports<i class="icon-reload" (click)="getQueueStates()"></i></h3>
                  <div>
                    <div class="form-group">
                      <label>Process S3 Events - current status: {{ s3EventsStatus }}</label>
                      <app-inline-loader *ngIf="s3EventsShowLoader"></app-inline-loader>
                      <div class="input-group" *ngIf="!s3EventsShowLoader">
                        <label class="switch">
                          <input type="checkbox" [(ngModel)]="s3EventsEnabled" (change)="updateQueueStatus('s3-events', 's3Events')">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Process Images - current status: {{ faceDetectionStatus }}</label>
                      <app-inline-loader *ngIf="faceDetectionShowLoader"></app-inline-loader>
                      <div class="input-group" *ngIf="!faceDetectionShowLoader">
                        <label class="switch">
                          <input type="checkbox" [(ngModel)]="faceDetectionEnabled" (change)="updateQueueStatus('image-processing', 'faceDetection')">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Process Person Matches - current status: {{ personMatcherStatus }}</label>
                      <app-inline-loader *ngIf="personMatcherShowLoader"></app-inline-loader>
                      <div class="input-group" *ngIf="!personMatcherShowLoader">
                        <label class="switch">
                          <input type="checkbox" [(ngModel)]="personMatcherEnabled" (change)="updateQueueStatus('persons', 'personMatcher')">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
            <div class="card">
              <div class="card-body">
                <div class="col-12" *ngIf="true">
                  <h3>Queue Processing Settings - DAM integration <i class="icon-reload" (click)="getQueueStates()"></i></h3>
                  <div>
                    <div class="form-group">
                      <label>Process High Priority Images - current status: {{ priorityFaceDetectionStatus }}</label>
                      <app-inline-loader *ngIf="priorityFaceDetectionShowLoader"></app-inline-loader>
                      <div class="input-group" *ngIf="!priorityFaceDetectionShowLoader">
                        <label class="switch">
                          <input type="checkbox" [(ngModel)]="priorityFaceDetectionEnabled" (change)="updateQueueStatus('priority-image-processing', 'priorityFaceDetection')">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Process DAM Updates - current status: {{ elvisUpdatesStatus }}</label>
                      <app-inline-loader *ngIf="elvisUpdatesShowLoader"></app-inline-loader>
                      <div class="input-group" *ngIf="!elvisUpdatesShowLoader">
                        <label class="switch">
                          <input type="checkbox" [(ngModel)]="elvisUpdatesEnabled" (change)="updateQueueStatus('elvis-updates', 'elvisUpdates')">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Process DAM Error Handler (stop DAM Updates when an error is detected) - current status: {{ elvisUpdatesStatus }}</label>
                      <app-inline-loader *ngIf="elvisErrorsShowLoader"></app-inline-loader>
                      <div class="input-group" *ngIf="!elvisErrorsShowLoader">
                        <label class="switch">
                          <input type="checkbox" [(ngModel)]="elvisErrorsEnabled" (change)="updateQueueStatus('elvis-errors', 'elvisErrors')">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
