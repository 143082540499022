<div class="container-fluid page-body-wrapper full-page-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12">
          <h1><a [routerLink]="'/bugs'">Bug Reports</a></h1>
        </div>
      </div>
      <div class="row">
        <div class="col-8 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
               <app-list-all-bugs #list></app-list-all-bugs>
            </div>
          </div>
        </div>
        <div class="col-4 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
                <app-edit-bug #editor></app-edit-bug>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>