import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SearchFacesRequest } from 'src/app/model/SearchFacesRequest';
import { ProcessedImageService } from 'src/app/services/images.service';
import { SqlEditorComponent } from 'src/app/components/sql-editor/sql-editor.component';
import { DatabaseService } from 'src/app/services/database.service';
import { Person } from 'src/app/model/Person';
import { Router } from '@angular/router';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-ignored',
  templateUrl: './ignored.component.html',
  styleUrls: ['./ignored.component.css']
})
export class IgnoredComponent implements OnInit {

  title = 'Ignored Persons';
  showLoader = false;
  persons: Person[];
  showMoreButton = false;
  totalCount: number;

  limit = 100;
  offset = 0;
  condition: string;

  orderText: string;
  errorText: string;


  constructor(
    private imageService: ProcessedImageService,
    private router: Router,
    private dbService: DatabaseService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.offset = 0;
    this.limit = 100;

    this.titleService.setTitle('Ignored Persons');
    this.doSearch();
  }


  doSearch() {
    this.showLoader = true;
    this.condition = 'where p.ignored = true order by p.name, p.ignored_at desc';
    this.imageService.searchPersons(this.condition, this.limit, this.offset).subscribe((p) => {
      this.persons = p.persons;
      this.totalCount = p.totalCount;
      this.showLoader = false;
      this.showMoreButton = (p.persons.length < p.totalCount);
      this.title = 'Showing ' + p.persons.length + ' of ' + p.totalCount +  ' ignored persons';
    }, (err) => {
      this.errorText = err.Error;
      this.showLoader = false;
    });
  }

  loadMoreFaces() {
    this.offset += this.limit;
    this.imageService.searchPersons(this.condition, this.limit, this.offset).subscribe((p) => {
      this.persons = this.persons.concat(p.persons);
      this.showLoader = false;
      this.showMoreButton = (this.persons.length < this.totalCount);
      this.title = 'Showing ' + this.persons.length + ' of ' + this.totalCount +  ' ignored persons';
    });
  }

}
