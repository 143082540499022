import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'Duration'
})
export class DurationPipe implements PipeTransform {

    transform(value: number): string {
        value = Math.floor(value);
        let minutes: number = Math.floor(value / 60);
        const seconds = value - (minutes * 60);
        const hours: number = Math.floor(minutes / 60);

        minutes = minutes - (hours * 60);

        return hours.toString().padStart(2, '0') + ':' +
            minutes.toString().padStart(2, '0') + ':' +
            seconds.toString().padStart(2, '0');
    }
}
