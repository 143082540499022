import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessedImageService } from 'src/app/services/images.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TitleService } from 'src/app/services/title.service';
import { Person } from 'src/app/model/Person';

@Component({
  selector: 'app-potential-duplicates',
  templateUrl: './potential-duplicates.component.html',
  styleUrls: ['./potential-duplicates.component.css']
})
export class PotentialDuplicatesComponent implements OnInit {

  title = '';
  showLoader = true;
  personId: number;
  persons: Person[];

  person: Person;
  bestGuessName: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private imageService: ProcessedImageService,
    private notifications: NotificationsService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.getData();
    this.bestGuessName = '';
    this.route.params.subscribe((p) => this.getData());
  }

  getData() {
    this.personId = Number(this.route.snapshot.paramMap.get('personId'));
    this.showLoader = true;
    this.title = 'Looking for potential duplicates for person #' + this.personId;
    this.titleService.setTitle('Duplicates of Person #' + this.personId);
    this.imageService.getPerson(this.personId).subscribe(p => {
      this.person = p;
      this.findBestGuess();
    });

    this.imageService.searchPotentialDuplicates(this.personId).subscribe(p => {
      this.persons = p;
      this.title = 'Found ' + p.length + ' potential duplicates for #' + this.personId;

      this.findBestGuess();

      this.showLoader = false;
    });
  }

  findBestGuess() {
    if (this.person && !this.person.name && this.persons) {
      for (const pe of this.persons) {
        if (pe.name) {
          this.bestGuessName = pe.name;
          break;
        }
      }
      if (this.bestGuessName === '') {
        this.bestGuessName = '! Unable to guess name !';
      }
    }
  }


}
