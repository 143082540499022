import { Component, OnInit } from '@angular/core';
import { ElvisSettings } from 'src/app/model/ElvisSettings';
import { SettingsService } from 'src/app/services/settings.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-elvis',
  templateUrl: './elvis.component.html',
  styleUrls: ['./elvis.component.css']
})
export class ElvisComponent implements OnInit {

  elvisSettings: ElvisSettings;

  elvisSettingsVerified = false;
  saveElvisInProgress = false;
  showElvisLoader = false;

  constructor(
    private settingsService: SettingsService,
    private notificationService: NotificationsService,
    public authService: AuthenticationService,
    private titleService: TitleService
    ) { }

  ngOnInit() {
    this.showElvisLoader = true;
    this.titleService.setTitle('Settings: Elvis');
    this.settingsService.getElvisSettings().subscribe(s => {
      this.elvisSettings = s;
      this.showElvisLoader = false;
    });
  }

  saveElvisSettings() {
    this.saveElvisInProgress = true;
    this.settingsService.updateElvisSettings(this.elvisSettings).subscribe(s => {
      this.elvisSettings = s;
      this.saveElvisInProgress = false;
    });
  }

  testElvisSettings() {
    this.settingsService.testElvisSettings(this.elvisSettings).subscribe(res => {
      if (res.loginSuccess) {
        console.log('Login was successful');
        this.elvisSettingsVerified = true;
        this.notificationService.showInfo('Login was successful', res.userProfile, 'success', 'Close', false);
      } else {
        console.log('Login failed: ' + res.loginFaultMessage);
        this.notificationService.showInfo('Login failed', res.loginFaultMessage, 'error', 'Close', false);
        this.elvisSettingsVerified = false;
      }

    });
  }

}
