

export class Environment {

    identityPoolId: string;
    userPoolId: string;
    clientId: string;
    region: string;
    name: string;
    customerName: string;
    subscriptionLevel: SubscriptionLevel;

}

export enum SubscriptionLevel {

    BRONZE=10, SILVER=20, GOLD=30

}

