import { Component, OnInit } from '@angular/core';
import { StatisticsService } from 'src/app/services/statistics.service';
import { Statistics } from 'src/app/model/Statistics';
import { HistoryElement } from 'src/app/model/HistoryElement';
import { TitleService } from 'src/app/services/title.service';

declare function Chart(canvas: any, settings: any): void;

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {

  constructor(
    private statsService: StatisticsService,
    private titleService: TitleService
    ) { }

  title: string;
  fetchTime: Date;

  showData = false;
  showLoader = true;

  showImagesHistoryLoader = true;
  showImagesProcessedLoader = true;
  showImagesErrorHistoryLoader = true;

  showPriorityImagesHistoryLoader = true;
  showPriorityImagesProcessedLoader = true;
  showPriorityImagesErrorHistoryLoader = true;

  showPersonsHistoryLoader = true;
  showPersonsProcessedLoader = true;
  showPersonsErrorHistoryLoader = true;

  showElvisS3HistoryLoader = true;
  showElvisS3ProcessedLoader = true;
  showElvisS3ErrorHistoryLoader = true;

  showElvisHistoryLoader = true;
  showElvisProcessedLoader = true;
  showElvisErrorsHistoryLoader = true;

  showS3HistoryLoader = true;
  showS3ProcessedLoader = true;
  showS3ErrorsHistoryLoader = true;

  imageQueue: number;
  priorityImageQueue: number;
  personQueue: number;
  s3Queue = 0;
  elvisQueue = 0;
  s3ElvisQueue = 0;
  personVerification = 0;
  personVerificationErrors = 0;

  imagesInProgress: number;
  priorityImagesInProgress: number;
  personsInProgress: number;

  imageErrors: number;
  priorityImageErrors: number;
  personErrors: number;
  s3Errors: number;
  elvisErrors: number;
  s3ElvisErrors: number;

  ngOnInit() {
    this.title = 'Fetching updated queue statistics';
    this.titleService.setTitle('Settings: Statistics');
    this.fetchStats();
  }

  fetchStats() {
    this.showLoader = true;
    this.statsService.getQueueStatistics().subscribe((resp) => {
      this.title = 'Queue statistics';
      this.fetchTime = new Date();
      this.imageQueue = Number(resp.images);
      this.imagesInProgress = Number(resp.imagesInProgress);
      this.imageErrors = Number(resp.imageErrors);

      this.priorityImageQueue = Number(resp.priorityImages);
      this.priorityImagesInProgress = Number(resp.priorityImagesInProgress);
      this.priorityImageErrors = Number(resp.priorityImageErrors);

      this.personQueue = Number(resp.persons);
      this.personsInProgress = Number(resp.personsInProgress);
      this.personErrors = Number(resp.personErrors);

      this.elvisQueue = Number(resp.elvis);
      this.elvisErrors = Number(resp.elvisErrors);

      this.s3Queue = Number(resp.s3Events);
      this.s3Errors = Number(resp.s3EventErrors);
      this.s3ElvisQueue = Number(resp.s3ElvisEvents);
      this.s3ElvisErrors = Number(resp.s3ElvisEventErrors);

      this.personVerification = Number(resp.personVerification);
      this.personVerificationErrors = Number(resp.personVerificationErrors);

      this.showData = true;
      this.showLoader = false;
    });

    // this.statsService.getQueueHistory('images-depth').subscribe(values => {
    //   this.drawChart('images-depth', values);
    //   this.showImagesHistoryLoader = false;
    // });
    // this.statsService.getQueueHistory('priority-images-depth').subscribe(values => {
    //   this.drawChart('priority-images-depth', values);
    //   this.showPriorityImagesHistoryLoader = false;
    // });
    // this.statsService.getQueueHistory('persons-depth').subscribe(values => {
    //   this.drawChart('persons-depth', values);
    //   this.showPersonsHistoryLoader = false;
    // });
    // this.statsService.getQueueHistory('s3-depth').subscribe(values => {
    //   this.drawChart('s3-depth', values);
    //   this.showS3HistoryLoader = false;
    // });
    // this.statsService.getQueueHistory('elvis-depth').subscribe(values => {
    //   this.drawChart('elvis-depth', values);
    //   this.showElvisHistoryLoader = false;
    // });
    // this.statsService.getQueueHistory('s3-elvis-depth').subscribe(values => {
    //   this.drawChart('elvis-s3-depth', values);
    //   this.showElvisS3HistoryLoader = false;
    // });
    // this.statsService.getQueueHistory('images-processed').subscribe(values => {
    //   this.drawChart('images-processed', values);
    //   this.showImagesProcessedLoader = false;
    // });
    // this.statsService.getQueueHistory('priority-images-processed').subscribe(values => {
    //   this.drawChart('priority-images-processed', values);
    //   this.showPriorityImagesProcessedLoader = false;
    // });
    // this.statsService.getQueueHistory('persons-processed').subscribe(values => {
    //   this.drawChart('persons-processed', values);
    //   this.showPersonsProcessedLoader = false;
    // });
    // this.statsService.getQueueHistory('s3-processed').subscribe(values => {
    //   this.drawChart('s3-processed', values);
    //   this.showS3ProcessedLoader = false;
    // });
    // this.statsService.getQueueHistory('elvis-processed').subscribe(values => {
    //   this.drawChart('elvis-processed', values);
    //   this.showElvisProcessedLoader = false;
    // });
    // this.statsService.getQueueHistory('s3-elvis-processed').subscribe(values => {
    //   this.drawChart('elvis-s3-processed', values);
    //   this.showElvisS3ProcessedLoader = false;
    // });
    // this.statsService.getQueueHistory('images-errors').subscribe(values => {
    //   this.drawChart('images-errors', values);
    //   this.showImagesErrorHistoryLoader = false;
    // });
    // this.statsService.getQueueHistory('priority-images-errors').subscribe(values => {
    //   this.drawChart('priority-images-errors', values);
    //   this.showPriorityImagesErrorHistoryLoader = false;
    // });
    // this.statsService.getQueueHistory('persons-errors').subscribe(values => {
    //   this.drawChart('persons-errors', values);
    //   this.showPersonsErrorHistoryLoader = false;
    // });
    // this.statsService.getQueueHistory('s3-errors').subscribe(values => {
    //   this.drawChart('s3-errors', values);
    //   this.showS3ErrorsHistoryLoader = false;
    // });
    // this.statsService.getQueueHistory('elvis-errors').subscribe(values => {
    //   this.drawChart('elvis-errors', values);
    //   this.showElvisErrorsHistoryLoader = false;
    // });
    // this.statsService.getQueueHistory('s3-elvis-errors').subscribe(values => {
    //   this.drawChart('elvis-s3-errors', values);
    //   this.showElvisS3ErrorHistoryLoader = false;
    // });


  }

  drawChart(canvasId: string, dataElements: HistoryElement[]) {

    const data = {
      labels: dataElements.map(d => ''),
      datasets: [{
          data: dataElements.map(d => d.value),
          backgroundColor: [
            '#22548e'
          ],
          borderColor: [
            '#22548e'
          ],
          borderWidth: 0,
          fill: false,
        }
      ]
    };

    let max = 0;
    for (const d of dataElements) {
      if (d.value > max) {
        max = d.value;
      }
    }

    const options = {
      scales: {
        yAxes: [{
          display: true,
          min: 0,
          gridLines: {
            drawBorder: false,
            lineWidth: 0,
            color: 'rgba(0,0,0,0)'
          },
          ticks: {
            stepSize: max + 10,
            beginAtZero: true,
            fontColor: '#686868'
          }
        }],
        xAxes: [{
          gridLines: {
            drawBorder: false,
            lineWidth: 0,
            color: 'rgba(0,0,0,0)'
          }
        }]
      },
      legend: {
        display: false
      },
      elements: {
        point: {
          radius: 0
        }
      },
      stepsize: 1
    };
    const activityChartCanvas = document.getElementById(canvasId) as HTMLCanvasElement;
    if (activityChartCanvas) {
      const activityChart = new Chart(activityChartCanvas.getContext('2d'), {
        type: 'line',
        data: data,
        options: options
      });
    }
  }


}
