import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(
    @Inject(DOCUMENT) private document: HTMLDocument
  ) { }

  /**
   * Get the title of the current HTML document.
   */
  getTitle(): string { return this.document.title; }

  /**
   * Set the title of the current HTML document.
   */
  setTitle(newTitle: string) { this.document.title = newTitle; }

}
