<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <h1>Elvis settings</h1>
      <div class="row">
        <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6" *ngIf="true">
                  <h3>Elvis integration</h3>
                  <app-loader *ngIf="showElvisLoader"></app-loader>
                  <div *ngIf="elvisSettings">

                    <div class="form-group">
                      <label>Host name for Elvis Server</label>
                      <div class="input-group">
                        <input class="form-control" type="text" [(ngModel)]="elvisSettings.host"
                          [readOnly]="!authService.hasAdminAccess()" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Username for Elvis</label>
                      <div class="input-group">
                        <input class="form-control" type="text" [(ngModel)]="elvisSettings.username"
                          [readOnly]="!authService.hasAdminAccess()" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Password for Elvis</label>
                      <div class="input-group">
                        <input class="form-control" type="password" [(ngModel)]="elvisSettings.password"
                          [readOnly]="!authService.hasAdminAccess()" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Client ID to use against Elvis</label>
                      <div class="input-group">
                        <input class="form-control" type="text" [(ngModel)]="elvisSettings.clientId"
                          [readOnly]="!authService.hasAdminAccess()" />
                      </div>
                    </div>

                    <div class="form-group">
                      <button class="btn btn-outline-success btn-block" (click)="testElvisSettings()"
                        *ngIf="!saveElvisInProgress" type="submit" [disabled]="!authService.hasAdminAccess()"
                        style="margin: 0px;">Test Elvis Login Settings</button>
                      <app-loader *ngIf="saveElvisInProgress"></app-loader>
                    </div>
                    <div class="form-group">
                      <button class="btn btn-outline-primary btn-block" (click)="saveElvisSettings()"
                        *ngIf="!saveElvisInProgress" type="submit"
                        [disabled]="!authService.hasAdminAccess() || !elvisSettingsVerified" style="margin: 0px;">Save
                        Elvis settings and Field mappings</button>
                      <app-loader *ngIf="saveElvisInProgress"></app-loader>
                    </div>
                  </div>
                </div>
                <div class="col-6" *ngIf="elvisSettings">
                  <h3>Elvis Field Mappings</h3>
                  <div class="form-group">
                    <label>ID Field</label>
                    <div class="input-group">
                      <input class="form-control" type="text" [(ngModel)]="elvisSettings.idField"
                        [readOnly]="!authService.hasAdminAccess()" />
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Persons Field</label>
                    <div class="input-group">
                      <input class="form-control" type="text" [(ngModel)]="elvisSettings.personsField"
                        [readOnly]="!authService.hasAdminAccess()" />
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Timestamp Field</label>
                    <div class="input-group">
                      <input class="form-control" type="text" [(ngModel)]="elvisSettings.timestampField"
                        [readOnly]="!authService.hasAdminAccess()" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>