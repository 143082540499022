import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Person } from 'src/app/model/Person';
import { FaceProcessorService } from 'src/app/services/face-processor.service';
import { IdentifiedFace } from 'src/app/model/IdentifiedFace';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { FaceLister } from 'src/app/interfaces/FaceLister';
import { Router } from '@angular/router';
import {ContextMenuComponent, ContextMenuService} from '@perfectmemory/ngx-contextmenu';

@Component({
  selector: 'app-face-menu',
  templateUrl: './face-menu.component.html',
  styleUrls: ['./face-menu.component.css']
})
export class FaceMenuComponent implements OnInit {

  selectedPerson: Person;

  @ViewChild('contextMenu') contextMenu: ContextMenuComponent<any>;

  @ViewChild('deleteSwal') private deleteSwal: SwalComponent;
  @ViewChild('assignSwal') private assignSwal: SwalComponent;
  @ViewChild('splitSwal') private splitSwal: SwalComponent;
  @ViewChild('splitBranchSwal') private splitBranchSwal: SwalComponent;
  @ViewChild('detailsSwal') private detailsSwal: SwalComponent;

  @Input() listener: FaceLister;

  detailsFace: IdentifiedFace;

  @Input() showAssignItem = true;
  @Input() showReprocessItem = true;
  @Input() showDeleteItem = true;
  @Input() showDetailsItem = true;
  @Input() showSplitItem = true;
  @Input() showSplitBranchItem = false;


  constructor(
    private facesService: FaceProcessorService,
    private contextMenuService: ContextMenuService<any>,
    private router: Router
  ) {
   }

  ngOnInit() {
  }

  showContextMenu($event: MouseEvent, face: IdentifiedFace): void {
    this.contextMenuService.show(
      this.contextMenu,
      {
        x: $event.x,
        y: $event.y,
        value: face,
      });

    $event.preventDefault();
    $event.stopPropagation();
  }

  setSelectedPerson(p: Person, me: FaceMenuComponent) {
    console.log('Selected Target: ' + p.name);
    me.selectedPerson = p;
  }

  assign(face: IdentifiedFace) {
    this.assignSwal.fire().then((res) => {
      if (res.dismiss) {
        this.selectedPerson = undefined;
      } else {
        this.facesService.assignFace(face, this.selectedPerson, this.listener);
      }
    }).catch(() => {
      this.selectedPerson = undefined;
    });
  }

  split(face: IdentifiedFace) {
    this.splitSwal.fire().then((res) => {
      if (res.dismiss) {
        // nothing
      } else {
        this.facesService.splitFace(face);
      }
    }).catch(() => {
      // nothing
    });
  }

  splitBranch(face: IdentifiedFace) {
    this.splitBranchSwal.fire().then((res) => {
      if (res.dismiss) {
        // nothing
      } else {
        this.facesService.splitFaceBranch(face, this.listener);
      }
    }).catch(() => {
      // nothing
    });
  }

  delete(face: IdentifiedFace) {
    this.deleteSwal.fire().then((res) => {
      if (res.dismiss) {
        // nothing
      } else {
        if (face.image) {
          face.image.showLoader = true;
        }
        this.facesService.deleteFace(face, this.listener);
      }
    }).catch(() => {
      // nothing
    });
  }

  showDetails(face: IdentifiedFace) {
    this.router.navigate(['faces/' + face.id]);
  }

  reprocess(face: IdentifiedFace) {
    this.facesService.reprocessFace(face, this.listener);
  }

}
