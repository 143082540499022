import * as AWSCognito from 'amazon-cognito-identity-js';

export class User {
    id: number;

    username: string;
    password: string;
    rememberMe = false;

    state: 'OK' | 'CHANGE_PASS' | 'SETUP_MFA' | 'CHALLENGE_MFA' | 'ERROR' | 'PASS_CHANGE_OK' | 'CONFIRM_PASS';
    message?: string;

    fullname: string;
    email: string;

    token?: string;
    refreshToken?: string;
    lastRefresh: number;

    cognito?: AWSCognito.CognitoUser;

    mfa?: MFAChallenge;

    groups: string[];

    lastSaved: Date;
    loaded: Date;

    isAdmin(): boolean {
        return this.groups && this.groups.includes('administrators');
    }

    isKeyCoreAdmin(): boolean {
        return this.groups && this.groups.includes('keycore');
    }

}

export class MFAChallenge {
    Medium: string;
    Details: string;
}
