<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <h1>Batch Jobs</h1>
      <div class="row">
        <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Start new batch task</h4>
              <p class="card-description">Select the predefined job to run</p>
              <form class="form-sample">
                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="bucketName">Predefined Job Template</label>
                    <div class="col-sm-9">
                      <select name="priority" class="form-control" id="priority" type="text" [(ngModel)]="jobName">
                        <option>REFRESH_MATERIALIZED_VIEWS</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
              <button class="btn btn-outline-primary mb-2" (click)="submitJob()" type="submit"
                [disabled]="!authService.hasAdminAccess() || !jobName" style="margin: 0px;">Submit Job</button>
              <div class="form-group">
                <app-loader *ngIf="showLoader"></app-loader>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title" (click)="refresh()">Batch Job History</h4>
              <app-loader *ngIf="!jobs"></app-loader>
              <table class="table table-stribed">
                <thead>
                  <tr>
                    <th>Job ID</th>
                    <th>Job Name</th>
                    <th>Started At</th>
                    <th>Stopped At</th>
                    <th>Runtime</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let j of jobs">
                    <td>{{ j.jobId }}</td>
                    <td>{{ j.jobName }}</td>
                    <td>{{ j.startedAt | date:'yyyy-MMM-dd HH:mm' | uppercase }} ({{ j.startedAt | amTimeAgo }})</td>
                    <td *ngIf="j.stoppedAt">{{ j.stoppedAt | date:'yyyy-MMM-dd HH:mm' | uppercase }} ({{ j.stoppedAt | amTimeAgo }})</td>
                    <td *ngIf="j.stoppedAt">{{ (j.stoppedAt - j.startedAt)/1000 | Duration }}</td>
                    <td *ngIf="!j.stoppedAt">-</td>
                    <td *ngIf="!j.stoppedAt">-</td>
                    <td>{{ j.status }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>