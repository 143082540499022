import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { IdentifiedFace } from 'src/app/model/IdentifiedFace';
import { SearchFacesRequest } from 'src/app/model/SearchFacesRequest';
import { ProcessedImageService } from 'src/app/services/images.service';
import { SqlEditorComponent } from 'src/app/components/sql-editor/sql-editor.component';
import { DatabaseService } from 'src/app/services/database.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-raw-face-search',
  templateUrl: './raw-face-search.component.html',
  styleUrls: ['./raw-face-search.component.css']
})
export class RawFaceSearchComponent implements OnInit, AfterViewInit {

  @ViewChild('editor') editor: SqlEditorComponent;

  title = 'Search for faces by defining where clause for SQL search';
  showLoader = false;
  faces: IdentifiedFace[];
  showMoreButton = false;
  totalCount: number;

  orderText: string;

  request: SearchFacesRequest;

  constructor(
    private imageService: ProcessedImageService,
    private dbService: DatabaseService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.request = new SearchFacesRequest();

    this.request.offset = 0;
    this.request.limit = 100;
    this.titleService.setTitle('Raw Face Search');

    this.loadTables();
  }

  ngAfterViewInit() {
    this.editor.setValue('WHERE f.confidence < 99.99');
  }

  queryChanged(q: string) {
    if (q && q != null) {
      console.log('New query: ' + q);
      this.request.rawSearch = q;
    } else {
      this.request.rawSearch = '';
    }
  }

  doSearch() {
    this.showLoader = true;
    this.request.offset = 0;
    this.request.rawSearch = this.editor.getValue();
    this.imageService.searchFaces(this.request).subscribe((f) => {
      this.faces = f.faces;
      this.totalCount = f.totalCount;
      this.showLoader = false;
      this.showMoreButton = (f.faces.length < f.totalCount);
      this.title = 'Showing ' + f.faces.length + ' of ' + f.totalCount +  ' faces that match parameters';
    });
  }

  loadMoreFaces() {
    this.request.offset += this.request.limit;
    this.imageService.searchFaces(this.request).subscribe((f) => {
      this.faces = this.faces.concat(f.faces);
      this.showLoader = false;
      this.showMoreButton = (this.faces.length < this.totalCount);
      this.title = 'Showing ' + this.faces.length + ' of ' + this.totalCount +  ' faces that match parameters';
    });
  }

  loadTables() {
    this.dbService.listTables().subscribe((t) => {
      this.editor.setTables(t);
    });
  }


}
