import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, config } from 'rxjs';
import { User } from '../model/user';
import { CustomerManager } from '../model/customer-manager';
import { SubscriptionLevel } from '../model/Environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    public currentUser: BehaviorSubject<User>;

    constructor() {
        if (sessionStorage.getItem('currentUser')) {
            this.currentUser = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
            this.currentUser.value.loaded = new Date();
            console.log('Loaded user from sessionStorage');
        } else if (localStorage.getItem('currentUser')) {
            this.currentUser = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
            this.currentUser.value.loaded = new Date();
            console.log('Loaded user from localStorage');
        } else {
            this.currentUser = new BehaviorSubject<User>(new User());
            console.log('No user loaded');
        }
        console.log('Authentication Service constructed');
    }

    public get currentUserValue(): User {
      if (!this.currentUser) {
        return undefined;
      }
      return this.currentUser.value;
    }

    hasKeyCoreAccess(): boolean {
        return (this.currentUser && this.hasGroup(this.currentUser.value, 'keycore'));
    }

    hasReportBugAccess(): boolean {
        return (this.currentUser && this.hasGroup(this.currentUser.value, 'bug-reporters'));
    }

    hasHandleBugsAccess(): boolean {
        return (this.currentUser && this.hasGroup(this.currentUser.value, 'bug-handlers'));
    }

    hasAdminAccess(): boolean {
        return (this.currentUser && this.hasGroup(this.currentUser.value, 'administrators'));
    }

    hasAccessTo(regionCode: string): boolean {
        return this.hasAdminAccess() || (this.currentUser && this.hasGroup(this.currentUser.value, regionCode + '-users'));
    }

    hasGroup(user: User, name: string): boolean {
        return (user) && (user.groups) &&
            (user.groups.includes(CustomerManager.CustomerName + '-' + name) || user.groups.includes('keycore'));
    }

    hasSubscriptionLevel(minimum: SubscriptionLevel): boolean {
        return CustomerManager.SubscriptionLevel >= minimum;
    }

    logout() {
        // remove user from local storage to log user out
        console.log('Doing logout');
        sessionStorage.removeItem('currentUser');
        localStorage.removeItem('currentUser');
        this.currentUser.next(undefined);
    }

    storeUser(u: User): void {
        const temp: User = JSON.parse(JSON.stringify(u));
        if (this && this.currentUser) {
            this.currentUser.next(u);
        }
        temp.cognito = undefined;
        temp.password = undefined;
        u.password = undefined;
        u.lastSaved = new Date();
        sessionStorage.setItem('currentUser', JSON.stringify(temp));
        localStorage.setItem('currentUser', JSON.stringify(temp));
    }

}
