import { Component, OnInit } from '@angular/core';
import { ImageMatchSettings } from 'src/app/model/ImageMatchSettings';
import { ProcessedImageService } from 'src/app/services/images.service';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-face-matching',
  templateUrl: './face-matching.component.html',
  styleUrls: ['./face-matching.component.css']
})
export class FaceMatchingComponent implements OnInit {

  currentSettings: ImageMatchSettings;

  saveInProgress = false;
  showLoader = false;

  constructor(
    public authService: AuthenticationService,
    private settingsService: SettingsService,
    private titleService: TitleService
    ) { }

  ngOnInit() {
    this.showLoader = true;
    this.titleService.setTitle('Settings: Face Matching');
    this.settingsService.getImageMatcherSettings().subscribe(s => {
      this.currentSettings = s;
      this.showLoader = false;
    });
  }


  save() {
    this.saveInProgress = true;
    this.settingsService.updateImageMatcherSettings(this.currentSettings).subscribe(s => {
      this.currentSettings = s;
      this.saveInProgress = false;
    });
  }

}
