import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SearchFacesRequest } from 'src/app/model/SearchFacesRequest';
import { ProcessedImageService } from 'src/app/services/images.service';
import { DatabaseService } from 'src/app/services/database.service';
import { Person } from 'src/app/model/Person';
import { Router } from '@angular/router';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  searchString = '';
  showLoader = false;
  persons: Person[];
  showMoreButton = false;
  totalCount: number;
  title = 'Search named persons by name';

  limit = 100;
  offset = 0;
  condition: string;

  orderText: string;
  errorText: string;


  constructor(
    private imageService: ProcessedImageService,
    private router: Router,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.offset = 0;
    this.limit = 100;
    this.searchString = '';
    this.titleService.setTitle('Search Persons');
  }

  doSearch() {
    this.showLoader = true;
    this.condition = 'WHERE upper(p.name) like upper(\'%' + this.searchString + '%\')';

    console.log(this.condition);
    this.imageService.searchPersons(this.condition, this.limit, this.offset).subscribe((p) => {
      this.persons = p.persons;
      this.totalCount = p.totalCount;
      this.showLoader = false;
      this.showMoreButton = (p.persons.length < p.totalCount);
      this.title = 'Showing ' + p.persons.length + ' of ' + p.totalCount +  ' persons that match query';
    }, (err) => {
      this.errorText = err.Error;
      this.showLoader = false;
    });
  }

  loadMoreFaces() {
    this.offset += this.limit;
    this.imageService.searchPersons(this.condition, this.limit, this.offset).subscribe((p) => {
      this.persons = this.persons.concat(p.persons);
      this.showLoader = false;
      this.showMoreButton = (this.persons.length < this.totalCount);
      this.title = 'Showing ' + this.persons.length + ' of ' + this.totalCount +  ' persons that match query';
    });
  }

  public showPerson(person) {
    this.router.navigate(['persons/' + person.id]);
  }


}
