import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Table } from '../model/Table';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  public url = 'v1';

    constructor(public http: HttpClient) { }

    listTables(): Observable<Table[]> {
      return this.http.get<Table[]>(this.url + '/database/tables');
      // return new BehaviorSubject<Table[]>([]);
  }
}
