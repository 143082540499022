import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, from} from 'rxjs';
import { Router } from '@angular/router';
import { WaitHelper } from './Wait';

@Injectable()
export class DelayedResponsesInterceptor implements HttpInterceptor {
    constructor(
        private router: Router
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(new Promise<HttpEvent<any>>(async (resolve, reject) => {
            try {
                const event = await next.handle(request).toPromise();

                if (event instanceof HttpResponse) {
                    if (event.status === 202) {
                        // response is token with reference to delayed response
                        // make new request for actual response
                        const r = await this.waitForResponse(event.body.Token, next);
                        resolve(r);
                        return;
                    }
                }
                resolve(event);
            } catch (err) {
                reject(err);
            }

        }));
    }

    waitForResponse(token: string, next: HttpHandler): Promise<HttpEvent<any>> {
        const me = this;
        // return Observable.fromPromise(new Promise<HttpEvent<any>>(async (resolve, reject) => {
        return new Promise<HttpEvent<any>>(async (resolve, reject) => {
            const req = new HttpRequest<any>('GET', 'v1/pending-response/' + token + '?v=' + new Date().getTime());

            next.handle(req).subscribe(async (r) => {
                if (r instanceof HttpResponse) {
                    if (r.status === 204) {
                        await WaitHelper.wait(200);
                        const resp = await me.waitForResponse(token, next);
                        resolve(resp);
                    } else {
                        resolve(r);
                    }
                }
            }, (err) => reject(err));
        });
    }
}
