<div class="container-scroller">
  <app-top-menu></app-top-menu>

  <router-outlet></router-outlet>
  <footer class="footer">
    <div class="w-100 clearfix">
      <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">Created by <a
          href="https://www.keycore.dk/" target="_blank">KeyCore</a> {{year}}. All rights reserved.</span>
      <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Advanced and intelligent AWS solutions <i
          class="icon-heart text-danger"></i></span>
    </div>
  </footer>
</div>
