import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ProcessedImageService } from 'src/app/services/images.service';
import { FailedImage } from 'src/app/model/FailedImage';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-failed-images',
  templateUrl: './failed-images.component.html',
  styleUrls: ['./failed-images.component.css']
})
export class FailedImagesComponent implements OnInit {

  offset = 0;
  limit = 500;
  showLoader = false;
  images: FailedImage[];
  more = false;

  constructor(
    public authService: AuthenticationService,
    private imageService: ProcessedImageService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.offset = 0;
    this.titleService.setTitle('Settings: Failed Images');
    this.loadMoreImages();
  }

  loadMoreImages() {
    this.showLoader = true;
    this.imageService.listFailedImages(this.offset, this.limit).subscribe(i => {
      if (this.images) {
        this.images = this.images.concat(i);
      } else {
        this.images = i;
      }
      this.more = i.length === this.limit;
      this.offset += this.limit;
      this.showLoader = false;
    });
  }

}
