<div class="row">
  <div class="col-12">
    <app-loader *ngIf="showLoader"></app-loader>
    <table class="table table-hover">
      <tr>
        <th>ID</th>
        <th>Subject</th>
        <th>Status</th>
        <th>Type</th>
        <th>Priority</th>
        <th>Reported</th>
        <th>Reporter</th>
        <th>Last Update</th>
        <th>Last Update by</th>
        <th *ngIf="!parentId">Instances</th>
        <th *ngIf="parentId">Details</th>
        <th>Logs</th>
      </tr>
      <tr *ngFor="let bug of reports" (click)="showDetails(bug)">
        <td>{{bug.id}}</td>
        <td>{{bug.subject}}</td>
        <td>{{bug.status}}</td>
        <td>{{bug.type}}</td>
        <td>{{bug.priority}}</td>
        <td>{{bug.created | date:'yyyy-MMM-dd HH:mm' | uppercase}}</td>
        <td>{{bug.reporter}}</td>
        <td>{{bug.lastUpdate | date:'yyyy-MMM-dd HH:mm' | uppercase }}</td>
        <td>{{bug.lastUpdateBy}}</td>
        <td *ngIf="parentId">
          <a *ngIf="bug.faceId" [routerLink]="'/faces/' + bug.faceId">
            <img [src]="'processed-images/faces/' + bug.faceId + '.png'" class="img-sm mr-4" />
            Face #{{bug.faceId}}
          </a>

          <a *ngIf="bug.imageId" [routerLink]="'/images/' + bug.imageId">Image {{bug.imageId}}</a>
          <a *ngIf="bug.personId" [routerLink]="'/persons/' + bug.personId">Person {{bug.personId}}</a>
        </td>
        <td *ngIf="!parentId && bug.childCount > 0"><a [routerLink]="'/bugs/' + bug.id">{{bug.childCount}}</a></td>
        <td *ngIf="!parentId && bug.childCount == 0">
          <a *ngIf="bug.faceId" [routerLink]="'/faces/' + bug.faceId">
            <img [src]="'processed-images/faces/' + bug.faceId + '.png'" class="img-sm mr-4" />
            Face #{{bug.faceId}}
          </a>
          <a *ngIf="bug.imageId" [routerLink]="'/images/' + bug.imageId">Image {{bug.imageId}}</a>
          <a *ngIf="bug.personId" [routerLink]="'/persons/' + bug.personId">Person {{bug.personId}}</a>
        </td>
        <td>
          <a [routerLink]="'/logs/' + bug.logGroup.split('/').join('@@') + '/' + bug.logStream.split('/').join('@@')  + '/' + bug.requestId" *ngIf="bug.logGroup">
            View Logs
          </a>
        </td>
      </tr>
    </table>
    <button *ngIf="!showLoader" class="btn btn-outline-primary" (click)="refresh()">Refresh</button>
  </div>
</div>