import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-subscription-level',
  templateUrl: './subscription-level.component.html',
  styleUrls: ['./subscription-level.component.css']
})
export class SubscriptionLevelComponent implements OnInit {

  year = new Date().getFullYear();
  errorCode: string;
  errorText: string;

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.errorCode = this.route.snapshot.queryParams.message;

    if (this.errorCode == '402') {
      this.errorText = 'Customer unknown - contact support';
    } else if (this.errorCode == '405') {
      this.errorText = 'You have tried to call a feature unavailable on your current subscription tier. Contact support to upgrade'
    } else if (this.errorCode == '412') {
      this.errorText = 'Customer is inactive - contact support';
    } else {
      this.errorText = 'You have tried to access a feature unavailable on your current subscription tier. Contact support to upgrade'
    }
  }

}
