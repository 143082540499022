<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12">
          <h1>{{title}}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <app-loader *ngIf="showLoader"></app-loader>
                <div class="col-12" *ngIf="persons && persons.length == 0">
                  <h2>No potential duplicates found</h2>
                  <button class="btn btn-outline-primary" [routerLink]="'/persons/' + personId">Back to Person #{{personId}}</button>
                </div>
                <div class="col-12" *ngIf="persons && persons.length > 0">
                <h2>Showing potential duplicates for person #{{personId}}: {{ (person && person.name) ? person.name : '"Unnamed"' }}</h2>
                <h4 *ngIf="person && !person.name">Best AI Guess for name: {{bestGuessName}}</h4>
                  <div *ngIf="person">
                    <h4>Person #{{person.id}}</h4>
                    <img [src]="'processed-images/faces/' + person.primaryFaceId + '.png'" />
                  </div>

                  <button class="btn btn-outline-primary" [routerLink]="'/persons/' + personId">Back to Person #{{personId}}</button>
                  <table class="table table-stribed">
                    <thead>
                    </thead>
                    <tbody>
                      <tr *ngFor="let per of persons">
                        <td>
                        <h4>Person #{{per.id}} {{per.name}}</h4>
                          <img [src]="'processed-images/faces/' + per.primaryFaceId + '.png'" />
                          <p>{{ per.queryCount }} Matching Face Relations</p>
                          <a [routerLink]="'/persons/' + per.id" class="btn btn-outline-primary btn-sm">Show Person #{{per.id}}</a>&nbsp;
                          <a [routerLink]="'/merge/' + personId" [queryParams]="{with: per.id}"  class="btn btn-outline-primary btn-sm">Merge #{{personId}} with #{{per.id}}</a>&nbsp;
                          <a [routerLink]="'/persons/' + personId + '/comparewith/' + per.id"  class="btn btn-outline-primary btn-sm">Compare #{{personId}} with #{{per.id}}</a>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>