<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <h1 *ngIf="!showLoader">{{title}}</h1>
          <app-loader *ngIf="showLoader"></app-loader>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-8" *ngIf="image">
                  <div style="align-content: center;">
                    <img [src]="image.location.key" [id]="'img_' + image.id" class="main-image" />
                    <canvas class="transition-2" [id]="'canvas_' + image.id" [hidden]="true"
                      class="main-image"></canvas>
                    <p style="text-align: center;">Hash: {{ image.hash }}</p>
                  </div>
                </div>
                <div class="col-4" *ngIf="image">
                  <button class="btn btn-outline-warning btn-block" (click)="reprocessImage()">Reprocess images with
                    current settings</button>
                  <div *ngIf="showPersonMatchingDetails">
                    <h2>Identified Faces</h2>
                    <app-face-menu #faceMenu [listener]="this"></app-face-menu>
                    <table class="table table-striped">
                      <tr>
                        <th>Person</th>
                        <th>Name</th>
                        <th>Confidence</th>
                        <th>Person verified</th>
                      </tr>
                      <tr *ngFor="let face of image.faces"
                        (mouseover)="drawBoundingBox(image, face.imageLocation, face.ignored)"
                        (mouseleave)="removeBoundingBox(image)" (contextmenu)="showFaceContextMenu($event, face)">
                        <td>
                          {{ (face.person.id) ? face.person.id : '-' }}
                          <app-inline-loader *ngIf="face.showLoader"></app-inline-loader>
                        </td>
                        <td>
                          <a style="cursor: pointer;" *ngIf="face.person.id"
                            [routerLink]="'/persons/' + face.person.id">{{ (face.person.name) ? face.person.name : 'Not yet named' }}</a>
                          <span *ngIf="!face.person.id">Pending Person Matching</span>
                        </td>
                        <td *ngIf="face.personConfidence">{{face.personConfidence | number }}%</td>
                        <td>
                          <app-inline-loader *ngIf="face.showLoader"></app-inline-loader>
                          <button *ngIf="!face.personVerified && !face.showLoader && face.person.name"
                            class="btn btn-outline-success" (click)="verifyFace(face)">Verify match</button>
                          <button *ngIf="face.personVerified && !face.showLoader" class="btn btn-outline-warning"
                            (click)="unverifyFace(face)">Remove Verification</button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 grid-margin stretch-card" *ngIf="showPersonMatchingDetails">
          <div class="card">
            <div class="card-body">
              <div class="row">


                <div class="col-12" *ngIf="image">
                  <h2>Details on Identified Faces</h2>
                  <app-face-menu #faceMenu [listener]="this"></app-face-menu>
                  <table class="table table-striped">
                    <tr>
                      <th>#</th>
                      <th>Person</th>
                      <th>Name</th>
                      <th>Confidence this is a human face</th>
                      <th>Confidence it is the assigned person</th>
                      <th>Person verified</th>
                      <th>Verified By</th>
                      <th>Verified Date</th>
                      <th>Relative Size</th>
                      <th>Sharpness</th>
                      <th>Brightness</th>
                      <th>Roll</th>
                      <th>Yaw</th>
                      <th>Pitch</th>
                      <th>Ignored</th>
                    </tr>
                    <tr *ngFor="let face of image.faces"
                      (mouseover)="drawBoundingBox(image, face.imageLocation, face.ignored)"
                      (mouseleave)="removeBoundingBox(image)" (contextmenu)="showFaceContextMenu($event, face)">
                      <td>
                        <a [routerLink]="'/faces/' + face.id">
                          {{face.id}}
                        </a>
                      </td>
                      <td>
                        {{ face.person.id }}
                        <app-inline-loader *ngIf="face.showLoader"></app-inline-loader>
                      </td>
                      <td>
                        <a style="cursor: pointer;" *ngIf="face.person.id"
                          [routerLink]="'/persons/' + face.person.id">{{ (face.person.name) ? face.person.name : 'Not yet named' }}</a>
                        <span *ngIf="!face.person.id">Pending Person Matching</span>
                      </td>
                      <td>{{face.confidence | number }}%</td>
                      <td *ngIf="face.personConfidence">{{face.personConfidence | number }}%</td>
                      <td *ngIf="!face.personConfidence">-</td>
                      <td>
                        <app-inline-loader *ngIf="face.showLoader"></app-inline-loader>
                        <button *ngIf="!face.personVerified && !face.showLoader && face.person.name"
                          class="btn btn-outline-success" (click)="verifyFace(face)">Verify match</button>
                        <button *ngIf="face.personVerified && !face.showLoader" class="btn btn-outline-warning"
                          (click)="unverifyFace(face)">Remove Verification</button>
                      </td>
                      <td>{{face.personVerifiedBy}}</td>
                      <td>{{face.personVerifiedDate | date}}</td>

                      <td>
                        {{ face.relativeSize | number}} %
                      </td>
                      <td>
                        {{face.sharpness | number}}
                      </td>
                      <td>{{face.brightness | number}}</td>
                      <td>{{face.facePose.roll | number}}</td>
                      <td>{{face.facePose.yaw | number}}</td>
                      <td>{{face.facePose.pitch | number}}</td>
                      <td>{{face.ignored }}</td>
                    </tr>
                  </table>
                </div>
                <div class="col-3" *ngIf="image">
                  <div class="form-group">
                    <label>Show Faces for unnamed Persons</label>
                    <div class="input-group">
                      <label class="switch">
                        <input type="checkbox" (change)="toggleIdentifiedOnly()" [(ngModel)]="showUnidentifiedFaces">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-3" *ngIf="image">
                  <div class="form-group">
                    <label>Show Ignored Persons</label>
                    <div class="input-group">
                      <label class="switch">
                        <input type="checkbox" (change)="toggleIgnoredPersons()" [(ngModel)]="showIgnoredPersons">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-3" *ngIf="image">
                  <div class="form-group">
                    <label>Show Ignored Faces</label>
                    <div class="input-group">
                      <label class="switch">
                        <input type="checkbox" (change)="toggleIgnored()" [(ngModel)]="showIgnoredFaces">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-3" *ngIf="image">
                  <div class="form-group">
                    <label>Mark Image</label>
                    <div class="input-group">
                      <label class="switch">
                        <input type="checkbox" (change)="toggleMarked()" [(ngModel)]="image.marked">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <span *ngIf="image.marked">Image marked by {{image.markedBy}} on
                    {{image.markedAt | date:'yyyy-MMM-dd HH:mm' | uppercase}}</span>
                  <app-loader *ngIf="showMarkLoader"></app-loader>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12" *ngIf="image">
                  <h2>Image Details</h2>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Key</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>First seen</td>
                        <td>{{image.created | date:'yyyy-MMM-dd HH:mm' | uppercase}} - {{image.created | amTimeAgo }}
                        </td>
                      </tr>
                      <tr>
                        <td>Last Processed</td>
                        <td>{{image.lastProcessed | date:'yyyy-MMM-dd HH:mm' | uppercase}} -
                          {{image.lastProcessed | amTimeAgo }}</td>
                      </tr>
                      <tr>
                        <td>Origsize</td>
                        <td>{{image.origSize | bytes }}</td>
                      </tr>
                      <tr>
                        <td>Resized</td>
                        <td>{{image.resized }}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>{{image.status}}</td>
                      </tr>
                      <tr>
                        <td>Dimensions (W x H)</td>
                        <td>{{image.width}} x {{image.height}}</td>
                      </tr>
                      <tr>
                        <td>External ID</td>
                        <td>{{image.allerImageId}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12" *ngIf="image">
                  <h2>Image Event Log</h2>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Time</th>
                        <th>Event</th>
                        <th>Details</th>
                        <th *ngIf="authService.hasAdminAccess()">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="!this.events">
                        <td colspan="4">
                          <app-loader></app-loader>
                        </td>
                      </tr>
                      <tr *ngFor="let e of this.events">
                        <td>{{ e.time | date:'yyyy-MMM-dd  HH:mm.ss'}} ({{ e.time | amTimeAgo }})</td>
                        <td>{{ e.event }}</td>
                        <td>{{ e.metadata }}</td>
                        <td *ngIf="authService.hasAdminAccess()">
                          <a [routerLink]="'/logs/' + e.logGroup.split('/').join('@@') + '/' + e.logStream.split('/').join('@@')  + '/' + e.requestId"
                            *ngIf="e.logGroup">
                            View Logs
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12" *ngIf="image">
                  <h2>Image labels</h2>
                  <table class="table table-striped">
                    <tr>
                      <th>Name</th>
                      <th>Confidence</th>
                      <th>Parents</th>
                      <th>Source</th>
                    </tr>
                    <tr *ngFor="let label of image.labels">
                      <td>{{ label.kind }}</td>
                      <td>{{ label.confidence  | number }} %</td>
                      <td>{{ label.parents.join(',') }}</td>
                      <td>{{ label.source }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>