import { ErrorHandler, Injectable} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorLogHandlerService } from '../services/error-log-handler.service';
import { NotificationsService } from '../services/notifications.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorsHandler implements ErrorHandler {

  constructor(
    private errorService: ErrorLogHandlerService,
    private notifications: NotificationsService,
  ) {}

  handleError(error: Error) {
    this.errorService.log(error);
    if (error instanceof HttpErrorResponse) {
        // Server or connection error happened
        if (!navigator.onLine) {
          // Handle offline error
          this.notifications.showInfo('Computer appears Offline', 'It appears your webbrowser does not have internet access.', 'warning', 'Close', false);
        } else {
          // Handle Http Error (error.status === 403, 404...)
          if (error.status === 401) {
            // ignore
          } else {
            this.notifications.showInfo('Server Error', 'Backend returned status: ' + error.status + ' - ' + error.message, 'error', 'Close', false);
          }
        }
      } else {
        this.notifications.showInfo('Client Error', 'An Error occured in the Client: ' + error.message, 'error', 'Close', false);
        // Handle Client Error (Angular Error, ReferenceError...)
     }
  }
}
