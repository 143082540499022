import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { BatchJobService } from 'src/app/services/batch-job.service';
import { TitleService } from 'src/app/services/title.service';
import { SubmittedBatchJob } from 'src/app/model/batch/submitted-batch-job';
import { BatchJobDefinition } from 'src/app/model/batch/batch-job-definitions';

@Component({
  selector: 'app-list-batch-jobs',
  templateUrl: './list-batch-jobs.component.html',
  styleUrls: ['./list-batch-jobs.component.css']
})
export class ListBatchJobsComponent implements OnInit {

  jobs: SubmittedBatchJob[];
  jobName: BatchJobDefinition;
  showLoader = false;

  constructor(
    public authService: AuthenticationService,
    private notificationService: NotificationsService,
    private batchService: BatchJobService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Settings: Batch Jobs');
    this.batchService.listAll().subscribe((h) => this.jobs = h);
  }

  submitJob() {
    if (this.jobName) {
      this.showLoader = true;
      this.batchService.start(this.jobName).subscribe(() => {
        this.refresh();
      });
    }
  }

  refresh() {
    this.showLoader = true;
    this.jobs = undefined;
    this.batchService.listAll().subscribe((h) => {
      this.jobs = h;
      this.showLoader = false;
  });

  }

}
