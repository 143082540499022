import * as React from 'react';
import {Button} from '@material-ui/core'
import ReactFlow, {removeElements, addEdge, Handle, Controls, ControlButton} from 'react-flow-renderer';
import RouteMenu from './RouteMenu.js'
import Node from './Node.js'
import { ImageRoutingService } from 'src/app/services/image-routing.service';
import { ImageRoute } from 'src/app/model/image-route';
import { MetataggerRouteProvider } from '../model/metatagger-route-provider';
import { NotificationsService } from 'src/app/services/notifications.service';


//SAVE SKAL OPDATERE LISTEN MED EKSISTERENDE ROUTES FRA DB

class Editor extends React.Component {

    pathCounter = 1;

    constructor(props) {
        super(props);
        this.state = {
          routes: this.props.providers,
          types : [{value: 'images', label: 'images'}],
          nodesOnBoard : [],
          deletedRoutes : [],
          preActions : [],
          idCounter : 0,
          notificationsService : new NotificationsService()
        };

    }

    UNSAFE_componentWillMount() {
        this.refresh = this.refresh.bind(this)
        this.save = this.save.bind(this)
        this.onConnect = this.onConnect.bind(this)
        this.onElementsRemove = this.onElementsRemove.bind(this)
        this.insertExistingRoutesOnStart()
    }

    getExistingRoutes(){
      let saved_route = this.props.routes
      let routes = []

      console.log("saved routes")
      console.log(saved_route)

      //recreated the saved routes from DB and insert them to the board
      saved_route.forEach(route => {
        let id = this.getNodeId()
        const newNode = {
          id: id,
          type:"routesOnBoard",
          key: id,
          data: {
            label: route.providerDetails.name,
            id : id,
            triggerElement: route.kind ? route.kind : "" ,
            confidence: route.threshold,
            applyAllImages: route.triggerForAll,
            enabled: route.enabled,
            cost : route.providerDetails.cost,
            priority : route.priority,
            isStartingPoint : route.isStartingPoint,
            newRoute : false,
            db_id : route.id
          },
          position: {
            x:250,
            y: 250,
          },
        };
        routes.push(newNode)
      });

      routes.sort((r1, r2) => (r1.data.priority > r2.data.priority) ? 1 : -1)

      let startingPoint_id = this.getNodeId()
      const startingPoint = {
        id: startingPoint_id,
        type:"routesOnBoard",
        data: {
         label: "Starting point",
         id : startingPoint_id,
         triggerElement: "",
         confidence: 0,
         applyAllImages: true,
         enabled: true,
         cost : 0,
         priority : 100,
         isStartingPoint : true,
         appliesTo : "images"
       },
        position: {
          x:250,
          y: 250,
        },
      }

      //insert starting point in front of list
      routes.unshift(startingPoint)

      //recreates positions for the old routes
      let width = 200 * routes.length
      routes.forEach((route, i) => {
        route.position.x = 100 + i*(width/routes.length)
        route.position.y = 250 + i*20
      });

      return routes
    }

    getExistingConnections(elements){
      //creates an connections between the exsisting routes
      let length = elements.length
      let nodes = elements
      for (let i = 0; i < length; i++) {
        if(i == length - 1){
          break
        }
        const curNode = nodes[i];
        const nextNode = nodes[i+1];

        let params = {
          source: curNode.id ,
          sourceHandle: null,
          target: nextNode.id,
          targetHandle: null
        }
        elements = addEdge(params, elements)
      }
      return elements
    }

    insertExistingRoutesOnStart(){
      if(this.props.routes.length < 1){
        return
      }
      let routes = this.getExistingRoutes()
      this.state.nodesOnBoard = routes
      let connections = this.getExistingConnections(routes)
      this.state.nodesOnBoard = connections
    }

    insertExistingRoutes(){
      this.setState({nodesOnBoard : []})
      let routes = this.getExistingRoutes()
      let connections = this.getExistingConnections(routes)

      this.setState({nodesOnBoard : connections})
    }

    undo(){
      let preActions = this.state.preActions;

      if(preActions.length < 1){
        return
      }

      let lastAction = preActions[preActions.length - 1]
      preActions.pop()
      this.setState({preActions : preActions})

      if(lastAction.type == "removingRoute"){
        let elements = this.state.nodesOnBoard.concat(lastAction.route)
        this.setState({nodesOnBoard : elements})
        return
      }else if(lastAction.type == "addingNode"){
        let elements = this.state.nodesOnBoard
        const new_elements = elements.filter((item) => item.id !== lastAction.route.id);
        this.setState({nodesOnBoard : new_elements})
        return

      }else if(lastAction.type == "addingEgde"){
        let elements = this.state.nodesOnBoard
        const new_elements = elements.filter((item) => (item.source !== lastAction.egde.source) && (item.target !== lastAction.egde.target));
        this.setState({nodesOnBoard : new_elements})
        return

      }else if(lastAction.type == "removingEgde"){
        let elements = this.state.nodesOnBoard.concat(lastAction.egde)
        this.setState({nodesOnBoard : elements})
        return
      }

    }

    renderActionMenu(){
        return(
          <div className="menu">
          <Button onClick={() => this.refresh()} variant="outlined" color="primary" className="button">
            Reset
          </Button>
          &nbsp;&nbsp;
          <Button  onClick={() => this.save()} variant="outlined" color="primary" className="button">
            Save
          </Button>
          </div>
        )
    }

    matchProviders(route_label){
      for (let i = 0; i < this.state.routes.length; i++) {
        const route = this.state.routes[i];

        if(route.name == route_label){
          return route.id
        }

      }
      return ""
    }


    save(){
      let paths = this.createPath()
      console.log(paths)

      let imageRoutes = []
      let updatedRoutes = []
      const path = paths[0];

      if((path.length < 2) && (path[0].data.isStartingPoint)){
        let error_msg = " The path contains no routes "
        this.state.notificationsService.showInfo('Error', error_msg, 'error', 'Close', false);
        return
      }

      if(!path[0].data.isStartingPoint){
        let error_msg = " The path is missing a starting-point "
        this.state.notificationsService.showInfo('Error', error_msg, 'error', 'Close', false);
        return
      }

      let groupid = 1
      let unit_type = path[0].data.appliesTo
      let prev_priority = 0

      if(unit_type == ""){
        let error_msg = " The path has no unit "
        this.state.notificationsService.showInfo('Error', error_msg, 'error', 'Close', false);
        return
      }

      for (let j = 0; j < path.length; j++) {
        const route = path[j];

        if(route.data.priority < prev_priority){
          let error_msg = " The priorities do not follow the order of the routes "
          this.state.notificationsService.showInfo('Error', error_msg, 'error', 'Close', false);
          return
        }



        if(!route.data.isStartingPoint){
          let imageRoute = new ImageRoute()
          imageRoute.id = route.data.db_id
          imageRoute.priority = parseInt(route.data.priority)
          imageRoute.triggerForAll = route.data.applyAllImages
          imageRoute.provider = this.matchProviders(route.data.label)
          imageRoute.enabled = route.data.enabled,
          imageRoute.media = unit_type

          if(!imageRoute.triggerForAll){
            imageRoute.kind = route.data.triggerElement
            imageRoute.threshold = parseInt(route.data.confidence)
          }

          if (imageRoute.provider == ""){
            let error_msg = " The provider does not exists "
            this.state.notificationsService.showInfo('Error', error_msg, 'error', 'Close', false);
            return
          }
          if(route.data.newRoute){
            imageRoutes.push(imageRoute)
          }

          if(!route.data.newRoute){
            console.log("updating route ")
            updatedRoutes.push(imageRoute) //virker ikke
          }

          prev_priority = route.data.priority
        }
      }


      imageRoutes.forEach(imageRoute => {
        this.props.insertRoute(imageRoute)
      });

      updatedRoutes.forEach(imageRoute => {
        this.props.updateRoute(imageRoute)
      });

      //deletes the routes from DB that has been deleted from the board
      this.props.routes.forEach(oldRoute => {
        let shouldDelete = true
        for (let i = 0; i < path.length; i++) {
          const route = path[i];
          if((!route.data.newRoute) && (route.data.db_id == oldRoute.id)){
            //the route is not deleted
            shouldDelete = false
          }
        }
        if(shouldDelete){
          console.log("deletes route")
          this.props.deleteRoute(db_id) //virker ikke
        }
      });
    }


    refresh(){
        //lav en pop-op, der spørger om brugeren virkelig vil gøre det (dette vil nemlig )
        this.setState({nodesOnBoard : []})
        this.setState({preActions : []})
        this.insertExistingRoutes()
    }


    onElementsRemove = (elementsToRemove) => {
        let elements = this.state.nodesOnBoard
        elements = removeElements(elementsToRemove, elements)
        this.setState({nodesOnBoard: elements})

        let deletedRoutes = this.state.deletedRoutes
        deletedRoutes.push(elementsToRemove)
        this.setState({deletedRoutes: deletedRoutes})

        //det vil altid kun være en node og en egde vi sletter

        elementsToRemove.reverse().forEach(element => {
          if(element.hasOwnProperty("source") && element.hasOwnProperty("target")){
            let action = {
              type : "removingEgde",
              egde : element,
            }
            let preActions = this.state.preActions
            preActions.push(action)
            this.setState({preActions : preActions})
          }else{
            let action = {
              type : "removingRoute",
              route : element,
            }
            let preActions = this.state.preActions
            preActions.push(action)
            this.setState({preActions : preActions})
          }
        });
    }

    isInputRoute(id, routes){
      for (let i = 0; i < routes.length; i++) {
        const route = routes[i];

        if(route.id == id){
          if(route.type == 'inputlabel'){
            return true
          }
        }
      }
      return false
    }

    hasEgde(source, target, edges){
      for (let i = 0; i < edges.length; i++) {
        const egde = edges[i];

        if((egde.source == source) || (egde.target == target)){
          return true
        }
      }
      return false
    }


    isAncestor(source, target, edges){
      let isAncestor = false
      let isRoot = false

      if(edges.length < 1){
        return isAncestor
      }

      let curTarget = source
      while((!isAncestor) && (!isRoot)){
        let foundNextEgde = false

        for (let i = 0; i < edges.length; i++) {
          const egde = edges[i];

          if(egde.target == curTarget){
            curTarget = egde.source
            foundNextEgde = true

            if(egde.source == target){
              isAncestor = true
            }
            break;
          }

          if((!foundNextEgde) && (i == edges.length -1)){
            isRoot = true
          }
        }
      }

      return isAncestor

    }

    isStartingPoint(id, routes){
      for (let i = 0; i < routes.length; i++) {
        const route = routes[i];
        if (route.data.isStartingPoint && route.id == id){
          return true
        }
      }
      return false
    }

  onElementClick = (node) => {
    console.log('Clicked on ' + JSON.stringify(node.id));

  };

    onConnect = (params) => {
      if(params.source == params.target){
        return
      }

      let elements = this.state.nodesOnBoard

      let routes = []
      let edges = []
      elements.forEach(element => {
        if(element.hasOwnProperty("source") && element.hasOwnProperty("target")){
          edges.push(element)
        }else{
          routes.push(element)
        }
      });

      if(this.isStartingPoint(params.target, routes)){
        return
      }

      if(this.isAncestor(params.source, params.target, edges)){
        return
      }

      if(!this.hasEgde(params.source, params.target, edges)){
        elements = addEdge(params, elements)
        this.setState({nodesOnBoard: elements})
      }

      let action = {
        type : "addingEgde",
        egde : params,
      }
      let preActions = this.state.preActions
      preActions.push(action)
      this.setState({preActions : preActions})
    }

    getNodeId = () => {
      let newIdCounter = this.state.idCounter +1
      this.setState({idCounter : newIdCounter})
      return `randomnode_${+new Date()}${+ Math.floor(Math.random() * 36)}${+ Math.floor(Math.random() * this.state.idCounter)}`
    };

    onInsertStartingPoint(){
      for (let i = 0; i < this.state.nodesOnBoard.length; i++) {
        const node = this.state.nodesOnBoard[i];
        if(!node.hasOwnProperty("source") && !node.hasOwnProperty("target")){
          if(node.data.isStartingPoint){
            return
          }
        }
      }

      let id = this.getNodeId()
       const newNode = {
         id: id,
         type:"routesOnBoard",
         data: {
          label: "Starting point",
          id : id,
          triggerElement: "",
          confidence: 0,
          applyAllImages: true,
          enabled: true,
          cost : 0,
          priority : 100,
          isStartingPoint : true,
          appliesTo : "images"
        },
         position: {
           x:250,
           y: 250,
         },
       };
       let elements = this.state.nodesOnBoard.concat(newNode)
       this.setState({nodesOnBoard : elements})

       let action = {
        type : "addingNode",
        route : newNode,
      }
      let preActions = this.state.preActions
      preActions.push(action)
      this.setState({preActions : preActions})

    }

    onInsertRoute(route){
      debugger;
       let id = this.getNodeId()
       const newNode = {
         id: id,
         type:"routesOnBoard",
         data: {
          label: route.name,
          id : id,
          triggerElement: "",
          confidence: 0,
          applyAllImages: true,
          enabled: true,
          cost : route.cost,
          priority : 100,
          isStartingPoint : false,
          newRoute : true,
          db_id : ""
        },
         position: {
           x:250,
           y: 250,
         },
       };
       let elements = this.state.nodesOnBoard.concat(newNode)
       this.setState({nodesOnBoard : elements})

      let action = {
        type : "addingNode",
        route : newNode,
      }
      let preActions = this.state.preActions
      preActions.push(action)
      this.setState({preActions : preActions})
    }

    getStyle(c){
      const style = {
        background: c,
        color: '#FFF',
        padding : 8,
        width: 180,
        height: 60,
        margin:0,
      };
      return style
    }


    NodeStyle = ({ data }) => {
      return (
        <div>
          {
            data.isStartingPoint ?
              <div> </div>
              :
              <Handle type="target" position="left" style={{ borderRadius: 0 }} />
          }
          <Node types={this.state.types} route={data} updateNodesOnBoard={this.updateNodesOnBoard.bind(this)} isRoot={this.isRoot.bind(this)}  />
          <Handle type="source" position="right" style={{ borderRadius: 0 }} />
        </div>
      );
    };

    isRoot(node){
      let edges = []
      let routes = []
      let isStartingPoint = ""

      this.state.nodesOnBoard.forEach(element => {
        if(element.hasOwnProperty("source") && element.hasOwnProperty("target")){
          edges.push(element)
        }else{
          routes.push(element)
        }
      });

      for (let i = 0; i < routes.length; i++) {
        const route = routes[i];

        if(route.data.isStartingPoint){
          isStartingPoint = route.id
        }
      }

      for (let i = 0; i < edges.length; i++) {
        const egde = edges[i];
        if((egde.target == node.id) && (isStartingPoint != egde.source)){
          return false
        }
      }

      node.applyAllImages = true
      //ændre nodens attributter, så de passer med at det er en root
      return true
    }


    findPaths(nodes, edges, node){
      let paths = []
      let childeren = []

      edges.forEach(egde => {
        if(node.id == egde.source){
          let child = nodes.filter(n => n.id == egde.target)[0]
          childeren.push(child)
        }
      });

      if(!node.data.enabled){
        return [[]]
      }

      //bottom case
      if(childeren.length <= 0 ){
        return [[node]]
      }

      childeren.forEach(child => {
        let child_paths = this.findPaths(nodes, edges, child)


        child_paths.forEach(path => {
          paths.push(path.concat(node))
        });

      });

      return paths
    }

    createPath(){
      //sorter starting point fra
      //sorter alle edges fra starting point fra

      let elements = this.state.nodesOnBoard
      let nodes = []
      let edges = []
      let roots = []
      let paths = []

      this.state.nodesOnBoard.forEach(element => {
        if(element.hasOwnProperty("source") && element.hasOwnProperty("target")){
          edges.push(element)
        }else{
            nodes.push(element)

        }
      });

      //finds all roots
      nodes.forEach(node => {
        let isRoot = true
        for (let i = 0; i < edges.length; i++) {
          const edge = edges[i];
          if(node.id == edge.target){
            isRoot = false
            break;
          }
        }
        if(isRoot){
          roots.push(node)
        }
      });

      //finds the paths for each root
      roots.forEach(root => {
        //de bliver returneret i omvendt rækkefølge
        let root_paths = this.findPaths(nodes, edges, root)
        root_paths.forEach(path => {
          path = path.reverse()
          paths.push(path)
        });
      });

      //for all roots find de lister, hvor de er rooten
      let pathGroupByRoot = []
      roots.forEach(root => {
        let all_root_paths = []

        paths.forEach(path => {
          if (path[0] == root){
            all_root_paths.push(path)
          }
        });

        pathGroupByRoot.push([root, all_root_paths])
      });


      return paths
    }

    calculateCost(path){
      let cost = 0
      path.forEach(route => {
        cost = cost + parseInt(route.data.cost)
      });
      return cost
    }

    renderPath(path){
      let cost = this.calculateCost(path)

      return(
        <div className="route-groupe" key={'path-total-' + this.pathCounter++ }>
          {path.map((route, i) =>
            <div className="configuration-route" key={'path-' + route.id + '_' + i}>
              <div className="center-text-container">
              <span className="root-text">
              {route.data.label}
              </span>
              <br/>
              <span className="configuration-details">Execution order: {i + 1}</span>
              </div>
            </div>
          )}
          <div className="center-text-container">
          <span className="cost-text">Total cost: {cost}</span>
          </div>
        </div>
      )
    }

    renderConfiguration(){
      let paths = this.createPath()

      return(
        <div className="routeScroller">
        <div className="routeMenuInput">
          {paths.map(path =>
            this.renderPath(path)
          )}
        </div>
        </div>
      )

    }

    updateNodesOnBoard(node_data){
      let nodes = this.state.nodesOnBoard
      nodes.find(node => node.id == node_data.id).data = node_data
      this.setState({nodesOnBoard : nodes})
    }

    renderBoard(){
        return(
            <div>
              <div className="routeMenu">
              <RouteMenu routes={this.state.routes} insertRoute={this.onInsertRoute.bind(this)} insertStartingPoint={this.onInsertStartingPoint.bind(this)}  />
              </div>
              <div className="Board">
                <Button  onClick={() => this.undo()} variant="contained" color="primary" className="undo-button">
                  Undo
                </Button>
                <ReactFlow
                nodeTypes={{routesOnBoard: this.NodeStyle }}
                elements={this.state.nodesOnBoard}
                onElementsRemove={this.onElementsRemove}
                deleteKeyCode={8}
                onConnect={this.onConnect}
                >
                  <Controls showInteractive={true}>
                  </Controls>
                </ReactFlow>
              </div>

            <div className="configurationMenu">
              <div className="menuTop">
              <div className="center-text-container">
                <h2 className="configurationMenuHeader">Your Route Paths</h2>
              </div>
              </div>
              <div>
              {this.renderConfiguration()}
              </div>

           </div>


          </div>
        )
    }


    render() {
        return (
            <div>
                {this.renderActionMenu()}
                {this.renderBoard()}
            </div>
        );
    }

}
export default Editor;
