<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="row">
          <div class="col-12 grid-margin stretch-card">
            <h1>{{title}}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    SELECT * from faces f
                    <app-sqleditor #editor></app-sqleditor>
                    <button class="btn btn-outline-primary" (click)="doSearch()">Search</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <app-loader *ngIf="showLoader"></app-loader>
                <h3 *ngIf="!showLoader && !faces">Define query and click search to view faces that match</h3>
                <div class="row portfolio-grid">
                  <div *ngFor="let face of faces" class="col-2 grid-margin stretch-card">
                    <a [routerLink]="'/faces/' + face.id">
                      <figure class="effect-text-in" data-toggle="tooltip" data-placement="bottom" title="Click to edit">
                        <img [src]="'processed-images/faces/' + face.id + '.png'" class="transition-2" />
                        <figcaption>
                          <h4 class="unknown">Face #{{face.id}}</h4>
                          <p>Sharpness: {{face.sharpness | number}}%, Brightness: {{face.brightness | number}}%, Confidence
                            is face: {{face.confidence | number}}%, Image: {{face.image.id}}, Size: {{face.relativeSize | number}}%</p>
                        </figcaption>
                      </figure>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 grid-margin stretch-card" *ngIf="showMoreButton">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <app-loader *ngIf="showLoader"></app-loader>
                  <button class="btn btn-outline-primary" (click)="loadMoreFaces()">Load More Faces</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>