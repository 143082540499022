import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((err) => {
            console.log('Caught error: ' + JSON.stringify(err));
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                this.router.navigate(['/login']);
            } else if (err.status === 402) {
                // auto logout if 402 response returned from api
                // this.authenticationService.logout();
                this.router.navigate(['/subscription'], { queryParams: { message: '402' } });
            } else if (err.status === 405) {
                // auto logout if 405 response returned from api
                // this.authenticationService.logout();
                this.router.navigate(['/subscription'], { queryParams: { message: '405' } });
            } else if (err.status === 412) {
                // auto logout if 412 response returned from api
                // this.authenticationService.logout();
                this.router.navigate(['/subscription'], { queryParams: { message: '412' } });
            }
            return throwError(err);
        }));
    }
}
