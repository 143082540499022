import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { BugReport } from 'src/app/model/BugReport';
import { BugReportService } from 'src/app/services/bug-report.service';
import { EditComponent } from '../edit/edit.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-list-all-bugs',
  templateUrl: './list-all.component.html',
  styleUrls: ['./list-all.component.css']
})
export class ListAllComponent implements OnInit {

  editor: EditComponent;

  showLoader: boolean;
  reports: BugReport[];
  parentId: number;


  constructor(
    private bugService: BugReportService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.refresh();
    this.route.params.subscribe((p) => this.refresh());
  }

  public refresh() {
    this.showLoader = true;
    const pId = this.route.snapshot.paramMap.get('parentId');

    if (pId) {
      this.parentId = Number(pId);
    } else {
      this.parentId = undefined;
    }

    this.bugService.listAll(this.parentId).subscribe(bugs => {
      this.reports = bugs;
      this.showLoader = false;
    });
  }

  showDetails(bug: BugReport) {
    this.editor.selected = bug;
  }

}
