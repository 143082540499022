import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LatestPhotosComponent } from './pages/images/latest-photos/latest-photos.component';
import { AuthGuard } from './guards';
import { LoginComponent } from './pages/login/login.component';
import { StatisticsComponent } from './pages/other/statistics/statistics.component';
import { DatamodelComponent } from './pages/other/datamodel/datamodel.component';
import { ManualUploadComponent } from './pages/other/manual-upload/manual-upload.component';
import { ViewPersonsComponent } from './pages/persons/view-persons/view-persons.component';
import { PersonDetailsComponent } from './pages/persons/person-details/person-details.component';
import { ViewPhotoComponent } from './pages/images/view-photo/view-photo.component';
import { ProfileSettingsComponent } from './pages/profile-settings/profile-settings.component';
import { MergePersonsComponent } from './pages/persons/merge-persons/merge-persons.component';
import { VerificationQueueComponent } from './pages/persons/verification-queue/verification-queue.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ListUsersComponent } from './pages/other/list-users/list-users.component';
import { ScanS3BucketComponent } from './pages/other/scan-s3-bucket/scan-s3-bucket.component';
import { MonitoringComponent } from './pages/other/monitoring/monitoring.component';
import { ViewFaceComponent } from './pages/faces/view-face/view-face.component';
import { DoubleNamedComponent } from './pages/persons/double-named/double-named.component';
import { SearchLogsComponent } from './pages/other/search-logs/search-logs.component';
import { SearchBadFacesComponent } from './pages/faces/search-bad-faces/search-bad-faces.component';
import { RawFaceSearchComponent } from './pages/faces/raw-face-search/raw-face-search.component';
import { ElvisComponent } from './pages/other/settings/elvis/elvis.component';
import { FaceMatchingComponent } from './pages/other/settings/face-matching/face-matching.component';
import { PersonMatchingComponent } from './pages/other/settings/person-matching/person-matching.component';
import { ProcessingComponent } from './pages/other/settings/processing/processing.component';
import { ResetSystemComponent } from './pages/other/settings/reset-system/reset-system.component';
import { RawPersonSearchComponent } from './pages/persons/raw-search/raw-search.component';
import { ShowPersonFaceTreeComponent } from './pages/persons/show-person-face-tree/show-person-face-tree.component';
import { ShowFaceBranchComponent } from './pages/faces/show-face-branch/show-face-branch.component';
import { FailedImagesComponent } from './pages/other/failed-images/failed-images.component';
import { KnownNamesComponent } from './pages/other/known-names/known-names.component';
import { SearchComponent } from './pages/persons/search/search.component';
import { GridComponent } from './pages/test/grid/grid.component';
import { ListNotFoundComponent } from './pages/other/list-not-found/list-not-found.component';
import { IgnoredComponent } from './pages/persons/ignored/ignored.component';
import { PotentialDuplicatesComponent } from './pages/persons/potential-duplicates/potential-duplicates.component';
import { ComparePersonsComponent } from './pages/persons/compare-persons/compare-persons.component';
import { ManageComponent } from './pages/bugreports/manage/manage.component';
import { ListBatchJobsComponent } from './pages/other/batch/list-batch-jobs/list-batch-jobs.component';
import { FacetreeRootsComponent } from './pages/persons/facetree-roots/facetree-roots.component';
import { CompareLogsComponent } from './pages/other/compare-logs/compare-logs.component';
import { ElvisPushComponent } from './pages/other/elvis-push/elvis-push.component';
import { SubscriptionLevelComponent } from './pages/other/subscription-level/subscription-level.component';
import { SilverGuard } from './guards/silver.guard';
import { GoldGuard } from './guards/gold.guard';
import { ImageRoutingComponent } from './pages/other/image-routing/image-routing.component';

export const routes: Routes = [
          { path: '', redirectTo: '/dashboard', pathMatch: 'full', canActivate: [AuthGuard] },
          { path: 'login', component: LoginComponent },
          { path: 'subscription', component: SubscriptionLevelComponent },
          { path: 'bugs', component: ManageComponent, data: { breadcrumb: 'Bugs' }, canActivate: [AuthGuard]},
          { path: 'bugs/:parentId', component: ManageComponent, data: { breadcrumb: 'Bugs' }, canActivate: [AuthGuard]},
          { path: 'test/grid', component: GridComponent, data: { breadcrumb: 'Test' }, canActivate: [AuthGuard]},
          { path: 'dashboard', component: DashboardComponent, data: { breadcrumb: 'Dashboard' }, canActivate: [AuthGuard] },
          { path: 'monitoring', component: MonitoringComponent, data: { breadcrumb: 'Dashboard' }, canActivate: [AuthGuard] },
          { path: 'upload', component: ManualUploadComponent, data: { breadcrumb: 'Upload' }, canActivate: [AuthGuard] },
          { path: 'images', component: LatestPhotosComponent, data: { breadcrumb: 'Images' }, canActivate: [AuthGuard] },
          { path: 'images/routing', component: ImageRoutingComponent, data: { breadcrumb: 'Image Routes' }, canActivate: [AuthGuard] },
          { path: 'images/failed', component: FailedImagesComponent, data: { breadcrumb: 'Failed Images' }, canActivate: [AuthGuard] },
          { path: 'images/failed/notfound', component: ListNotFoundComponent, data: { breadcrumb: 'Settings / Elvis / Not Found'}, canActivate: [AuthGuard] },
          { path: 'verification', component: VerificationQueueComponent, data: { breadcrumb: 'Verification' }, canActivate: [AuthGuard] },
           { path: 'images/:imageId', component: ViewPhotoComponent, data: { breadcrumb: 'Images/Details'} , canActivate: [AuthGuard]},
           { path: 'images/elvis/:elvisId', component: ViewPhotoComponent, data: { breadcrumb: 'Images/Details'} , canActivate: [AuthGuard]},
           { path: 'faces/query', component: RawFaceSearchComponent, data: { breadcrumb: 'Faces/Advanced'} , canActivate: [AuthGuard]},
           { path: 'faces/search', component: SearchBadFacesComponent, data: { breadcrumb: 'Faces/Search'} , canActivate: [AuthGuard, GoldGuard]},
           { path: 'faces/:faceId', component: ViewFaceComponent, data: { breadcrumb: 'Faces/Details'} , canActivate: [AuthGuard]},
           { path: 'faces/:faceId/facetree', component: ShowFaceBranchComponent, data: { breadcrumb: 'Faces/FaceTree'} , canActivate: [AuthGuard]},
           { path: 'persons', component: ViewPersonsComponent, data: { breadcrumb: 'Persons'}, canActivate: [AuthGuard] },
           { path: 'persons/double-named', component: DoubleNamedComponent, data: { breadcrumb: 'Persons'}, canActivate: [AuthGuard] },
           { path: 'persons/search', component: SearchComponent, data: { breadcrumb: 'Persons / Search'}, canActivate: [AuthGuard] },
           { path: 'persons/ignored', component: IgnoredComponent, data: { breadcrumb: 'Persons / Ignored'}, canActivate: [AuthGuard] },
           { path: 'persons/query', component: RawPersonSearchComponent, data: { breadcrumb: 'Persons / Search'}, canActivate: [AuthGuard] },
           { path: 'persons/:personId', component: PersonDetailsComponent, data: { breadcrumb: 'Persons / Person'}, canActivate: [AuthGuard] },
           { path: 'persons/:personId/facetree', component: ShowPersonFaceTreeComponent, data: { breadcrumb: 'Persons / FaceTree'}, canActivate: [AuthGuard] },
           { path: 'persons/:personId/facetree-roots', component: FacetreeRootsComponent, data: { breadcrumb: 'Persons / FaceTree'}, canActivate: [AuthGuard] },
           { path: 'persons/:personId/duplicates', component: PotentialDuplicatesComponent, data: { breadcrumb: 'Persons / Duplicates'}, canActivate: [AuthGuard, SilverGuard] },
           { path: 'persons/:personId/comparewith/:comparePersonId', component: ComparePersonsComponent, data: { breadcrumb: 'Persons / Compare'}, canActivate: [AuthGuard] },
           { path: 'merge/:personId', component: MergePersonsComponent, data: { breadcrumb: 'Persons / Merge'}, canActivate: [AuthGuard] },
           { path: 'profile', component: ProfileSettingsComponent, data: { breadcrumb: 'Settings'}, canActivate: [AuthGuard] },
           { path: 'datamodel', component: DatamodelComponent, data: { breadcrumb: 'DataModel'}, canActivate: [AuthGuard] },
           { path: 'elvis-push', component: ElvisPushComponent, data: { breadcrumb: 'Elvis Push'}, canActivate: [AuthGuard] },
           { path: 'scanbucket', component: ScanS3BucketComponent, data: { breadcrumb: 'Scan Bucket'}, canActivate: [AuthGuard] },
           { path: 'statistics', component: StatisticsComponent, data: { breadcrumb: 'Statistics'}, canActivate: [AuthGuard]  },
           { path: 'known-names', component: KnownNamesComponent, data: { breadcrumb: 'Known Names'}, canActivate: [AuthGuard]  },
           { path: 'logs/compare', component: CompareLogsComponent, data: { breadcrumb: 'Logs'}, canActivate: [AuthGuard]  },
           { path: 'logs/:logGroup/:logStream/:query', component: SearchLogsComponent, data: { breadcrumb: 'Logs'}, canActivate: [AuthGuard]  },
           { path: 'users', component: ListUsersComponent, data: { breadcrumb: 'Users'}, canActivate: [AuthGuard]  },
           { path: 'settings/elvis', component: ElvisComponent, data: { breadcrumb: 'Settings / Elvis'}, canActivate: [AuthGuard] },
           { path: 'settings/face-matching', component: FaceMatchingComponent, data: { breadcrumb: 'Settings / Faces'}, canActivate: [AuthGuard] },
           { path: 'settings/person-matching', component: PersonMatchingComponent, data: { breadcrumb: 'Settings/ Persons'}, canActivate: [AuthGuard] },
           { path: 'settings/processing', component: ProcessingComponent, data: { breadcrumb: 'Settings / Processing'}, canActivate: [AuthGuard] },
           { path: 'settings/batch', component: ListBatchJobsComponent, data: { breadcrumb: 'Settings / Batch'}, canActivate: [AuthGuard] },
           { path: 'settings/reset', component: ResetSystemComponent, data: { breadcrumb: 'Settings / Reset'}, canActivate: [AuthGuard] },
           // { path: 'videos/:videoId', component: ShowVideoDetailsComponent, data: { breadcrumb: 'Videos/Details'} },
           // { path: 'text', component: TranslatorComponent, data: { breadcrumb: 'Text'} },
           // { path: 'videos', component: LatestVideosComponent, data: { breadcrumb: 'Videos' } },


  // { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
  // { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
