<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="row">
          <div class="col-12">
            <h1>{{ title }}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="dropdown" style="display: inline-block;" *ngIf="statistics && regions">
                      <button type="button" class="btn btn-outline-primary icon-btn dropdown-toggle"
                        id="selectRegionButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Show pending verifications for: {{(state.selectedRegion) ? state.selectedRegion.name : ''}} <i
                          *ngIf="state.selectedRegion" class="flag-icon flag-icon-{{state.selectedRegion.code}}"></i>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="selectRegionButton">
                        <h6 class="dropdown-header">Search for pending verifications in </h6>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" *ngFor="let n of regions" (click)="setRegion(n)"><i
                            class="flag-icon flag-icon-{{n.code}}"></i> {{n.name}} ({{getPending(n.code)}} pending)</a>
                      </div>
                    </div>
                    <div class="dropdown" style="display: inline-block;">
                        <button type="button" class="btn btn-outline-primary icon-btn dropdown-toggle"
                        id="selectOrderButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Order by {{state.orderText}}
                      </button>

                      <div class="dropdown-menu" aria-labelledby="selectOrderButton">
                        <h6 class="dropdown-header">Order faces by</h6>
                        <a class="dropdown-item" (click)="setOrder('created_desc')">Newest first</a>
                        <a class="dropdown-item" (click)="setOrder('created_asc')">Oldest first</a>
                        <a class="dropdown-item" (click)="setOrder('name')">Name</a>
                      </div>
                    </div>
                    <span *ngIf="statistics && regions">Keep verified on screen: </span>
                    <label class="switch" *ngIf="statistics && regions">

                      <input type="checkbox" [(ngModel)]="state.showAlreadyVerified" (change)="updateShown()">
                      <span class="slider round"></span>
                    </label>
                  </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="row" *ngIf="faces">
          <div class="col-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <h2>Showing {{faces.length}} pending verifications (total pending for {{state.selectedRegion.name }} is {{getPending(state.selectedRegion.code)}})</h2>
                    <app-face-menu #faceMenu [listener]="this"></app-face-menu>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Face</th>
                        <th>Confidence</th>
                        <th>Seen</th>
                        <th>Matched to</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let face of faces" (contextmenu)="showFaceContextMenu($event, face)">
                        <td style="align-content: center">
                          <img [src]="'processed-images/faces/' + face.id + '.png'" *ngIf="!face.showLoader" class="image" />
                          <app-inline-loader *ngIf="face.showLoader"></app-inline-loader>
                        </td>
                        <td>{{face.personConfidence | number }} %</td>
                        <td>{{ face.created | date:'yyyy-MMM-dd HH:mm' | uppercase  }}</td>
                        <td>
                          <a (click)="showPerson(face.person)" style="cursor: pointer;">{{ face.person.name }}
                        </a></td>
                        <td>

                          <button *ngIf="!face.personVerified" class="btn btn-outline-success" (click)="verifyFace(face)">Verify</button>


                          <button *ngIf="face.personVerified" class="btn btn-outline-warning" (click)="unverifyFace(face)">Remove Verification</button>


                          <a class="btn btn-outline-primary" [routerLink]="'/images/' + face.image.id">View Full Image</a>
                          <button class="btn btn-outline-secondary" (click)="showFaceContextMenu($event, face)">More</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>