import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { ImageRoute } from 'src/app/model/image-route';
import { MetataggerRouteProvider } from 'src/app/model/metatagger-route-provider';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EnvironmentsService } from 'src/app/services/environments.service';
import { ImageRoutingService } from 'src/app/services/image-routing.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TitleService } from 'src/app/services/title.service';
import {EditorWrapper} from './editor-wrapper';

@Component({
  selector: 'app-image-routing',
  templateUrl: './image-routing.component.html',
  styleUrls: ['./image-routing.component.css']
})
export class ImageRoutingComponent implements OnInit {

  @ViewChild('editorWrapper') editorWrapper: EditorWrapper;

  routes: ImageRoute[];
  providers: MetataggerRouteProvider[];
  updateMode = false;
  temp: ImageRoute = new ImageRoute();
  showLoader: boolean;
  tempstring: string;

  constructor(
    public authService: AuthenticationService,
    private notificationService: NotificationsService,
    private envService: EnvironmentsService,
    private titleService: TitleService,
    private imageRoutingService: ImageRoutingService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Image Routing');
    this.refreshRoutes();
    this.imageRoutingService.listProviders().subscribe((p) => {
      p.sort((a, b) => a.name.localeCompare(b.name));
      this.providers = p;
      this.matchRoutesWithProviders();
      this.updateSelectedProvider();
    });
  }

  refreshRoutes() {
    this.routes = undefined;
    this.imageRoutingService.listRoutes().subscribe((r) => {
      this.routes = r;
      this.matchRoutesWithProviders();
      this.updateSelectedProvider();
      this.showLoader = false;
      console.log('Routes refreshed - found ' + this.routes.length);
      this.changeDetector.detectChanges();
    });
  }

  updateSelectedProvider() {
    if (this.temp && this.providers && !this.temp.provider && this.providers.length > 0) {
      this.temp.provider = this.providers[0].id;
    }
    if (this.providers) {
      for (const p of this.providers) {
        if (p.id === this.temp.provider) {
          this.temp.providerDetails = p;
          break;
        }
      }
    }

  }

  matchRoutesWithProviders() {
    if (this.routes && this.providers) {
      for (const r of this.routes) {
        let found = false;
        for (const p of this.providers) {
          if (p.id === r.provider) {
            r.providerDetails = p;
            found = true;
            break;
          }
        }
        if (!found) {
          r.providerDetails = {
            name: 'Unknown - ' + r.provider
          } as MetataggerRouteProvider;
        }
      }
    }
  }

  addRoute() {
    this.showLoader = true;
    this.temp.providerDetails = undefined;
    if (this.updateMode) {
      this.imageRoutingService.updateRoute(this.temp).subscribe((s) => {
        this.notificationService.showInfo('Server said', s, 'info', 'Close', false);
        this.refreshRoutes();
      })

    } else {
      this.imageRoutingService.createRoute(this.temp).subscribe((s) => {
        this.notificationService.showInfo('Server said', s, 'info', 'Close', false);
        this.refreshRoutes();
      })

    }

    this.cancelEdit();
  }

  addRouteToBoard() {
    const t = new ImageRoute();

    t.provider = this.providers[0].id;
    t.enabled = true;

    this.editorWrapper.routes.push(t);
    this.changeDetector.detectChanges();
  }

  cancelEdit() {
    this.updateMode = false;
    this.temp = new ImageRoute();
    this.updateSelectedProvider();
  }

  editRoute(p: MetataggerRouteProvider) {
    this.temp = JSON.parse(JSON.stringify(p));
    this.updateMode = true;
  }

  async deleteRoute(id: string) {
    const resp = await this.notificationService.showQuestion('Delete Route?',
    'Route will be deleted - this cannot be undone.', 'warning', 'Delete', 'Cancel');

    if (resp) {
      this.showLoader = true;
      this.imageRoutingService.deleteRoute(id).subscribe((c) => {
        console.log('Route was deleted - refreshing routes');
        this.showLoader = false;
        this.refreshRoutes();
      });
    }
  }


}
