import { MetataggerRouteProvider } from "./metatagger-route-provider";


export class ImageRoute {

    public id: number;
    public group_id: number; //added in order to create more paths
    public priority: number = 100;
    public threshold: number;
    public triggerForAll: boolean = false;
    public kind: string;
    public provider: string;
    public enabled: boolean = true;
    public media: string = 'images';

    public lastUpdate: moment.Moment;
    public created: moment.Moment;

    public providerDetails: MetataggerRouteProvider;

}
