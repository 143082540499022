import { Component, OnInit } from '@angular/core';
import { Environment } from 'src/app/model/Environment';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SettingsService } from 'src/app/services/settings.service';
import { ProcessedImageService } from 'src/app/services/images.service';
import { EnvironmentsService } from 'src/app/services/environments.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-reset-system',
  templateUrl: './reset-system.component.html',
  styleUrls: ['./reset-system.component.css']
})
export class ResetSystemComponent implements OnInit {

  public result: string;
  public results: string[];
  showDeleteLoader = false;

  environment: Environment;

  constructor(
    private imagesService: ProcessedImageService,
    public authService: AuthenticationService,
    private envService: EnvironmentsService,
    private titleService: TitleService
    ) { }

  ngOnInit() {
    this.titleService.setTitle('!!! System RESET !!!');
    this.envService.selectedEnv.subscribe((e) => {
      this.environment = e;
    });

  }

public resetAll() {
    this.showDeleteLoader = true;
    this.imagesService.clearDatabase().subscribe((res) => {
      this.results = res;
      this.showDeleteLoader = false;
    }, (err) => {
      this.showDeleteLoader = false;
      this.result = 'Deleting all data failed';
      if (err.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        this.result += ' : Client-side error: ' + err.error.message;
        console.error('A Client-side network error occurred:', err.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        // tslint:disable-next-line:triple-equals
        if (err.status != '0') {
          this.result += ' : Server said: [' + err.status + '] ' + err.status;
        } else {
          this.result += ' Server did respond in time - this can happen during the PoC because we use a database that might shutdown do safe costs. Please try again.';
        }
        console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      }
    }
    );
  }

}
