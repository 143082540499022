<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <h1>{{title}}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>Sharpness is at {{ (request.valuesAreMinimum) ? 'least' : 'most'}}:</label>
                    <div class="input-group">
                      <input class="form-control" type="number" [(ngModel)]="request.sharpness" max="100" step="0.1"
                        style="text-align: right" />
                      <div class="input-group-append">
                        <span class="input-group-text text-white">%</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Brightness is at {{ (request.valuesAreMinimum) ? 'least' : 'most'}}:</label>
                    <div class="input-group">
                      <input class="form-control" type="number" [(ngModel)]="request.brightness" max="100" step="0.1"
                        style="text-align: right" />
                      <div class="input-group-append">
                        <span class="input-group-text text-white">%</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Confidence it is Face is at {{ (request.valuesAreMinimum) ? 'least' : 'most'}}:</label>
                    <div class="input-group">
                      <input class="form-control" type="number" [(ngModel)]="request.faceConfidence" max="100"
                        step="0.01" style="text-align: right" />
                      <div class="input-group-append">
                        <span class="input-group-text text-white">%</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Confidence that a Human is in the image is at {{ (request.valuesAreMinimum) ? 'least' : 'most'}} (0 disables check for Human):</label>
                    <div class="input-group">
                      <input class="form-control" type="number" [(ngModel)]="request.humanConfidence" max="100"
                        step="0.1" style="text-align: right" />
                      <div class="input-group-append">
                        <span class="input-group-text text-white">%</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Relative size of face - compared to largest face on image (0 disables check for relative box size):</label>
                    <div class="input-group">
                      <input class="form-control" type="number" [(ngModel)]="request.relativeBoxSize" max="100"
                        step="1" style="text-align: right" />
                      <div class="input-group-append">
                        <span class="input-group-text text-white">%</span>
                      </div>
                    </div>
                  </div>
                  <button class="btn btn-outline-primary" (click)="doSearch()">Search</button>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Show faces that match any of the specified conditions</label>
                    <div class="input-group">
                      <label class="switch">
                        <input type="checkbox" [(ngModel)]="request.useOrInsteadOfAnd">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Show faces where values are as specified or better</label>
                    <div class="input-group">
                      <label class="switch">
                        <input type="checkbox" [(ngModel)]="request.valuesAreMinimum">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="dropdown" style="display: inline-block;">
                        <button type="button" class="btn btn-outline-primary icon-btn dropdown-toggle"
                        id="selectOrderButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Order by {{orderText}}
                      </button>
                      
                      <div class="dropdown-menu" aria-labelledby="selectOrderButton">
                        <h6 class="dropdown-header">Order persons by</h6>
                        <a class="dropdown-item" (click)="setOrder('creation')">Creation</a>
                        <a class="dropdown-item" (click)="setOrder('personConfidence')">Person Confidence</a>
                        <a class="dropdown-item" (click)="setOrder('person')">Person ID</a>
                        <a class="dropdown-item" (click)="setOrder('faceConfidence')">Condidence it is face</a>
                        <a class="dropdown-item" (click)="setOrder('sharpness')">Sharpness</a>
                        <a class="dropdown-item" (click)="setOrder('brightness')">Brightness</a>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                      <label>Sort with smallest values first</label>
                      <div class="input-group">
                        <label class="switch">
                          <input type="checkbox" [(ngModel)]="request.sortDirectionAsc">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <app-loader *ngIf="showLoader"></app-loader>
              <h3 *ngIf="!showLoader && !faces">Set parameters and click search to view faces that match</h3>
              <div class="row portfolio-grid">
                <div *ngFor="let face of faces" class="col-2 grid-margin stretch-card">
                  <a [routerLink]="'/faces/' + face.id">
                    <figure class="effect-text-in" data-toggle="tooltip" data-placement="bottom" title="Click to edit">
                      <img [src]="'processed-images/faces/' + face.id + '.png'" class="transition-2" />
                      <figcaption>
                        <h4 class="unknown">Face #{{face.id}}</h4>
                        <p>Sharpness: {{face.sharpness | number}}%, Brightness: {{face.brightness | number}}%, Confidence
                          is face: {{face.confidence | number}}%, Image: {{face.image.id}}, Size: {{face.relativeSize | number}}%</p>
                      </figcaption>
                    </figure>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin stretch-card" *ngIf="showMoreButton">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <app-loader *ngIf="showLoader"></app-loader>
                <button class="btn btn-outline-primary" (click)="loadMoreFaces()">Load More Faces</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>