import { Nationality } from '../model/Nationality';


export class ViewPersonsState {

    public showIdentified = false;
    public showAIIdentified = false;
    public order: 'face_count' | 'created' | 'name' = 'face_count';
    public orderText = 'Number of faces';
    public nationalities: Nationality[];

    public selectedNationality;

    public offset = 0;
    public limit = 100;

}
