<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <h1>{{title}}</h1>
          <app-loader *ngIf="showLoader"></app-loader>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <h1>Person to remove</h1>
                <div class="col-12" *ngIf="person1">
                  <h2><i class="flag-icon flag-icon-{{person1.nationality}}" *ngIf="person1.nationality"></i>
                    {{ (person1.name) ? person1.name : 'Not yet named' }} </h2>
                    <div class="col-6" *ngIf="person1">
                      <img [src]="person1ProfilePictureUrl" /><br />
                    </div>
                    <div class="col-6" *ngIf="person1">
                      <h4>Description</h4>
                      <span>{{ person1.description }}</span>
                      <h4>Details</h4>
                      <ul>
                        <li *ngIf="person1">Seen on {{ person1.facesCount }} faces</li>
                        <li>Created {{ person1.created | date:'yyyy-MMM-dd  HH:mm:ss'}}</li>
                        <li>Last updated {{ person1.lastUpdate | date:'yyyy-MMM-dd HH:mm' | uppercase}}</li>
                        <li>Last seen {{ person1.lastImageSeen | date:'yyyy-MMM-dd HH:mm' | uppercase}}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row" style="text-align: center;">
                <button class="btn btn-primary" (click)="switchPersons()"><i class="icon icon-arrow-left"></i>Switch<i class="icon icon-arrow-right"></i></button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <h1>Person to keep</h1>
                <div class="col-12">

                  <app-person-selector #selector></app-person-selector>
                  <div class="col-12" *ngIf="person2">
                    <h2><i class="flag-icon flag-icon-{{person2.nationality}}" *ngIf="person2.nationality"></i>
                      {{ (person2.name) ? person2.name : 'Not yet named' }} </h2>
                    <div class="col-6" *ngIf="person2">
                      <img [src]="person2ProfilePictureUrl" /><br />
                    </div>
                    <div class="col-6" *ngIf="person1">
                      <h4>Description</h4>
                      <span>{{ person2.description }}</span>
                      <h4>Details</h4>
                      <ul>
                        <li>Seen on {{ person2.facesCount }} faces</li>
                        <li>Created {{ person2.created | date:'yyyy-MMM-dd  HH:mm:ss'}}</li>
                        <li>Last updated {{ person2.lastUpdate | date:'yyyy-MMM-dd HH:mm' | uppercase}}</li>
                        <li>Last seen {{ person2.lastImageSeen | date:'yyyy-MMM-dd HH:mm' | uppercase}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <app-loader *ngIf="showMergeLoader"></app-loader>
                  <button class="btn btn-outline-success btn-block" [swal]="{ title: 'Are you sure?',
                      text: 'Persons will be merged and all faces will be assigned to selected person',
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonClass: 'btn btn-outline-success',
                      cancelButtonClass: 'btn btn-outline',
                      confirmButtonText: 'Merge Persons'
                     }" (confirm)="mergePersons()">Merge Persons</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>