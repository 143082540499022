<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12">
          <h1>{{title}}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <app-loader *ngIf="showLoader"></app-loader>

                <div class="col-12" *ngIf="roots && roots.length > 0">
                  <h2>Showing {{roots.length}} face tree roots for person #{{personId}}: {{ (person && person.name) ? person.name : '"Unnamed"' }}</h2>
                  <div *ngIf="person">
                    <h4>Person #{{person.id}} - total of {{person.facesCount}} faces - {{person.verifiedCount }} verified</h4>
                    <img [src]="'processed-images/faces/' + person.primaryFaceId + '.png'" />
                  </div>
                  <button class="btn btn-outline-primary" [routerLink]="'/persons/' + personId">Back to Person #{{personId}}</button>&nbsp;
                  <button class="btn btn-outline-warning" [swal]="{ title: 'Are you sure?',
                  text: 'The system will attempt to match each root on to faces linked to the root with the most verified faces !!! This CANNOT be undone !!!',
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonClass: 'btn btn-outline-warning',
                  cancelButtonClass: 'btn btn-outline',
                  confirmButtonText: 'Attempt to Consolidate'
                 }" (confirm)="consolidate()">Attempt to consolidate face tree</button>
                  <table class="table table-stribed">
                    <thead>
                    </thead>
                    <tbody>
                      <tr *ngFor="let r of roots">
                        <td>
                          <h4>Face #{{r.faceId}} with {{ r.childCount}} child faces - {{r.verifiedChildCount }} verified</h4>
                          <img [src]="'processed-images/faces/' + r.faceId + '.png'" /><br />
                          <a [routerLink]="'/faces/' + r.faceId" class="btn btn-outline-primary btn-sm">Show Face #{{r.faceId}}</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>