import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { BatchJobDefinition } from '../model/batch/batch-job-definitions';
import { SubmittedBatchJob } from '../model/batch/submitted-batch-job';

@Injectable({
  providedIn: 'root'
})
export class BatchJobService {

  public url = 'v1';

  constructor(public http: HttpClient,
              private authenticationService: AuthenticationService) { }

  listAll(): Observable<SubmittedBatchJob[]> {
    return this.http.get<SubmittedBatchJob[]>(this.url + '/batch?v=' + Date.now());
  }

  start(job: BatchJobDefinition): Observable<void> {
    return this.http.put<void>(this.url + '/batch', {
      jobName: job
    });
  }

}
