import * as React from 'react';
import { TextField } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from 'react-select'



class Node extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            route : this.props.route,
            hasClick: false,
            selected: false,
            types : this.props.types
        };
    }

    doubleClick = () =>{
        let hasClick = !this.state.hasClick
        this.setState({hasClick: hasClick});
    }

    click = () =>{
        let selected = !this.state.selected
        this.setState({selected: selected})
        console.log('Selection: ' + selected);
    }

    handleChangeSwitch = (event) => {
        let updated_route = this.state.route
        updated_route[event.target.name] = event.target.checked
        this.setState({route : updated_route})
        this.props.updateNodesOnBoard(this.state.route)
    };

    handleChangeText = (event) => {
        let updated_route = this.state.route
        updated_route[event.target.name] = event.target.value
        this.setState({route : updated_route})
        this.props.updateNodesOnBoard(this.state.route)
    };


    renderRoute() {
        let isRoot = this.props.isRoot(this.state.route)
      return (
        <div className={this.state.route.enabled? "enabled-wrapper" : "not-enabled-wrapper"} key={this.state.route.id} >
        <div onDoubleClick={this.doubleClick} onClick={this.click} className={this.state.hasClick ? "node-clicked" : "node"}>
            {this.state.hasClick?
                <div>
                <div className="center-text-container">
                    <span className="triggerText-clicked">{this.state.route.label}</span>
                </div>


                <span className="triggerText-clicked-elements">Apply to all images </span>

                {!isRoot ?
                    <div>
                    <FormControlLabel style={{position:'absolute', left: '242px', top: '35px'}} color="#90BDE9" label={''}
                        control={<Switch checked={this.state.route.applyAllImages} onChange={this.handleChangeSwitch} name="applyAllImages" color="primary" />}
                    />

                    {!this.state.route.applyAllImages ?
                        <div>
                        <span className="triggerText-clicked-elements">Image element used to trigger route</span>
                        <TextField onChange={this.handleChangeText} style={{position:'absolute', left: '200px', top: '60px'}} className="trigger-input-box" id="standard-basic" label=""  name="triggerElement" value={this.state.route.triggerElement}/>
                        <br/>

                        <span className="triggerText-clicked-elements">Confidence Threshold </span>
                        <TextField onChange={this.handleChangeText} style={{position:'absolute', left: '200px', top: '85px'}} InputProps={{ inputProps: { min: 0, max: 100 } }} className="trigger-input-box" id="standard-basic" label="" type="number" name="confidence" value={this.state.route.confidence}/>
                        <br/>
                        </div>
                    :
                        <div>&nbsp;</div>
                    }

                    </div>
                :
                    <div>
                    <FormControlLabel style={{position:'absolute', left: '242px', top: '35px'}} color="#90BDE9" control={<Switch checked={this.state.selected} />} label={''} />

                    </div>
                }

                <span className="triggerText-clicked-elements">Priority </span>
                <TextField onChange={this.handleChangeText} style={{left: '145px'}} InputProps={{ inputProps: { min: 0} }} className="trigger-input-box" id="standard-basic" label="" type="number" name="priority" value={this.state.route.priority}/>
                <br/>

                <span className="triggerText-clicked-elements">Enabled </span>
                    <FormControlLabel style={{position:'absolute', left: '242px',  paddingTop:'5px'}} color="#90BDE9" label={''}
                        control={<Switch checked={this.state.route.enabled} onChange={this.handleChangeSwitch} name="enabled" color="primary"/>}
                />

                </div>
            :
                <div className="center-text-container">
                  <span className="triggerText">{this.state.route.label}</span>
                </div>
            }

        </div>
        </div>
    );
    }

    handleSelectChange = (event) =>{
        let updated_route = this.state.route
        updated_route.appliesTo = event.value
        this.setState({route : updated_route})
    }

    renderStartRoute(){
        return(
            <div className="enabled-wrapper">
            <div onDoubleClick={this.click} className={this.state.hasClick ? "node-clicked" : "node"}>
            {this.state.hasClick?
                <div>
                    <div className="center-text-container">
                        <span className="triggerText-clicked">{this.state.route.label}</span>
                    </div>
                    <br/>
                    <span style={{position:'absolute', left: '16px', top: '60px'}} className="triggerText-clicked-elements-startingpoint">Applies to</span>

                    <div>
                    <Select onChange={this.handleSelectChange} options={this.state.types} defaultValue={this.state.types[0]} className="type-list"/>
                    </div>

                </div>
            :
                <div className="center-text-container">
                    <span className="triggerText-clicked">{this.state.route.label}</span>
                </div>
                }
            </div>
            </div>
        )
    }

    render(){
        return(
            <div className={this.selected ? 'selected' : ''}>
                {this.state.route.isStartingPoint? this.renderStartRoute() : this.renderRoute()}
            </div>
        )
    }
}
export default Node;
