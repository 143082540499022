import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import * as CodeMirror from 'codemirror';
import 'codemirror/mode/sql/sql.js';
import 'codemirror/addon/hint/sql-hint';
import 'codemirror/addon/hint/show-hint';
import { WindowRef } from 'src/app/helpers/WindowRef';
import { Table } from 'src/app/model/Table';

@Component({
  selector: 'app-sqleditor',
  templateUrl: './sql-editor.component.html',
  styleUrls: ['./sql-editor.component.css']
})
export class SqlEditorComponent implements AfterViewInit {

  internalQuery: string;

  @Input() initialQuery: string;
  @Input() tables: Table[];

  @Output() query: EventEmitter<string>;

  @ViewChild('myEditor') myEditor;
  editor: CodeMirror.EditorFromTextArea;

  constructor(private winRef: WindowRef, private cd: ChangeDetectorRef) {
    this.query = new EventEmitter(true);
  }

  public getValue(): string {
    return this.editor.getValue();
  }

  public setValue(q: string): void {
    this.editor.setValue(q);
  }

  public updateListeners() {
    console.log('Emitting event :' + this.internalQuery);
    this.query.emit(this.internalQuery);
  }

  ngAfterViewInit() {
    this.internalQuery = this.initialQuery;
    if (!this.initialQuery || this.internalQuery == null) {
      this.internalQuery = '';
    }
    console.log('Query: ' + this.internalQuery);
    const mime = 'text/x-pgsql';
    const currentWindow = this.winRef.nativeWindow;
    const options = {
      mode: mime,
      indentWithTabs: true,
      smartIndent: true,
      lineNumbers: true,
      autofocus: true,
      value: this.internalQuery,
      extraKeys: { 'Ctrl-Space': 'autocomplete' },
      // hint: CodeMirror['hint'].sql,
      hintOptions: {
          tables: this.getTableOptions()
          }

    };
    this.editor = CodeMirror.fromTextArea(this.myEditor.nativeElement);
    currentWindow.editor = this.editor;
    this.editor.setValue(this.internalQuery);
    this.cd.detectChanges();
  }

  public setTables(t: Table[]) {
    this.tables = t;
    if (this.editor) {
      this.editor.setOption('hintOptions', {
        tables: this.getTableOptions()
      } as any);
    }
  }

  getTableOptions() {
    const res = {};

    if (this.tables) {
      for (const t of this.tables) {
        res[t.name] = t.columns;
      }
    }

    return res;
  }

}
