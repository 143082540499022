<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <h1>List of users</h1>
      <div class="row">
        <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <app-loader *ngIf="!users"></app-loader>
                  <table class="table table-striped" *ngIf="users">
                      <thead>
                        <tr>
                          <th>Username</th>
                          <th>Fullname</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Created</th>
                          <th>Enabled</th>
                          <th>Status</th>
                          <th>Groups</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let u of users">
                          <td>{{u.username }}</td>
                          <td>{{u.fullname }}</td>
                          <td>{{u.email }}</td>
                          <td>{{u.phone }}</td>
                          <td>{{u.created | date:'yyyy-MMM-dd HH:mm' | uppercase }}</td>
                          <td>{{u.enabled }}</td>
                          <td>{{u.status }}</td>
                          <td>
                              <div *ngFor="let g of u.groups" class="badge badge-primary">{{g}}</div>
                          </td>
                        </tr>
                      </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>