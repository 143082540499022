<div class="container-fluid page-body-wrapper full-page-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12">
          <h1>Latest images</h1>
        </div>
      </div>
      <div class="row">
          <div class="col-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="dropdown" style="display: inline-block;">
                        <button type="button" class="btn btn-outline-primary icon-btn dropdown-toggle"
                        id="selectReportButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{reportText}}
                      </button>

                      <div class="dropdown-menu" aria-labelledby="selectReportButton">
                        <h6 class="dropdown-header">Select images:</h6>
                        <a class="dropdown-item" *ngFor="let r of reports" (click)="setReport(r)">{{ r.text }}</a>
                      </div>
                    </div>

                    <div class="dropdown" style="display: inline-block;">
                        <button type="button" class="btn btn-outline-primary icon-btn dropdown-toggle"
                        id="selectOrderButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Order by {{orderText}}
                      </button>

                      <div class="dropdown-menu" aria-labelledby="selectOrderButton">
                        <h6 class="dropdown-header">Order images by</h6>
                        <a class="dropdown-item" *ngFor="let o of sortOptions" (click)="setOrder(o)">{{ o.name }}</a>
                      </div>
                    </div>
                    <div class="dropdown" style="display: inline-block;">
                        <button type="button" class="btn btn-outline-primary icon-btn dropdown-toggle"
                        id="selectNationalityButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Filter by company <i *ngIf="selectedRegion && selectedRegion != 'All'" class="flag-icon flag-icon-{{selectedRegion}}"></i>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="selectNationalityButton">
                        <h6 class="dropdown-header">Show</h6>
                        <a class="dropdown-item" (click)="setRegion('All')" *ngIf="authenticationService.hasAdminAccess()">All</a>
                        <a class="dropdown-item" (click)="setRegion('NotSet')">Unknown</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" *ngFor="let n of regions" (click)="setRegion(n.code)"><i
                          class="flag-icon flag-icon-{{n.code}}"></i> {{n.name}}</a>
                        </div>
                      </div>
                      <button class="btn btn-outline-primary" (click)="getImages()">Refresh</button>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                    <app-loader *ngIf="showLoader"></app-loader>
                    <app-face-menu #faceMenu [listener]="this"></app-face-menu>
                  <div class="row" *ngIf="images && images.length == 0">
                    <h4>No images found</h4>
                  </div>
                  <div class="row portfolio-grid">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12" *ngFor="let image of images">
                      <a [routerLink]="'/images/' + image.id">
                      <figure class="effect-text-in" data-toggle="tooltip"
                        data-placement="bottom" title="Click to edit">

                        <div class="outer">

                            <img [src]="image.location.key" class="transition-2 inner" [id]="'img_' + image.id" />
                            <canvas class="transition-2 inner" [id]="'canvas_' + image.id" [hidden]="true" ></canvas>




                        </div>

                        <figcaption>
                          <h4 class="unknown"><i class="flag-icon flag-icon-{{image.region}}" *ngIf="image.region"></i> {{image.allerImageId}}</h4>
                          <p>Last processed on {{ image.lastProcessed | date:'yyyy-MMM-dd HH:mm' | uppercase }} ({{ image.lastProcessed | amTimeAgo }})<br />
                           First seen on {{ image.created | date:'yyyy-MMM-dd HH:mm' | uppercase }}  ({{ image.created | amTimeAgo }})<br />
                           Status: {{ image.status }}</p>
                        </figcaption>
                      </figure>
                    </a>
                      <div *ngIf="image.faces">
                        <h4 *ngIf="image.faces && image.faces.length > 0">Faces from Collection</h4>
                        <app-loader *ngIf="image.showLoader"></app-loader>
                        <ul>
                          <li *ngFor="let face of activeFaces(image.faces)" (contextmenu)="showFaceContextMenu($event, face, image)"
                              data-toggle="tooltip" data-placement="bottom" title="Right Click for Options" data-custom-class="tooltip-primary">
                            <span (mouseover)="drawBoundingBox(image, face.imageLocation)"
                              (mouseleave)="removeBoundingBox(image)">
                              <a style="cursor: pointer;" *ngIf="face.person.id" [routerLink]="'/persons/' + face.person.id">
                                {{ (face.person.name) ? face.person.name : 'Not yet named' }} ({{ face.personConfidence | number }}% confidence)
                              Person {{ face.person.id }}</a>
                              <a style="cursor: pointer;" [routerLink]="'/faces/' + face.id" *ngIf="!face.person.id">
                                Face #{{ face.id }} - pending person matching
                              </a>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                  <app-loader *ngIf="showLoader"></app-loader>
                <button class="btn btn-outline-primary" (click)="loadMoreImages()">Load More Images</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>