<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <app-face-menu #faceMenu [listener]="this" [showAssignItem]="false" [showSplitItem]="false"
        [showDeleteItem]="false" [showReprocessItem]="false"></app-face-menu>
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <h1>{{title}}</h1>
          <app-loader *ngIf="showLoader"></app-loader>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-title">
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-8">
                  <h2>Face Landmarks</h2>
                  <div *ngIf="face" (contextmenu)="showFullFaceContextMenu($event, face)">
                    <img [src]="face.image.location.key" [id]="'fullimg'" class="full-image"
                      (load)="drawBoundingBox(face.imageLocation)" />
                    <canvas class="transition-2" [id]="'imgcanvas'" [hidden]="true" class="full-image"
                      (click)="showImage(face.image.id)"></canvas>
                    <table *ngIf="face" class="table">
                      <thead>
                        <tr>
                          <th colspan="2">Face Details</th>
                          <th colspan="4" *ngIf="face.person && face.person.id">
                            <a [routerLink]="'/persons/'+ face.person.id">ID: {{ face.person.id }} {{ (face.person.name) ? face.person.name : 'Not named' }}</a></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="face.personVerified">
                          <td colspan="6">Face was verified by {{face.personVerifiedBy}}
                           on {{face.personVerifiedDate | date:'yyyy-MMM-dd HH:mm:ss'}}
                           AI confidence is: {{ face.personConfidence | number }}%
                          </td>
                        </tr>
                        <tr *ngIf="!face.personVerified">
                          <td colspan="6">Face is not verified by. AI confidence is: {{ face.personConfidence | number }}%
                          </td>
                        </tr>
                        <tr>
                          <td>Confidence: {{ face.confidence | number }}</td>
                          <td>Brightness: {{ face.brightness | number }}</td>
                          <td>Sharpness: {{ face.sharpness | number }}</td>
                          <td>Pitch: {{ face.facePose.pitch | number }}</td>
                          <td>Roll: {{ face.facePose.roll | number }}</td>
                          <td>Yaw: {{ face.facePose.yaw | number }}</td>
                        </tr>
                        <tr>
                          <td><a class="btn btn-sm btn-outline-primary" [routerLink]="'/images/' + face.image.id">View Full Image</a></td>
                          <td><a *ngIf="face.person && face.person.id" class="btn btn-sm btn-outline-success" [routerLink]="'/persons/' + face.person.id">View Person</a></td>
                          <td><a *ngIf="face.person && face.person.id" class="btn btn-sm btn-outline-success" [routerLink]="'/faces/' + face.id + '/facetree'">View Face Tree</a></td>
                          <td>
                            <button class="btn btn-outline-primary" (click)="reprocessFace(face)">Reprocess</button>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-4">
                  <table *ngIf="face" class="table">
                    <thead>
                      <tr>
                        <th colspan="2">Landmarks Detected
                          <button class="btn btn-sm" [ngClass]="(outlineToggle) ? 'btn-primary' : 'btn-outline-primary'"
                            (click)="outlineFace()">Outline Face</button></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let m of face.landmarks | pairs">
                        <td (mouseover)="drawLandmark(m[0].x, m[0].y)" (mouseout)="removeLandmarks()">{{ m[0].kind }}
                        </td>
                        <td (mouseover)="drawLandmark(m[1].x, m[1].y)" (mouseout)="removeLandmarks()">{{ m[1].kind }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div *ngIf="face">
                    <h2>Person Matching</h2>
                    <p *ngIf="!matchedToPerson">
                      Face was not matched to any person
                    </p>
                    <div *ngIf="matchedToPerson">
                      <p>
                        Face was matched to <a (click)="showPerson(face.person)" style="cursor: pointer;">Person
                          #{{face.person.id}} {{(face.person.name) ? face.person.name : 'Not yet named'}}</a>
                      </p>
                      <p *ngIf="rootFaceForPerson">
                        This was first face seen for person - face did not match any known persons so Person
                        #{{face.person.id}} was created at {{rootFaceForPerson.created | date:'yyyy-MMM-dd HH:mm' | uppercase}}
                      </p>
                      <h4 *ngIf="matches && matches.length > 0">Face matches found by AI</h4>

                      <table *ngIf="matches && matches.length > 0" class="table table-stribed">
                        <thead>
                          <tr>
                            <th>Face</th>
                            <th>Used to match</th>
                            <th>Similarity</th>
                            <th>Time</th>
                            <th>Face ID</th>
                            <th>AWS Face ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let rel of matches" (contextmenu)="showFaceContextMenu($event, rel)" [ngClass]="(rel.relationUsedToMatch) ? 'highlighted' : ''">
                            <td>
                                <a [routerLink]="'/faces/' + rel.relatedFaceId">
                                  <img [src]="'processed-images/faces/' + rel.relatedFaceId + '.png'" class="image"  />
                                </a>
                              </td>
                              <td>{{ rel.relationUsedToMatch }}</td>
                              <td>{{ rel.confidence | number }} %</td>
                              <td>{{ rel.created | date:'yyyy-MMM-dd HH:mm' | uppercase}}</td>
                              <td>
                                <a [routerLink]="'/faces/' + rel.relatedFaceId">
                                  {{ rel.relatedFaceId }}
                                </a>
                              </td>
                              <td>
                                <a [routerLink]="'/faces/' + rel.relatedFaceId">
                                  {{ rel.relatedAwsFaceId }}
                                </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12" *ngIf="face">
                  <div>
                    <h2>Faces Details</h2>
                    <table class="table table-striped">
                      <tr>
                        <th>Detail</th>
                        <th>Value</th>
                        <th>AI Confidence</th>
                      </tr>
                      <tr *ngFor="let d of face.details">
                        <td>
                          {{ d.kind }}
                        </td>
                        <td>
                          {{ d.value }}
                        </td>
                        <td>{{d.confidence | number }}%</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div *ngIf="face">
                    <h2>Face Collection Search</h2>
                    <button class="btn btn-outline-primary" (click)="searchFaceMatches()">Search AWS Face Collection for 100 best matches</button>
                    <app-loader *ngIf="showFaceMatchLoader"></app-loader>
                    <div *ngIf="matchSearchResults">
                      <h4>Face matches found by AI</h4>
                      <table class="table table-stribed">
                        <thead>
                          <tr>
                            <th>Face</th>
                            <th>Similarity</th>
                            <th>Face ID</th>
                            <th>Person</th>
                            <th>AWS Face ID</th>
                            <th>Created</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let rel of matchSearchResults" (contextmenu)="showFaceContextMenu($event, rel)">
                            <td>
                              <img [src]="'processed-images/faces/' + rel.relatedFaceId + '.png'" />
                            </td>
                            <td>{{ rel.confidence | number }} %</td>
                            <td>{{ rel.relatedFaceId }}</td>
                            <td>{{ (rel.person) ? 'Person #' + rel.person.id + ': ' + ((rel.person.name) ? rel.person.name : ' (Not yet named)') : 'Face is not linked to any person' }}</td>
                            <td>{{ rel.relatedAwsFaceId }}</td>
                            <td>{{ rel.created | date:'yyyy-MMM-dd HH:mm:ss' }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>