<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <h1>{{title}}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                      <div class="form-group row">
                          <label class="col-sm-3 col-form-label" for="namequery">Part of name to search for</label>
                          <input id="namequery" [(ngModel)]="searchString" name="namequery" class ="form-control" type="text" placeholder="" />
                      </div>
                    <h3 style="color: #FFFFFF;">{{searchString}}</h3>
                    <h3 style="color: #FF0000;">{{errorText}}</h3>
                    <button class="btn btn-outline-primary" (click)="doSearch()">Search</button>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <app-loader *ngIf="showLoader"></app-loader>
              <h3 *ngIf="!showLoader && !persons">Type full or partial name to search named persons</h3>
              <div class="row persons-grid">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12" *ngFor="let person of persons">
                    <a [routerLink]="'/persons/' + person.id">
                      <figure class="effect-text-in" style="height: 245px;" >
                        <img [src]="'processed-images/faces/' + person.primaryFaceId + '.png'" />
                        <figcaption>
                          <h4 style="cursor: pointer;" [ngClass]="(person.name) ? 'known' : 'unknown'">
                            <i class="flag-icon flag-icon-{{person.nationality}}" *ngIf="person.nationality"></i>
                            {{ (person.name) ? person.name : 'Unknown' }}</h4>
                          <p>Person id: {{ person.id }}<br /> So far identified on {{ person.facesCount }}
                            image{{ (person.facesCount > 1 ? 's' : '')}}.<br />Last seen on
                            {{ person.lastImageSeen | date:'yyyy-MMM-dd HH:mm' | uppercase  }}
                            {{ person.description }}</p>
                        </figcaption>
                      </figure>
                    </a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin stretch-card" *ngIf="showMoreButton">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <app-loader *ngIf="showLoader"></app-loader>
                <button class="btn btn-outline-primary" (click)="loadMoreFaces()">Load More Faces</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>