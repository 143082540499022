


  <h4 *ngIf="selected.id" class="card-title">Edit bug #{{selected.id}}</h4>
  <h4 *ngIf="!selected.id" class="card-title">Add new Bug Report</h4>
  <p class="card-description">Enter details and click submit - KeyCore is automatically notified</p>
  <form class="form-sample">
    <div class="col-12">
      <div class="form-group row">
        <label class="col-3 col-form-label" for="subject">Title</label>
        <div class="col-9">
          <input name="subject" class="form-control" id="subject" type="text" [(ngModel)]="selected.subject"
            [readOnly]="!authService.hasAdminAccess()" placeholder="" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group row">
        <label class="col-3 col-form-label" for="description">Description</label>
        <div class="col-9">
          <textarea id="description" class="form-control" name="description" [(ngModel)]="selected.description"
            rows="15" cols="50"></textarea>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group row">
        <label class="col-3 col-form-label" for="type">Type</label>
        <div class="col-9">
          <select name="type" class="form-control" id="type" type="text" [(ngModel)]="selected.type">
            <option>Bug</option>
            <option>Feature-Request</option>
            <option>Question</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group row">
        <label class="col-3 col-form-label" for="priority">Priority</label>
        <div class="col-9">
          <select name="priority" class="form-control" id="priority" type="text" [(ngModel)]="selected.priority">
            <option>Normal</option>
            <option>High</option>
            <option>Low</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group row">
        <label class="col-3 col-form-label" for="status">Status</label>
        <div class="col-9">
          <select name="status" class="form-control" id="status" type="text" [(ngModel)]="selected.status"
            *ngIf="authService.hasHandleBugsAccess()">
            <option>Reported</option>
            <option>In-progress</option>
            <option>Closed-Pending-Deploy</option>
            <option>Fixed</option>
            <option>Wont-Fix</option>
          </select>
          <span *ngIf="!authService.hasHandleBugsAccess()">{{ selected.status }}</span>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group row">
        <label class="col-3 col-form-label" for="faceId">Face</label>
        <div class="col-9">
          <input name="faceId" class="form-control" id="faceId" type="text" [(ngModel)]="selected.faceId"
            [readOnly]="!authService.hasAdminAccess()" placeholder="" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group row">
        <label class="col-3 col-form-label" for="imageId">Image</label>
        <div class="col-9">
          <input name="imageId" class="form-control" id="imageId" type="text" [(ngModel)]="selected.imageId"
            [readOnly]="!authService.hasAdminAccess()" placeholder="" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group row">
        <label class="col-3 col-form-label" for="personId">Person</label>
        <div class="col-9">
          <input name="personId" class="form-control" id="personId" type="text" [(ngModel)]="selected.personId"
            [readOnly]="!authService.hasAdminAccess()" placeholder="" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group row">
        <label class="col-3 col-form-label" for="description">Response</label>
        <div class="col-9" *ngIf="authService.hasHandleBugsAccess()">
          <textarea id="response" class="form-control" name="response" [(ngModel)]="selected.response"
          rows="15" cols="50"></textarea>
        </div>
        <div class="col-9" *ngIf="!authService.hasHandleBugsAccess()">
          <p>{{ selected.response }}</p>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group row">
        <label class="col-3 col-form-label" for="faceId">Log Group</label>
        <div class="col-9">
          <input name="logGroup" class="form-control" id="logGroup" type="text" [(ngModel)]="selected.logGroup"
            [readOnly]="!authService.hasAdminAccess()" placeholder="" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group row">
        <label class="col-3 col-form-label" for="imageId">Log Stream</label>
        <div class="col-9">
          <input name="logStream" class="form-control" id="logStream" type="text" [(ngModel)]="selected.logStream"
            [readOnly]="!authService.hasAdminAccess()" placeholder="" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group row">
        <label class="col-3 col-form-label" for="personId">Request Id</label>
        <div class="col-9">
          <input name="requestId" class="form-control" id="requestId" type="text" [(ngModel)]="selected.requestId"
            [readOnly]="!authService.hasAdminAccess()" placeholder="" />
        </div>
      </div>
    </div>
  </form>
  <button class="btn btn-outline-success mb-2" (click)="save()" type="submit" *ngIf="selected.id"
    [disabled]="!authService.hasHandleBugsAccess()" style="margin: 0px;">Save</button>
  <button class="btn btn-outline-success mb-2" (click)="save()" type="submit" *ngIf="!selected.id"
    [disabled]="!authService.hasHandleBugsAccess()" style="margin: 0px;">Report</button>
  &nbsp;
  <button class="btn btn-outline-primary mb-2" (click)="clear()" type="submit"
    style="margin: 0px;">Clear Form</button>
<div class="form-group">
  <app-loader *ngIf="showLoader"></app-loader>
</div>