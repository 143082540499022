<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
      <div class="content-wrapper">
        <h1>Do a complete System Reset</h1>
        <div class="row">
          <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasKeyCoreAccess()">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-6" *ngIf="true">
                    <div>
                      <h3>Perform a Complete System Reset</h3>
                      <p>
                        <button class="btn btn-outline-danger" [swal]="{ title: 'Are you sure?', 
                        text: 'All images and face data will be deleted !!! This CANNOT be undone !!!', 
                        type: 'warning', 
                        showCancelButton: true,
                        confirmButtonClass: 'btn btn-outline-danger',
                        cancelButtonClass: 'btn btn-outline', 
                        confirmButtonText: 'Delete Everything'
                      }" (confirm)="resetAll()">Delete all data in the database - and reset
                          collection</button>
                      </p>
                      <app-loader *ngIf="showDeleteLoader"></app-loader>
                      <p *ngIf="result">{{result}}</p>
                      <ul *ngIf="results">
                        <li class="completed" *ngFor="let e of results">{{e}}</li>
                      </ul>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>