import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { BugReport } from 'src/app/model/BugReport';
import { TitleService } from 'src/app/services/title.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { BugReportService } from 'src/app/services/bug-report.service';
import * as moment from 'moment-timezone';
import { ListAllComponent } from '../list-all/list-all.component';
import { BehaviorSubject } from 'rxjs';
import { MomentModule } from 'ngx-moment';

@Component({
  selector: 'app-edit-bug',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {

  selected: BugReport;
  showLoader: boolean;

  public saving: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  constructor(
    public authService: AuthenticationService,
    private notificationService: NotificationsService,
    private bugService: BugReportService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    if (!this.selected) {
      this.clear();
    }
  }

  save() {
    this.showLoader = true;
    if (!this.selected.id) {
      this.selected.reporter = this.authService.currentUserValue.fullname;
    }
    this.selected.lastUpdate = moment.tz();
    this.selected.lastUpdateBy = this.authService.currentUserValue.fullname;

    console.log(JSON.stringify(this.selected, null, 4));

    this.bugService.save(this.selected).subscribe(() => {
      this.clear();
      this.saving.next(undefined);
      this.showLoader = false;
    });
  }

  clear() {
    this.selected = new BugReport();
    this.selected.type = 'Bug';
    this.selected.priority = 'Normal';
    this.selected.status = 'Reported';

  }

}
