import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImageMatchSettings } from '../model/ImageMatchSettings';
import { Observable } from 'rxjs';
import { ElvisSettings } from '../model/ElvisSettings';
import { ConcurrencySettings } from '../model/ConcurrencySettings';
import { ElvisLoginResponse } from '../model/ElvisLoginResponse';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public url = 'v1';

  constructor(
    private http: HttpClient
  ) { }

  getImageMatcherSettings(): Observable<ImageMatchSettings> {
    return this.http.get<ImageMatchSettings>(this.url + '/settings/person-matching?' + new Date().getTime());
  }

  updateImageMatcherSettings(values: ImageMatchSettings): Observable<ImageMatchSettings> {
    return this.http.post<ImageMatchSettings>(this.url + '/settings/person-matching', values);
  }

  updateDirectPersonMatchSetting(value: boolean): Observable<string> {
    return this.http.post<string>(this.url + '/settings/person-matching/direct', {
      enabled: value
    });
  }

  updateMatchIgnoredFacesSetting(value: boolean): Observable<string> {
    return this.http.post<string>(this.url + '/settings/person-matching/ignored', {
      enabled: value
    });
  }

  updateUseReadReplicasDuringPersonMatchingSetting(value: boolean): Observable<string> {
    return this.http.post<string>(this.url + '/settings/person-matching/use-read-replicas', {
      enabled: value
    });
  }

  getElvisSettings(): Observable<ElvisSettings> {
    return this.http.get<ElvisSettings>(this.url + '/settings/elvis?' + new Date().getTime());
  }

  updateElvisSettings(values: ElvisSettings): Observable<ElvisSettings> {
    return this.http.post<ElvisSettings>(this.url + '/settings/elvis', values);
  }

  testElvisSettings(values: ElvisSettings): Observable<ElvisLoginResponse> {
    return this.http.post<ElvisLoginResponse>(this.url + '/settings/elvis/test', values);
  }

  getConcurrencySettings(): Observable<ConcurrencySettings> {
    return this.http.get<ConcurrencySettings>(this.url + '/settings/concurrency?' + new Date().getTime());
  }

  updateConcurrencySettings(values: ConcurrencySettings): Observable<ConcurrencySettings> {
    return this.http.post<ConcurrencySettings>(this.url + '/settings/concurrency', values);
  }


  getQueueStatus(key: string): Observable<string> {
    return this.http.get<string>(this.url + '/settings/queues/' + key + '?v=' + new Date().getTime());
  }

  updateQueueStatus(key: string, enabled: boolean): Observable<string> {
    return this.http.post<string>(this.url + '/settings/queues/' + key, { enabled: enabled });
  }

  removeAIVerificationsAndReprocess(): Observable<string> {
    return this.http.get<string>(this.url + '/settings/rebuild-all-persons?v=' + new Date().getTime());
  }

  redoVerifications(): Observable<string> {
    return this.http.post<string>(this.url + '/persons/enqueue-facetree-verifications', {
      reprocess: false
    });
  }

  queueUnamedForAINaming(): Observable<string> {
    return this.http.post<string>(this.url + '/persons/enqueue-unnamed-persons-for-naming', {});
  }

  clearAllAINaming(): Observable<string> {
    return this.http.post<string>(this.url + '/persons/clear-all-ai-assigned-names', {});
  }

  queueUnamedForExternalNaming(): Observable<string> {
    return this.http.post<string>(this.url + '/persons/enqueue-unnamed-persons-for-external-naming', {});
  }

  clearAllExternalNaming(): Observable<string> {
    return this.http.post<string>(this.url + '/persons/clear-all-external-assigned-names', {});
  }

}
