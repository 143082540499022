import { Component, OnInit } from '@angular/core';
import { SearchFacesRequest } from 'src/app/model/SearchFacesRequest';
import { ProcessedImageService } from 'src/app/services/images.service';
import { IdentifiedFace } from 'src/app/model/IdentifiedFace';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-search-bad-faces',
  templateUrl: './search-bad-faces.component.html',
  styleUrls: ['./search-bad-faces.component.css']
})
export class SearchBadFacesComponent implements OnInit {

  title = 'Search for faces using parameters to filter during detection';
  showLoader = false;
  faces: IdentifiedFace[];
  showMoreButton = false;
  totalCount: number;

  orderText: string;

  request: SearchFacesRequest;

  constructor(
    private imageService: ProcessedImageService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.request = new SearchFacesRequest();
    this.titleService.setTitle('Search Bad Faces');
    this.request.brightness = 100;
    this.request.sharpness = 50;
    this.request.faceConfidence = 100;
    this.request.relativeBoxSize = 0;
    this.request.humanConfidence = 0;
    this.setOrder('creation');
    this.request.sortDirectionAsc = false;
    this.request.offset = 0;
    this.request.limit = 100;
    this.request.useOrInsteadOfAnd = false;
    this.request.valuesAreMinimum = false;
  }

  doSearch() {
    this.showLoader = true;
    this.request.offset = 0;
    this.imageService.searchFaces(this.request).subscribe((f) => {
      this.faces = f.faces;
      this.totalCount = f.totalCount;
      this.showLoader = false;
      this.showMoreButton = (f.faces.length < f.totalCount);
      this.title = 'Showing ' + f.faces.length + ' of ' + f.totalCount +  ' faces that match parameters';
    });
  }

  loadMoreFaces() {
    this.request.offset += this.request.limit;
    this.imageService.searchFaces(this.request).subscribe((f) => {
      this.faces = this.faces.concat(f.faces);
      this.showLoader = false;
      this.showMoreButton = (this.faces.length < this.totalCount);
      this.title = 'Showing ' + this.faces.length + ' of ' + this.totalCount +  ' faces that match parameters';
    });
  }

  setOrder(order: 'personConfidence' | 'person' | 'creation' | 'faceConfidence' | 'sharpness' | 'brightness') {
    this.request.orderBy = order;

    switch (order) {
      case 'personConfidence': {
        this.orderText = 'Person Confidence';
        break;
      }
      case 'person': {
        this.orderText = 'Person Id';
        break;
      }
      case 'creation': {
        this.orderText = 'Creation Time';
        break;
      }
      case 'faceConfidence': {
        this.orderText = 'Confidence it is a face';
        break;
      }
      case 'sharpness': {
        this.orderText = 'Sharpness';
        break;
      }
      case 'brightness': {
        this.orderText = 'Brightness';
        break;
      }
    }
  }

}
