import { Component, OnInit } from '@angular/core';
import { Person } from 'src/app/model/Person';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessedImageService } from 'src/app/services/images.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TitleService } from 'src/app/services/title.service';
import { FaceRelation } from 'src/app/model/FaceRelation';

@Component({
  selector: 'app-compare-persons',
  templateUrl: './compare-persons.component.html',
  styleUrls: ['./compare-persons.component.css']
})
export class ComparePersonsComponent implements OnInit {

  title = '';
  showLoader = true;
  personId: number;
  comparePersonId: number;
  relations: FaceRelation[];

  person: Person;
  comparePerson: Person;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private imageService: ProcessedImageService,
    private notifications: NotificationsService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.getData();
    this.route.params.subscribe((p) => this.getData());
  }

  getData() {
    this.personId = Number(this.route.snapshot.paramMap.get('personId'));
    this.comparePersonId = Number(this.route.snapshot.paramMap.get('comparePersonId'));
    this.showLoader = true;
    this.title = 'Looking for relations between #' + this.personId + ' and #' + this.comparePersonId;
    this.titleService.setTitle('Comparing Persons');

    this.imageService.getPerson(this.personId).subscribe(p => this.person = p);
    this.imageService.getPerson(this.comparePersonId).subscribe(p => this.comparePerson = p);

    this.imageService.searchRelatedFaces(this.personId, this.comparePersonId).subscribe(rel => {
      this.relations = rel;
      this.title = 'Found ' + rel.length + ' matches between persons';
      this.showLoader = false;
    });
  }

}
