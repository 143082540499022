<div class="container-fluid page-body-wrapper full-page-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <h1>List of failed images</h1>
      <div class="row">
        <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <app-loader *ngIf="showLoader"></app-loader>
              <table class="table table-stribed">
                <thead>
                  <tr>
                    <th>Seen</th>
                    <th>Filename</th>
                    <th>Bucket</th>
                    <th>Prefix</th>
                    <th>Version</th>
                    <th>Found in S3</th>
                    <th>Hash</th>
                    <th>Type</th>
                    <th>Size</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let i of images">
                    <td>{{ i.created | date:'yyyy-MMM-dd HH:mm' | uppercase }} ({{ i.created | amTimeAgo }})</td>
                    <td>{{ i.origname }}</td>
                    <td>{{ i.originalLocation.bucket }}</td>
                    <td>{{ i.originalLocation.key }}</td>
                    <td>{{ i.originalLocation.version }}</td>
                    <td>{{ i.foundInS3 }}</td>
                    <td>{{ i.hash }}</td>
                    <td>{{ i.type }}</td>
                    <td>{{ i.origSize }}</td>
                    <td>{{ i.status }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                    <app-loader *ngIf="showLoader"></app-loader>
                    <button *ngIf="more" class="btn btn-outline-primary" (click)="loadMoreImages()">Load More Images</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>