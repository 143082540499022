
<context-menu #contextMenu>
    <ng-template contextMenuItem let-item>&nbsp;<strong>Face #{{item.id}}</strong>&nbsp;</ng-template>

    <ng-template *ngIf="showAssignItem" contextMenuItem let-item (execute)="assign($event.item)">&nbsp;<i class="icon-user-follow text-primary icon-sm"></i> Assign to known Person&nbsp;</ng-template>
    <ng-template *ngIf="showSplitItem" contextMenuItem let-item (execute)="split($event.item)">&nbsp;<i class="icon-people text-primary icon-sm"></i> Split to new Person&nbsp;</ng-template>
    <ng-template *ngIf="showSplitBranchItem" contextMenuItem let-item (execute)="splitBranch($event.item)">&nbsp;<i class="icon-people text-primary icon-sm"></i> Split Branch to new Person&nbsp;</ng-template>
    <ng-template *ngIf="showReprocessItem" contextMenuItem let-item (execute)="reprocess($event.item)">&nbsp;<i class="icon-refresh text-primary icon-sm"></i> Reprocess Face&nbsp;</ng-template>
    <ng-template *ngIf="showDetailsItem" contextMenuItem let-item (execute)="showDetails($event.item)">&nbsp;<i class="icon-eyeglass text-primary icon-sm"></i> View All Face Details&nbsp;</ng-template>
    <ng-template *ngIf="showDeleteItem" contextMenuItem divider="true"></ng-template>
    <ng-template *ngIf="showDeleteItem" contextMenuItem let-item (execute)="delete($event.item)">&nbsp;<i class="icon-close text-primary icon-sm"></i> Delete Face&nbsp;</ng-template>
</context-menu>

<swal #assignSwal title="Select named person" type="info" [showCancelButton]="true"
    confirmButtonClass="btn btn-outline-success" cancelButtonClass="btn btn-outline"
    confirmButtonText="Assign Face"
    >
    <div *swalPartial>
        <div class="card" style="background-color: white;">
            <div class="card-body" style="height: 400px;">
                <app-person-selector [onSelection]="setSelectedPerson" [backReference]="this" [white]="true"></app-person-selector>
                <div *ngIf="selectedPerson">
                <h3>{{ selectedPerson.name }}</h3>
                <img [src]="'processed-images/faces/' + selectedPerson.primaryFaceId + '.png'" />
                </div>
            </div>
        </div>
    </div>
</swal>

<swal #splitSwal title="Create new person" type="info" [showCancelButton]="true"
    confirmButtonClass="btn btn-outline-success" cancelButtonClass="btn btn-outline"
    confirmButtonText="Create New Person" >
    <div *swalPartial>
        Face will be assigned to new person
    </div>
</swal>

<swal #splitBranchSwal title="Create new person" type="info" [showCancelButton]="true"
    confirmButtonClass="btn btn-outline-success" cancelButtonClass="btn btn-outline"
    confirmButtonText="Create New Person" >
    <div *swalPartial>
        Face and all faces related though it will be assigned to new person
    </div>
</swal>


<swal #deleteSwal title="Are you sure?" type="warning" [showCancelButton]="true"
    confirmButtonClass="btn btn-outline-danger" cancelButtonClass="btn btn-outline"
    confirmButtonText="Delete Face">
    <div *swalPartial>
            Face will be deleted and can only be restored if image is reprocessed
    </div>
</swal>



