<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12">
          <h1>{{title}}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12" *ngIf="relations && relations.length == 0">
                  <h2>No relations found</h2>
                  <button class="btn btn-outline-primary" [routerLink]="'/persons/' + personId">Back to Person
                    #{{personId}}</button>
                </div>
                <div class="col-12" *ngIf="relations && relations.length > 0">
                  <h2>Showing relations between #{{personId}} {{ (person && person.name) ? person.name : '"Unnamed"' }}
                    and
                    #{{comparePersonId}} {{ (comparePerson && comparePerson.name) ? comparePerson.name : '"Unnamed"' }}
                  </h2>
                </div>

                <div class="col-6">
                  <h4>Person #{{personId}}</h4>
                  <div *ngIf="person">
                    <img [src]="'processed-images/faces/' + person.primaryFaceId + '.png'" />
                  </div>
                  <button class="btn btn-outline-primary" [routerLink]="'/persons/' + personId">Back to Person
                    #{{personId}}</button>
                  </div>
                  <div class="col-6">
                    <h4>Person #{{comparePersonId}}</h4>
                  <div *ngIf="comparePerson">
                    <img [src]="'processed-images/faces/' + comparePerson.primaryFaceId + '.png'" />
                  </div>
                  <button class="btn btn-outline-primary" [routerLink]="'/persons/' + comparePersonId">To Person
                    #{{comparePersonId}}</button>
                </div>
                <div class="col-12">
                  <app-loader *ngIf="showLoader"></app-loader>
                  <table class="table table-stribed">
                    <thead>
                    </thead>
                    <tbody>
                      <tr *ngFor="let rel of relations">
                        <td>
                          <img [src]="'processed-images/faces/' + rel.faceId + '.png'" />
                          <a [routerLink]="'/faces/' + rel.faceId" class="btn btn-outline-primary btn-sm">Show Face
                            Details</a>
                        </td>
                        <td>{{ rel.confidence | number }}% Confidence</td>
                        <td>
                          <img [src]="'processed-images/faces/' + rel.relatedFaceId + '.png'" />
                          <a [routerLink]="'/faces/' + rel.relatedFaceId" class="btn btn-outline-primary btn-sm">Show Face
                            Details</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>