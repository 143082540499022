<nav class="navbar horizontal-layout col-lg-12 col-12 p-0" *ngIf="user && user.email">
  <div class="nav-top flex-grow-1">
    <div class="container d-flex flex-row h-100">
      <div class="text-center navbar-brand-wrapper d-flex align-items-center">
        <a class="navbar-brand brand-logo" href=""><img src="assets/images/metatagger-logo-01.png" alt="logo" /></a>
      </div>
      <div class="navbar-menu-wrapper d-flex align-items-stretch justify-content-between flex-grow-1">
        <form class="search-field d-none d-md-flex" action="#">
          <div class="form-group mb-0">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="icon-magnifier"></i></span>
              </div>
              <input type="text" name="elvisId_id" class="form-control" style="width: 400px" style="color: white"
                placeholder="Search here..." [(ngModel)]="elvisId" (keydown.enter)="searchElvisId()" />
            </div>
          </div>
        </form>
        <ul class="navbar-nav navbar-nav-right mr-0 ml-auto">
          <li class="nav-item dropdown" *ngIf="history && history.length > 0">
            <div class="nav-link">
              <span class="dropdown-toggle btn btn-sm" id="historyDropdown" data-toggle="dropdown" aria-expanded="false">
                Recently viewed elements
              </span>
              <div class="dropdown-menu navbar-dropdown" aria-labelledby="historyDropdown">
                 <div *ngFor="let h of history">
                   <a [routerLink]="h.url" class="dropdown-item font-weight-medium">{{h.title}}</a>
                   <div class="dropdown-divider"></div>
                  </div>
                  <a (click)="clearHistory()" class="dropdown-item font-weight-medium">Clear History</a>
                </div>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#"
              data-toggle="dropdown">
              <i class="icon-bell"></i>
              <span *ngIf="errorsShown.length > 0" class="count">{{ lastCount }}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
              aria-labelledby="notificationDropdown">
              <a class="dropdown-item py-3">
                <p class="mb-0 font-weight-medium float-left">You have {{ lastCount }} new notifications
                </p>
                <span class="badge badge-pill badge-danger float-right">View all</span>
              </a>
              <div *ngFor="let e of errorsShown">
                <div class="dropdown-divider"></div>
                <a class="dropdown-item preview-item">
                  <div class="preview-thumbnail">
                    <div class="preview-icon bg-danger">
                      <i class="icon-exclamation mx-0"></i>
                    </div>
                  </div>
                  <div class="preview-item-content">
                    <h6 class="preview-subject font-weight-normal text-light mb-1">{{ e.name }} {{ e.status }}</h6>
                    <p class="font-weight-light small-text mb-0">
                      {{ e.time | amTimeAgo }}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </li>
          <li class="nav-item nav-profile">
            <app-gravatar [email]="user.email" (click)="refreshToken()" *ngIf="!this.showTokenLoader"></app-gravatar>
            <app-inline-loader *ngIf="this.showTokenLoader"></app-inline-loader>
          </li>
          <li class="nav-item nav-profile dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
              <span class="nav-profile-name">{{ user.fullname }}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
              <a class="dropdown-item" routerLink="/profile">
                <i class="icon-settings text-primary mr-2"></i>
                Settings
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()">
                <i class="icon-logout text-primary mr-2"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
        <button class="navbar-toggler align-self-center" type="button" data-toggle="minimize">
          <span class="icon-menu text-light"></span>
        </button>
      </div>
    </div>
  </div>
  <div class="nav-bottom">
    <div class="container">
      <ul class="nav page-navigation">
        <li class="nav-item">
          <a routerLink="/dashboard" class="nav-link"><i class="link-icon icon-screen-desktop"></i><span
              class="menu-title">Dashboard</span></a>
        </li>
        <li class="nav-item">
          <a routerLink="/images" class="nav-link"><i class="link-icon icon-screen-desktop"></i><span
              class="menu-title">Images</span></a>
        </li>
        <li class="nav-item">
          <a routerLink="/verification" class="nav-link"><i class="link-icon  icon-user-following"></i><span
              class="menu-title">Verification Queue</span></a>
        </li>
        <li class="nav-item">
          <a routerLink="/persons" class="nav-link"><i class="link-icon icon-user"></i><span
              class="menu-title">Persons</span></a>
        </li>
        <li class="nav-item mega-menu" *ngIf="authenticationService.hasAdminAccess()">
          <a class="nav-link"><i class="link-icon icon-docs"></i><span class="menu-title">Other</span><i
              class="menu-arrow"></i></a>
          <div class="submenu">
            <div class="col-group-wrapper row">
              <div class="col-group col-md-3">
                <p class="category-heading">Information</p>
                <ul class="submenu-item">
                  <li *ngIf="authenticationService.hasKeyCoreAccess() && authenticationService.hasSubscriptionLevel(levels.GOLD)" class="nav-item"><a class="nav-link"
                      routerLink="/faces/query">Advanced Face Search</a></li>
                  <li *ngIf="authenticationService.hasKeyCoreAccess() && authenticationService.hasSubscriptionLevel(levels.GOLD)" class="nav-item"><a class="nav-link"
                      routerLink="/persons/query">Advanced Person Search</a></li>
                  <li class="nav-item" *ngIf="authenticationService.hasSubscriptionLevel(levels.GOLD)"><a class="nav-link" routerLink="/faces/search" >Test Face Detection Values</a>
                  </li>
                  <!-- <li class="nav-item"><a class="nav-link" routerLink="/monitoring">Monitoring</a></li> -->
                  <li class="nav-item" *ngIf="authenticationService.hasSubscriptionLevel(levels.GOLD)"><a class="nav-link" routerLink="/statistics">Statistics</a></li>
                  <li class="nav-item" *ngIf="authenticationService.hasSubscriptionLevel(levels.SILVER)"><a class="nav-link" routerLink="/images/failed"> View Failed Images</a></li>
                  <li class="nav-item"><a class="nav-link" routerLink="/images/failed/notfound">View Images Not Found in DAM</a></li>
                  <li class="nav-item" *ngIf="authenticationService.hasSubscriptionLevel(levels.GOLD)"><a class="nav-link" routerLink="/known-names" >Known Names</a></li>
                </ul>
              </div>
              <div class="col-group col-md-3">
                <p class="category-heading">Add images</p>
                <ul class="submenu-item">
                  <li class="nav-item"><a class="nav-link" routerLink="/elvis-push">Force Push to DAM</a></li>
                  <li class="nav-item"><a class="nav-link" routerLink="/scanbucket">Scan S3 bucket</a></li>
                  <li class="nav-item"><a class="nav-link" routerLink="/upload">Manual Upload</a></li>
                </ul>
              </div>
              <div class="col-group col-md-3">
                <p class="category-heading">Settings</p>
                <ul class="submenu-item">
                  <li class="nav-item"><a class="nav-link" routerLink="/bugs" *ngIf="authenticationService.hasReportBugAccess()">Bug Reports</a></li>
                  <li class="nav-item"><a class="nav-link" routerLink="/users"  *ngIf="authenticationService.hasSubscriptionLevel(levels.SILVER)">List Users</a></li>
                  <li class="nav-item"><a class="nav-link" routerLink="/images/routing" >Image Routing</a></li>
                  <li class="nav-item"><a class="nav-link" routerLink="/settings/face-matching">Face Matching</a></li>
                  <li class="nav-item"><a class="nav-link" routerLink="/settings/person-matching">Person Matching</a></li>
                  <li class="nav-item"><a class="nav-link" routerLink="/settings/elvis">Elvis</a></li>
                  <li class="nav-item"><a class="nav-link" routerLink="/settings/processing" >Concurrent Processing</a>
                  </li>
                  <li class="nav-item"><a class="nav-link" routerLink="/settings/batch"  *ngIf="authenticationService.hasSubscriptionLevel(levels.GOLD)">Batch Jobs</a></li>
                  <li class="nav-item"><a class="nav-link" routerLink="/settings/reset"
                      *ngIf="authenticationService.hasKeyCoreAccess()">Reset System</a></li>
                </ul>
              </div>
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>
</nav>