import { Component, OnInit } from '@angular/core';
import { LogRecord } from 'src/app/model/log-record';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/services/title.service';
import { ErrorLogHandlerService } from 'src/app/services/error-log-handler.service';

@Component({
  selector: 'app-compare-logs',
  templateUrl: './compare-logs.component.html',
  styleUrls: ['./compare-logs.component.css']
})
export class CompareLogsComponent implements OnInit {

    logs1: LogRecord[] = [];
    logs2: LogRecord[] = [];
  maxLength = 250;
   title = 'Searching for requested logs';

   logGroup1: string;
   logStream1: string;
   requestId1: string;

   logGroup2: string;
   logStream2: string;
   requestId2: string;

  constructor(
    public authService: AuthenticationService,
    private notificationService: NotificationsService,
    private route: ActivatedRoute,
    private titleService: TitleService,
    private logService: ErrorLogHandlerService
  ) { }


  ngOnInit() {
    this.titleService.setTitle('Comparing Logs');
  }

  search1() {
    this.searchLogs(this.logGroup1, this.logStream1, this.requestId1, 'logs1');
  }

  search2() {
    this.searchLogs(this.logGroup2, this.logStream2, this.requestId2, 'logs2');
  }

  searchLogs(logGroup: string, logStream: string, requestId: string, name: string) {
      this[name] = undefined;

      const query = '"' + requestId + '"';

      this.logService.getLogs(logStream, logGroup, query).subscribe(l => {
          this[name] = l;
        });
  }

  showDetails(set: number, index: number) {
    document.getElementById('full_' + set + '_' + index).hidden = ! (document.getElementById('full_' + set + '_' + index).hidden);
    document.getElementById('partial_' + set + '_' + index).hidden = ! (document.getElementById('partial_' + set + '_' + index).hidden);
  }

}
