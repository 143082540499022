import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ProcessedImage } from 'src/app/model/ProcessedImage';
import { IdentifiedFace } from 'src/app/model/IdentifiedFace';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessedImageService } from 'src/app/services/images.service';
import { FaceLister } from 'src/app/interfaces/FaceLister';
import { TitleService } from 'src/app/services/title.service';
import { ImageEventLogEntry } from 'src/app/model/image-event-log-entry';
import { HistoryService } from 'src/app/services/history.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import {ContextMenuComponent, ContextMenuService} from '@perfectmemory/ngx-contextmenu';

@Component({
  selector: 'app-view-photo',
  templateUrl: './view-photo.component.html',
  styleUrls: ['./view-photo.component.css']
})
export class ViewPhotoComponent implements OnInit, FaceLister {

  showLoader = true;
  showMarkLoader = false;
  showPersonMatchingDetails = false;

  public image: ProcessedImage;
  title = 'Fetching image details';
  imageId: string;
  showUnidentifiedFaces = false;
  showIgnoredFaces = false;
  showIgnoredPersons = false;

  allFaces: IdentifiedFace[];
  generalLabels: any;
  locatedLabels: any;

  @ViewChild('faceMenu') contextMenu: ContextMenuComponent<any>;
  events: ImageEventLogEntry[];

  constructor(private route: ActivatedRoute,
              private imagesService: ProcessedImageService,
              private router: Router,
              private contextMenuService: ContextMenuService<any>,
              private titleService: TitleService,
              private historyService: HistoryService,
              public authService: AuthenticationService,
    ) { }

  ngOnInit() {
    this.getImage();
    this.route.params.subscribe((p) => this.getImage());
  }

  showFaceContextMenu($event: MouseEvent, face: IdentifiedFace): void {
    console.log('Showing face menu for #' + face.id);
    this.contextMenuService.show(
      this.contextMenu,
      {
      x: $event.x,
      y: $event.y,
      value: face,
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

  getImage() {
    this.showLoader = true;
    this.imageId = this.route.snapshot.paramMap.get('imageId');
    this.titleService.setTitle('Image #' + this.imageId);
    this.historyService.addEntry('Image #' + this.imageId, '/images/' + this.imageId);
    if (!this.imageId) {
      this.imageId = this.route.snapshot.paramMap.get('elvisId');
    }
    this.title = 'Fetching image details for ' + this.imageId;
    this.imagesService.getImage(this.imageId).subscribe((img) => {
      this.title = `Image #${img.id} - ${img.origname} - Details`;

      this.allFaces = img.faces;
      this.allFaces.sort((a, b) => a.id - b.id);

      for (const f of img.faces) {
        f.image = img;
      }

      this.image = img;
      this.events = undefined;
      this.imagesService.fetchImageEventLog(img.id).subscribe((e) => {
        this.events = e;

        // look for person matching events
        for (const ev of e) {
          if (ev.event === 'ROUTE_ACTIVATED') {
            if (ev.metadata.indexOf('person-matching') > 0) {
              this.showPersonMatchingDetails = true;
            }
          }
        }

      });
      this.updateShown();
    }, (err) => {
      this.title = 'Fetching of data failed for ' + this.imageId;
      this.showLoader = false;
      if (err.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        this.title += ' : Client-side error: ' + err.error.message;
        console.error('A Client-side network error occurred:', err.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        if (err.status !== '0') {
          this.title += ' : Server said: [' + err.status + '] ' + err.status;
        } else {
          this.title += ' Server did respond in time - this can happen during the PoC because we use a database that might shutdown do safe costs. Please try again.';
        }
        console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      }
    }
    );
  }

  reprocessImage() {
    this.showLoader = true;

    this.imagesService.reprocessImage(this.imageId).subscribe((i) => {
      this.getImage();
    });

  }

  toggleIdentifiedOnly() {
    // this.showUnidentifiedFaces = !(this.showUnidentifiedFaces);
    this.updateShown();
  }

  toggleIgnored() {
    // this.showIgnoredFaces = !(this.showIgnoredFaces);
    this.updateShown();
  }

  toggleIgnoredPersons() {
    // this.showIgnoredFaces = !(this.showIgnoredFaces);
    this.updateShown();
  }

  removeFace(id: number) {
    this.allFaces = this.allFaces.filter(f => f.id !== id);
  }

  updateFace(face: IdentifiedFace) {
    this.removeFace(face.id);
    this.allFaces.push(face);
    this.allFaces.sort((a, b) => a.id - b.id);
  }

  updateShown() {
    this.image.faces = [];

    for (const face of this.allFaces) {
      if ((this.showUnidentifiedFaces || (face.person && face.person.name))
      && (this.showIgnoredFaces || !face.ignored)
      && (this.showIgnoredPersons || !face.person.ignored)
      ) {
        this.image.faces.push(face);
      }
    }

    if (this.image.faces.length === 0 && !this.showUnidentifiedFaces) {
      this.showUnidentifiedFaces = true;
      this.updateShown();
    } else {
      this.showLoader = false;
    }

  }

  showPerson(personId: number) {
    this.router.navigate(['persons/' + personId]);
  }

  public drawBoundingBox(image: ProcessedImage, box: any, ignored: boolean) {
    const c: HTMLCanvasElement = document.getElementById('canvas_' + image.id) as HTMLCanvasElement;
    const img: HTMLImageElement = document.getElementById('img_' + image.id) as HTMLImageElement;

    c.height = img.height;
    c.width = img.width;

    c.hidden = false;
    c.style.display = 'block';
    img.hidden = true;

    const ctx = c.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    ctx.clearRect(0, 0, c.width, c.height);

    ctx.drawImage(img, 0, 0, c.width, c.height);
    ctx.beginPath();
    ctx.rect(c.width * box.x, c.height * box.y, c.width * box.width, c.height * box.height);
    ctx.lineWidth = 2;
    ctx.strokeStyle = (ignored) ? 'red' : 'blue';
    ctx.stroke();
}

public removeBoundingBox(image: ProcessedImage) {
  const c: HTMLCanvasElement = document.getElementById('canvas_' + image.id) as HTMLCanvasElement;
  const img: HTMLImageElement = document.getElementById('img_' + image.id) as HTMLImageElement;

  c.hidden = true;
  c.style.display = 'none';
  img.hidden = false;
}

verifyFace(face: IdentifiedFace) {
  face.showLoader = true;
  face.image = this.image;
  this.imagesService.verifyFace(face).subscribe(updatedFace => {
    face.personVerified = updatedFace.personVerified;
    face.personVerifiedBy = updatedFace.personVerifiedBy;
    face.personVerifiedDate = updatedFace.personVerifiedDate;
    face.showLoader = false;
  });
}

unverifyFace(face: IdentifiedFace) {
  face.showLoader = true;
  face.image = this.image;
  this.imagesService.unverifyFace(face).subscribe(updatedFace => {
    face.personVerified = updatedFace.personVerified;
    face.personVerifiedBy = updatedFace.personVerifiedBy;
    face.personVerifiedDate = updatedFace.personVerifiedDate;
    face.showLoader = false;
  });
}

  toggleMarked() {
    this.showMarkLoader = true;
    if (this.image.marked) {
      this.imagesService.markImage(this.image).subscribe((i) => {
        this.image = i;
        this.showMarkLoader = false;
        this.updateShown();
      });
    } else {
        this.imagesService.unmarkImage(this.image).subscribe((i) => {
          this.image = i;
          this.showMarkLoader = false;
          this.updateShown();
        });

    }
  }

}
