import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Statistics } from '../model/Statistics';
import { HistoryElement } from '../model/HistoryElement';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  public url = 'v1';

  constructor(public http: HttpClient) { }

  public getQueueStatistics(): Observable<any> {
      return this.http.get<any>(this.url + '/statistics/queues?v=' + new Date().getTime());
    }

  public getOverallStatistics(): Observable<Statistics> {
      return this.http.get<Statistics>(this.url + '/statistics/overall?v=' + new Date().getTime());
  }

  public getPendingStatistics(): Observable<Statistics> {
    return this.http.get<Statistics>(this.url + '/persons/verification-queue/statistics?v=' + new Date().getTime());
  }

  public getPendingNamingStatistics(): Observable<Statistics> {
    return this.http.get<Statistics>(this.url + '/persons/naming-queue/statistics?v=' + new Date().getTime());
  }

  public getPendingUnnamedStatistics(): Observable<Statistics> {
    return this.http.get<Statistics>(this.url + '/persons/unnamed/verification-queue/statistics?v=' + new Date().getTime());
  }

  public getPerRegionImageUploadStatistics(): Observable<Statistics> {
    return this.http.get<Statistics>(this.url + '/statistics/image-uploads/per-region?v=' + new Date().getTime());
  }

  public getImageUploadStatistics(): Observable<Statistics> {
    return this.http.get<Statistics>(this.url + '/statistics/image-uploads?v=' + new Date().getTime());
  }

  public getCurrentMonthStatistics(): Observable<Statistics> {
    return this.http.get<Statistics>(this.url + '/statistics/current-month?v=' + new Date().getTime());
  }

  public getQueueHistory(key: string): Observable<HistoryElement[]> {
    return this.http.get<HistoryElement[]>(this.url + '/statistics/queues/' + key + '/history?v=' + new Date().getTime());
  }

}
