<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12">
          <h1 *ngIf="persons">Persons that appear to be named more than once</h1>
          <h1 *ngIf="!persons">Searching for persons that appear to be named more than once</h1>
        </div>
      </div>
      <div class="row" *ngIf="persons">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12" *ngIf="persons">
                  <h2>Showing {{persons.length}} names that appear more than once out of a total of {{allPersons.length}} across your collection</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <app-loader *ngIf="showLoader"></app-loader>
                <div class="col-12" *ngIf="persons">
                  <div class="dropdown" style="display: inline-block;">
                    <button type="button" class="btn btn-outline-primary icon-btn dropdown-toggle" id="selectNationalityButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Filter by region <i *ngIf="selectedNationality && selectedNationality != 'All'"
                        class="flag-icon flag-icon-{{selectedNationality}}"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="selectNationalityButton">
                      <h6 class="dropdown-header">Show</h6>
                      <a [ngClass]="(selectedNationality == 'all') ? 'dropdown-item active' : 'dropdown-item'"
                        (click)="setNationality('all')">All</a>
                      <div class="dropdown-divider"></div>
                      <a [ngClass]="(selectedNationality == n.code) ? 'dropdown-item active' : 'dropdown-item'"
                        *ngFor="let n of nationalities" (click)="setNationality(n.code)"><i class="flag-icon flag-icon-{{n.code}}"></i>
                        {{n.name}}</a>
                    </div>
                  </div>
                  <div class="dropdown" style="display: inline-block;">
                    <button type="button" class="btn btn-outline-primary icon-btn dropdown-toggle" id="selectLimitButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Showing {{(limit > 0) ? limit : 'all'}} persons
                    </button>
                    <div class="dropdown-menu" aria-labelledby="selectLimitButton">
                      <h6 class="dropdown-header">Number of Persons to show</h6>
                      <a (click)="setLimit(-1)" class="dropdown-item">All</a>
                      <div class="dropdown-divider"></div>
                      <a *ngFor="let i of [10,25,50,100,200]" (click)="setLimit(i)" class="dropdown-item">{{i}}</a>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngFor="let per of persons">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <app-loader *ngIf="showLoader"></app-loader>
                <div class="col-12" *ngIf="persons">
                  <div class="row">
                    <div class="col-12">
                      <h4>{{per[0].name}}</h4>
                    </div>
                    <ng-container *ngFor="let p of per; let i = index;">
                      <div class="col-2" *ngIf="p.status != 'PENDING_MERGE'">
                        <img [src]="'processed-images/faces/' + p.primaryFaceId + '.png'" *ngIf="!p.showLoader" />
                        <app-inline-loader *ngIf="p.showLoader"></app-inline-loader>
                        <p>{{ p.facesCount }} Faces
                          <i class="flag-icon flag-icon-{{p.nationality}}" *ngIf="p.nationality"></i>
                        </p>
                        <p *ngIf="p.namedByAI">
                        Named by AI on {{ p.namedByAIAt | date:'yyyy-MMM-dd HH:mm' | uppercase }}
                      </p>
                      <p *ngIf="!p.namedByAI">
                        Named manually
                      </p>
                      <a [routerLink]="'/persons/' + p.id" class="btn btn-outline-primary btn-block">Show Person #{{p.id}}</a><br />
                      <a *ngIf="i > 0" (click)="quickMerge(p, per[0])"
                        class="btn btn-outline-success btn-block">Quick Merge {{p.id}} into {{per[0].id}}</a><br />
                        <a *ngIf="i > 0" [routerLink]="'/merge/' + p.id" [queryParams]="{with: per[0].id}"
                          class="btn btn-outline-primary btn-block">Merge {{p.id}} with {{per[0].id}}</a>
                        </div>
                      </ng-container>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                  <app-loader *ngIf="showLoader"></app-loader>
                  <button *ngIf="!showLoader" class="btn btn-outline-primary" (click)="loadMore()">Load More</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>