import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css']
})
export class GridComponent implements OnInit {

  columnDefs = [
    {headerName: 'FaceID', field: 'make', sortable: true, filter: true},
    {headerName: 'Confidence', field: 'model', sortable: true, filter: true},
    {headerName: 'Image', field: 'price', sortable: true, filter: true}
];


rowData = [
  { make: 'Toyota', model: 'Celica', price: 35000 },
  { make: 'Ford', model: 'Mondeo', price: 32000 },
  { make: 'Porsche', model: 'Boxter', price: 72000 }
];
  constructor() { }

  ngOnInit() {
  }

}
