import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../model/Environment';
import { CustomerManager } from '../model/customer-manager';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentsService {

  private url = 'v1';

  selectedEnv: BehaviorSubject<Environment> = new BehaviorSubject<Environment>(undefined);

  constructor(httpBackend: HttpBackend) {
    const http = new HttpClient(httpBackend);

    http.get<Environment>(this.url + '/settings/login-details?v=' + new Date().getTime()).subscribe(v => {
      this.selectedEnv.next(v);
      CustomerManager.CustomerName = v.customerName;
      CustomerManager.SubscriptionLevel = v.subscriptionLevel;
    });

  }

}
