<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
      <div class="content-wrapper">
        <h1>Adjust Person Match settings</h1>
        <div class="row">
          <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-6" *ngIf="true">
                    <h3>Person match settings</h3>
                    <app-loader *ngIf="showLoader"></app-loader>
                    <div *ngIf="currentSettings">
                      <div class="form-group">
                        <label>Attribute face to person when match is above</label>
                        <div class="input-group">
                          <input class="form-control" type="number" [(ngModel)]="currentSettings.faceMatchThreshold"
                            max="100" step="0.1" style="text-align: right" [readOnly]="!authService.hasAdminAccess()" />
                          <div class="input-group-append">
                            <span class="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Maximum number of faces per image</label>
                        <input class="form-control" type="number" [(ngModel)]="currentSettings.maxFacesPerImage" max="100"
                          step="1" style="text-align: right" [readOnly]="!authService.hasAdminAccess()" />
                      </div>
                      <div class="form-group">
                        <label>Auto verify faces when face match to existing verified face is</label>
                        <div class="input-group">
                          <input class="form-control" type="number" [(ngModel)]="currentSettings.autoVerifyLimit"
                            max="100" step="0.1" style="text-align: right" [readOnly]="!authService.hasAdminAccess()" />
                          <div class="input-group-append">
                            <span class="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Image resize limit (larger images will be resized before analysis)</label>
                        <div class="input-group">
                          <input class="form-control" type="number" [(ngModel)]="currentSettings.resizeLimit" step="1024"
                            style="text-align: right" [readOnly]="!authService.hasAdminAccess()" />
                          <div class="input-group-append">
                            <span class="input-group-text">kb</span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Minimum number of faces required for AI naming</label>
                        <input class="form-control" type="number" [(ngModel)]="currentSettings.minFacesForAINaming" max="1000"
                          step="1" style="text-align: right" [readOnly]="!authService.hasAdminAccess()" />
                      </div>
                      <div class="form-group">
                        <label>Minimum number of faces required for External naming</label>
                        <input class="form-control" type="number" [(ngModel)]="currentSettings.minFacesForExternalNaming" max="1000"
                          step="1" style="text-align: right" [readOnly]="!authService.hasAdminAccess()" />
                      </div>
                      <div class="form-group">
                        <button class="btn btn-outline-primary btn-block" (click)="save()" *ngIf="!saveInProgress"
                          type="submit" [disabled]="!authService.hasAdminAccess()" style="margin: 0px;">Save</button>
                        <app-loader *ngIf="saveInProgress"></app-loader>
                      </div>
                    </div>
                  </div>
                  <div class="col-6" *ngIf="true">
                     <h3>Allow verification on unnamed</h3>
                     <div class="form-group">
                       <label>Verify faces for unnamed persons?</label>
                       <app-inline-loader *ngIf="!currentSettings"></app-inline-loader>
                        <div class="input-group" *ngIf="currentSettings">
                          <label class="switch">
                            <input type="checkbox" [(ngModel)]="currentSettings.verifyUnnamed" (change)="save()">
                            <span class="slider round"></span>
                          </label>
                          <p>{{ verifyUnknownLabel }}</p>
                        </div>
                      </div>
                     <h3>Queue pending images for person matching</h3>
                     <div class="form-group">
                       <label>Send images directly to Person matching</label>
                       <app-inline-loader *ngIf="showMatchSettingLoader"></app-inline-loader>
                        <div class="input-group" *ngIf="!showMatchSettingLoader">
                          <label class="switch">
                            <input type="checkbox" [(ngModel)]="currentSettings.sendToPersonMatching" (change)="updateDirectPersonMatchSetting()">
                            <span class="slider round"></span>
                          </label>
                          <p>{{ directMatchUpdateLabel }}</p>
                        </div>
                      </div>
                     <h3 *ngIf="authService.hasKeyCoreAccess()">Force person matching operations to DB Master</h3>
                     <div class="form-group"  *ngIf="authService.hasKeyCoreAccess()">
                       <label>Do not use read-replicas during Person matching</label>
                       <app-inline-loader *ngIf="showUseReadReplicaLoader"></app-inline-loader>
                        <div class="input-group" *ngIf="!showUseReadReplicaLoader">
                          <label class="switch">
                            <input type="checkbox" [(ngModel)]="currentSettings.useReadReplicasDuringPersonMatching" (change)="updateUseReadReplicasSetting()">
                            <span class="slider round"></span>
                          </label>
                          <p>{{ useReadReplicasUpdateLabel }}</p>
                        </div>
                      </div>
                     <h3>Ignored Faces</h3>
                     <div class="form-group">
                       <label>Match ignored faces to persons?</label>
                       <app-inline-loader *ngIf="showIgnoredSettingLoader"></app-inline-loader>
                        <div class="input-group" *ngIf="!showIgnoredSettingLoader">
                          <label class="switch">
                            <input type="checkbox" [(ngModel)]="currentSettings.matchIgnoredFaces" (change)="updateIgnoredMatchSetting()">
                            <span class="slider round"></span>
                          </label>
                          <p>{{ ignoredMatchUpdateLabel }}</p>
                        </div>
                      </div>
                      <div>
                        <h3>Queue Pending Person Matches</h3>
                          <div class="form-group">
                            <label>Re-search Collection for matches</label>
                            <div class="input-group">
                              <label class="switch">
                                <input type="checkbox" [(ngModel)]="reSearchCollection">
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <p>{{ queueResult }}</p>
                          <button class="btn btn-outline-secondary" (click)="updateSettings()">Update</button>
                          <button class="btn btn-outline-success" [swal]="{ title: 'Are you sure?',
                          text: 'All images in status pending person matching will be sent to queue',
                          type: 'info',
                          showCancelButton: true,
                          confirmButtonClass: 'btn btn-outline-success',
                          cancelButtonClass: 'btn btn-outline',
                          confirmButtonText: 'Send all pending images to Queue'
                        }" (confirm)="startPendingQueue()">Send all images pending person matching to queue</button>
                      </div>
                      <app-loader *ngIf="showQueueLoader"></app-loader>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
            <div class="card">
              <div class="card-body">
                <div class="col-12" *ngIf="true">
                  <h3>{{ autoUpdateTitle }}</h3>
                  <app-loader *ngIf="showPendingLoader"></app-loader>
                  <div *ngIf="currentStatistics && pendingStatistics">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Company</th>
                          <th>Pending Manual</th>
                          <th>Will be Auto Verified</th>
                          <th>Will be Removed</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let region of regions">
                          <td><i class="flag-icon flag-icon-{{region.code}}"></i> {{region.name}}</td>
                          <td>{{ getPendingCount(region.code) }}</td>
                          <td>{{ getDryRunAddedCount(region.code) }}</td>
                          <td>{{ getDryRunRemovedCount(region.code) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                      <button class="btn btn-outline-success btn-block" [swal]="{ title: 'Update auto-verified faces?',
                              text: 'Enforce current settings and change number of auto-verified faces?',
                              type: 'warning',
                              showCancelButton: true,
                              confirmButtonClass: 'btn btn-outline-success',
                              cancelButtonClass: 'btn btn-outline',
                              confirmButtonText: 'Update Auto Verified'
                            }" (confirm)="updateAutoVerified()" *ngIf="!autoVerifyInProgress" type="submit"
                        [disabled]="!authService.hasAdminAccess()" style="margin: 0px;">Update Auto Verified
                        Faces</button>
                      <app-loader *ngIf="autoVerifyInProgress"></app-loader>
                    </div>

                </div>
              </div>
            </div>
          </div>
          <div class="col-12 grid-margin stretch-card">
            <div class="card">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-4" *ngIf="true">
                      <h3>AI Naming of Persons</h3>
                      <p>This is a feature used to auto-name duplicated persons. </p>
                      <app-loader *ngIf="showAINamingLoader"></app-loader>
                      <p>

                        <button class="btn btn-outline-success btn-block" [swal]="{ title: 'Send all unnamed Persons to AI naming?',
                              text: 'AI will attempt to assign a name to all unnamed persons - based on their resemblance to already named persons.',
                              type: 'warning',
                              showCancelButton: true,
                              confirmButtonClass: 'btn btn-outline-success',
                              cancelButtonClass: 'btn btn-outline',
                              confirmButtonText: 'Start Naming'
                            }" (confirm)="startAINaming()" *ngIf="!showAINamingLoader" type="submit"
                        [disabled]="!authService.hasAdminAccess()" style="margin: 0px;">Attempt to let AI name currently unnamed Persons</button>
                      </p>
                      <p>

                        <button class="btn btn-outline-danger btn-block" [swal]="{ title: 'Clear all names assigned by AI naming?',
                              text: 'All Persons named by AI will be unnamed again!',
                              type: 'warning',
                              showCancelButton: true,
                              confirmButtonClass: 'btn btn-outline-danger',
                              cancelButtonClass: 'btn btn-outline',
                              confirmButtonText: 'Clear all AI assigned names'
                            }" (confirm)="clearAINaming()" *ngIf="!showAINamingLoader" type="submit"
                        [disabled]="!authService.hasAdminAccess()" style="margin: 0px;">Clear all AI namings</button>
                      </p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 grid-margin stretch-card">
            <div class="card">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-4" *ngIf="true">
                      <h3>External Naming of Persons</h3>
                      <p>This is a feature used to auto-name persons by matching against external sources.</p>
                      <app-loader *ngIf="showExternalNamingLoader"></app-loader>
                      <p>

                        <button class="btn btn-outline-success btn-block" [swal]="{ title: 'Send all unnamed Persons to External naming?',
                              text: 'AI will attempt to assign a name to all unnamed persons - based on their resemblance to identified external persons.',
                              type: 'warning',
                              showCancelButton: true,
                              confirmButtonClass: 'btn btn-outline-success',
                              cancelButtonClass: 'btn btn-outline',
                              confirmButtonText: 'Start Naming'
                            }" (confirm)="startExternalNaming()" *ngIf="!showAINamingLoader" type="submit"
                        [disabled]="!authService.hasAdminAccess()" style="margin: 0px;">Attempt to match currently unnamed Persons against external sources</button>
                      </p>
                      <p>

                        <button class="btn btn-outline-danger btn-block" [swal]="{ title: 'Clear all names assigned by external naming?',
                              text: 'All Persons named by AI will be unnamed again!',
                              type: 'warning',
                              showCancelButton: true,
                              confirmButtonClass: 'btn btn-outline-danger',
                              cancelButtonClass: 'btn btn-outline',
                              confirmButtonText: 'Clear all names that comes from external sources'
                            }" (confirm)="clearExternalNaming()" *ngIf="!showAINamingLoader" type="submit"
                        [disabled]="!authService.hasAdminAccess()" style="margin: 0px;">Clear all external namings</button>
                      </p>
                     </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 grid-margin stretch-card">
            <div class="card">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12" *ngIf="true">
                      <h3>Remove AI Verifications and Reprocess All Persons</h3>
                    </div>
                    <div class="col-4">
                        <p>
                          <button class="btn btn-outline-danger btn-block" [swal]="{ title: 'Are you sure?',
                          text: 'All AI verificaitions will deleted and all persons will be sent to reprocessing (first auto-verification and the reprocess of all images) !!! This CANNOT be undone !!!',
                          type: 'warning',
                          showCancelButton: true,
                          confirmButtonClass: 'btn btn-outline-danger',
                          cancelButtonClass: 'btn btn-outline',
                          confirmButtonText: 'Start Process'
                        }" (confirm)="removeAIVerificationsAndReprocess()">Remove AI verifications and reprocess all persons</button>
                        </p>
                        <p>
                          <button class="btn btn-outline-primary btn-block" [swal]="{ title: 'Are you sure?',
                          text: 'Queue all persons for face-tree verification? This may trigger a lot of updates to DAM.',
                          type: 'warning',
                          showCancelButton: true,
                          confirmButtonClass: 'btn btn-outline-primary',
                          cancelButtonClass: 'btn btn-outline',
                          confirmButtonText: 'Start Process'
                        }" (confirm)="restartVerificationForAll()">Start Tree Verification for all persons</button>
                        </p>
                      </div>
                      <div class="col-2">
                        &nbsp;
                      </div>
                      <div class="col-6">
                        <h4>Description</h4>
                        <p>Use this to clean up matches and verifications done by AI using previous settings and older algorithms. The following steps will be taken:</p>
                        <ul class="list-arrow">
                          <li>All AI verifications in the system are deleted - but no images are reassigned or removed from the person they have been matched to. <span class="text-danger">("AKA The Red Step")</span></li>
                          <li>An asynchronous task is started to put all identified persons on a queue for facetree verification</li>

                          <li>Per person on the Facetree Verification Queue - the following steps will be taken:
                          <ul>
                            <li>Starting from top of the persons facetree verification is done "down" the branches as long as match score
                                (similarity distance) is above auto verification threshold defined above. <span class="text-primary">("AKA The Blue Step")</span></li>
                                <li>All images with person is marked as "Pending Reprocessing" with a unique token. <span class="text-warning">("AKA The Yellow Step")</span></li>
                                <li>New asynchronous task is started with the token and will put all images marked "Pending Reprocessing" with the specific token onto the Person Matching Queue</li>
                                <li>Per image on the Person Matching Queue - the following steps will be taken:
                                  <ul>
                                    <li>Reset all matched persons for image</li>
                                    <li>Reprocess image in AWS Rekognition</li>
                                    <li>Rerun person matching with settings defined above</li>
                                    <li>Autoverification is <strong>only done</strong> if image is matched to person with a match score above auto verification threshold AND if match is to an already verified image</li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-4" *ngIf="true">
                      <div>
                        <h3>Reset AI Verifications</h3>
                        <p>
                          <button class="btn btn-outline-danger btn-block" [swal]="{ title: 'Are you sure?',
                          text: 'All AI made verifications will be removed and relations have to be reprocessed with current thresholds !!! This CANNOT be undone !!!',
                          type: 'warning',
                          showCancelButton: true,
                          confirmButtonClass: 'btn btn-outline-danger',
                          cancelButtonClass: 'btn btn-outline',
                          confirmButtonText: 'Remove AI Verifications'
                        }" (confirm)="resetAIVerifications()">Reset all AI verifications - but keep faces in database</button>
                        </p>
                      </div>
                      <div>
                        <h3>Reset all Verifications</h3>
                        <p>
                          <button class="btn btn-outline-danger btn-block" [swal]="{ title: 'Are you sure?',
                          text: 'All verifications will be removed and relations have to be reprocessed with current thresholds !!! This CANNOT be undone !!!',
                          type: 'warning',
                          showCancelButton: true,
                          confirmButtonClass: 'btn btn-outline-danger',
                          cancelButtonClass: 'btn btn-outline',
                          confirmButtonText: 'Remove Verifications'
                        }" (confirm)="resetAllVerifications()">Reset all verifications - but keep faces in database</button>
                        </p>
                      </div>
                      <div>
                        <h3>Delete All Persons</h3>
                        <p>
                          <button class="btn btn-outline-danger btn-block" [swal]="{ title: 'Are you sure?',
                          text: 'All Persons will be deleted and will have to be renamed !!! This CANNOT be undone !!!',
                          type: 'warning',
                          showCancelButton: true,
                          confirmButtonClass: 'btn btn-outline-danger',
                          cancelButtonClass: 'btn btn-outline',
                          confirmButtonText: 'Delete Persons'
                        }" (confirm)="resetAllPersons()">Delete all Persons - but keep faces in database</button>
                        </p>
                      </div>
                      <div>
                        <h3>Delete Unnamed Persons</h3>
                        <p>
                          <button class="btn btn-outline-danger btn-block" [swal]="{ title: 'Are you sure?',
                          text: 'All un-named Persons will be deleted !!! This CANNOT be undone !!!',
                          type: 'warning',
                          showCancelButton: true,
                          confirmButtonClass: 'btn btn-outline-danger',
                          cancelButtonClass: 'btn btn-outline',
                          confirmButtonText: 'Delete Unnamed Persons'
                        }" (confirm)="resetAllUnnamedPersons()">Delete all unnamed Persons - but keep faces in database</button>
                        </p>
                      </div>
                      <app-loader *ngIf="showDeleteLoader"></app-loader>
                      <ul *ngIf="results">
                        <li class="completed" *ngFor="let e of results">{{e}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
