import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TreeChartConfig, TreeModel, TreeNode } from 'src/app/model/TreeModel';
import { FaceRelation } from 'src/app/model/FaceRelation';
import { Meta } from '@angular/platform-browser';
import { FaceLister } from 'src/app/interfaces/FaceLister';
import { IdentifiedFace } from 'src/app/model/IdentifiedFace';
import {ContextMenuComponent, ContextMenuService} from '@perfectmemory/ngx-contextmenu';

declare function Treant(conf: TreeModel): void;

@Component({
  selector: 'app-face-tree',
  templateUrl: './face-tree.component.html',
  styleUrls: ['./face-tree.component.css']
})
export class FaceTreeComponent implements OnInit, FaceLister {

  @ViewChild('faceMenu') contextMenu: ContextMenuComponent<any>;

  treeStart: number;
  generated = false;
  highlightFaceId?: number;

  @Input() public set relations(rel: FaceRelation[]) {
    this._relationsList = rel;
    this.showChart();
  }

  // tslint:disable-next-line: variable-name
  _relationsList: FaceRelation[];
  errorText: string;
  showLoader = true;
  text: string;
  count: number;

  model: TreeModel;

  constructor(
    private contextMenuService: ContextMenuService<any>
  ) { }

  ngOnInit() {

  }

  showFaceContextMenu($event: MouseEvent, faceId: number): void {
    console.log('Showing face menu for #' + faceId);
    this.contextMenuService.show(this.contextMenu,{
      x: $event.x,
      y: $event.y,
      value: { id: faceId },
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

  showChart() {
    if (this.generated) {
      return;
    }
    this.text = 'Data fetched - generating tree';
    const start = new Date().getTime();
    const model = new TreeModel();

    if (!this._relationsList) {
      this.errorText = 'Unable to generate tree: No data provided';
      this.showLoader = false;
      return;
    }
    let rootFace = this._relationsList.filter((rel) => !rel.relatedFaceId);
    if (!rootFace || rootFace.length === 0) {
      for (const rel of this._relationsList) {
        const r = this._relationsList.filter((re) => re.faceId === rel.relatedFaceId);
        if (!r || r.length === 0) {
          rootFace = [rel];
          break;
        }
      }
    }

    model.nodeStructure.collapsed = false;
    model.nodeStructure.collapsable = true;
    model.nodeStructure.HTMLclass = 'treeface';

    if (!rootFace || rootFace.length === 0) {
      this.errorText = 'Unable to generate tree: No root found';
      this.showLoader = false;
      return;
    } else if (rootFace.length > 1) {
      console.log('Found ' + rootFace.length + ' roots - generating fake top node');
      // console.log(JSON.stringify(rootFace));
      model.nodeStructure.HTMLid = 'multi_root';
      model.nodeStructure.collapsable = false;

      model.nodeStructure.text = {
        title: 'Multi Roots Found'
      };

      model.nodeStructure.children = [];

      const added = [];
      for (const rootNode of rootFace) {
        if (added.indexOf(rootNode.faceId) === -1) {
          const n = this.generateTreeNode(rootNode);
          n.children = this.findChildren(this._relationsList, rootNode.faceId, 0, undefined);
          model.nodeStructure.children.push(n);
          added.push(rootNode.faceId);
        }
      }

    } else {
      model.nodeStructure.image = this.getImageUrl(rootFace[0].faceId);
      model.nodeStructure.HTMLid = 'face_' + rootFace[0].faceId;

      model.nodeStructure.text = {
        title: 'First'
      };

      model.nodeStructure.children = this.findChildren(this._relationsList, rootFace[0].faceId, 0, undefined);
    }



    this.count = 1;

    const end = new Date().getTime();
    console.log('Data structure generated in ' + (end - start) + ' ms');
    this.text = 'Fetching required images - this may take a few minutes...';
    this.treeStart = new Date().getTime();
    // console.log(JSON.stringify(model, null, 4));

    const me = this;
    model.chart.container = '#facetree';
    model.chart.callback = {
      onTreeLoaded: () => {
        me.showLoader = false;
        me.text = '';

        // hack to fix Treant size
        const svg = document.getElementsByTagName('svg');
        for (let i = 0; i < svg.length; i++) {
          const node = svg.item(i);
          node.setAttribute('height', (Number(node.getAttribute('height')) + 50) + '');
        }

        const nodes = document.getElementsByClassName('treeface');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes.item(i);
          node.addEventListener('contextmenu', (e: any) => {
            me.showFaceContextMenu(e, Number(node.id.substring('face_'.length)));
            e.preventDefault();
          });
        }
      },
      onBeforeClickCollapseSwitch: () => {
        // console.log('Collapsing');
      },
      onAfterClickCollapseSwitch: () => {
        // console.log('Collapsed');
      }
    };
    model.chart.animateOnInit = false;

    model.chart.connectors = {
      type: 'bCurve',
      style: {
        stroke: 'black'
      }
    };

    model.chart.node = {
      collapsable : true
    };

    this.model = model;
    const tree = new Treant(model);
    this.generated = true;
  }

  getImageUrl(id: number): string {
    return 'processed-images/faces/' + id + '.png';
  }

  expandAll() {
    for (const c of this.model.nodeStructure.children) {
      const el = document.getElementById(c.HTMLid);
      this.clickSwitch(el, false);
    }
  }

  clickSwitch(node: HTMLElement, collapse: boolean) {
    if (node.classList.contains('collapse-switch')) {
      const parent: HTMLElement = node.parentNode.parentNode as HTMLElement;
      if (collapse && !parent.classList.contains('collapsed')) {
        setTimeout(() => node.click(), 0);
      } else if (!collapse && parent.classList.contains('collapsed')) {
        setTimeout(() => node.click(), 0);
      }
    } else {
      for (let i = 0; i < node.children.length; i++) {
        this.clickSwitch(node.children.item(i) as HTMLElement, collapse);
      }
    }
  }

  collapseAll() {
    console.log('found: ' + this.model.nodeStructure.children.length);
    for (const c of this.model.nodeStructure.children) {
      const el = document.getElementById(c.HTMLid);
      this.clickSwitch(el, true);
    }
  }

  findChildren(relations: FaceRelation[], parentId: number, level: number, grandParentId: number): TreeNode[] {
    const res = [];
    // console.log('Looking for children to face: ' + parentId);

    const childRelations = relations.filter((rel) => rel.relatedFaceId === parentId && rel.relationUsedToMatch);

    for (const re of childRelations) {
      if (re.faceId === grandParentId) {
        // recursive relation
        const n = new TreeNode();
        this.count++;
        n.innerHTML = `<div style="background-color: #FF0000;">Recursive Reference!!!</div>`;
        n.HTMLid = 'face_' + re.faceId;
        n.HTMLclass = 'treeface';
        res.push(n);
      } else {
        const n = this.generateTreeNode(re);
        n.children = this.findChildren(relations, re.faceId, level + 1, parentId);
        res.push(n);
      }
    }

    return res;
  }
  generateTreeNode(re: FaceRelation): TreeNode {
    const n = new TreeNode();
    this.count++;
    // n.collapsed = (level > 3);
    n.collapsable = true;
    if (re.faceId === this.highlightFaceId) {
      n.innerHTML = `<div><a class="collapse-switch"><img style="border-color: #FF0000; border-style: dashed; border-width: 4px;" src="${this.getImageUrl(re.faceId)}" /></a><br />`;
    } else {
      n.innerHTML = `<div><a class="collapse-switch"><img src="${this.getImageUrl(re.faceId)}" /></a><br />`;
    }
    if (re.verified) {
      n.innerHTML += '<i class="icon icon-check"></i>';
    }
    n.innerHTML +=
    `<p>${re.confidence.toPrecision(5)} %</p>
    </div>`;
    n.HTMLid = 'face_' + re.faceId;
    n.HTMLclass = 'treeface';

    return n;
  }

  updateShown(): void {
    // Nothing
  }

  removeFace(id: number) {
    // Nothing
    this.generated = undefined;
    this.showLoader = true;
    this.showChart();
  }

  updateFace(face: IdentifiedFace) {
    // Nothing
  }


}
