import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TitleService } from 'src/app/services/title.service';
import { LogRecord } from 'src/app/model/log-record';
import { ErrorLogHandlerService } from 'src/app/services/error-log-handler.service';
import { ActivatedRoute } from '@angular/router';

declare function Treant(conf: any): void;

@Component({
  selector: 'app-search-logs',
  templateUrl: './search-logs.component.html',
  styleUrls: ['./search-logs.component.css']
})
export class SearchLogsComponent implements OnInit {

    logs: LogRecord[];
    maxLength = 250;
    title = 'Searching for requested logs';

  constructor(
    public authService: AuthenticationService,
    private notificationService: NotificationsService,
    private route: ActivatedRoute,
    private titleService: TitleService,
    private logService: ErrorLogHandlerService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Viewing Logs');
    this.route.params.subscribe((p) => this.searchLogs());
  }

  searchLogs() {
      this.logs = undefined;

      const logGroup = this.route.snapshot.paramMap.get('logGroup').split('@@').join('/') ;
      let logStream = this.route.snapshot.paramMap.get('logStream').split('@@').join('/') ;
      if (logStream === '-') {
          logStream = undefined;
      }
      const query = '"' + this.route.snapshot.paramMap.get('query') + '"';

      this.logService.getLogs(logStream, logGroup, query).subscribe(l => {
          this.logs = l;
          this.title = 'Showing ' + l.length + ' log records';
        });
  }

  showDetails(index: number) {
    document.getElementById('full_' + index).hidden = ! (document.getElementById('full_' + index).hidden);
    document.getElementById('partial_' + index).hidden = ! (document.getElementById('partial_' + index).hidden);
  }

}
