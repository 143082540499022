import * as React from 'react';
import Editor from './editor/Editor'

class EditorRender extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
          <Editor providers={this.props.providers} routes={this.props.routes} insertRoute={this.props.insertRoute} deleteRoute={this.props.deleteRoute} updateRoute={this.props.updateRoute}/>
      </div>
    )
  }

}
export default EditorRender;
