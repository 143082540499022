<div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="row">
          <div class="col-12 grid-margin stretch-card">
            <h1>{{title}}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 grid-margin stretch-card"> 
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <!-- <button alt="Show data as grid" (click)="switchViewType(false)" class="btn" [class]="(showGrid) ? 'btn btn-primary' : 'btn btn-outline-primary'"><i class="icon-list"></i></button>
                    <button alt="Show data as tree" (click)="switchViewType(true)" class="btn"  [class]="(showTree) ? 'btn btn-primary' : 'btn btn-outline-primary'"><i class="icon-vector"></i></button>
                    -->
                    <button alt="Show data as tree" (click)="expandAll()" class="btn"  [class]="'btn btn-outline-primary'"><i class="icon-plus"></i>Expand All</button> 
                    <button alt="Show data as tree" (click)="collapseAll()" class="btn"  [class]="'btn btn-outline-primary'"><i class="icon-close"></i>Collapse All</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                      <app-face-tree #faceTree *ngIf="showTree"></app-face-tree>
                      <app-face-grid #faceGrid *ngIf="showGrid"></app-face-grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>