<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12 grid-margin">
          <div class="card card-statistics">
            <div class="card-body p-0">
              <div *ngIf="showLoader" class="row">
                <div class="col-md-12 col-lg-12">
                  <app-loader></app-loader>
                </div>
              </div>
              <div *ngIf="showData" class="row">
                <div class="col-md-6 col-lg-3">
                  <div class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ s3Queue | number }}</h1>
                      <p class="text-muted mb-0">Pending S3 Events</p>
                    </div>
                    <i class="icon-energy text-primary icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="d-flex justify-content-between card-statistics-item">
                    <div>
                      <h1>{{ s3ElvisQueue | number }}</h1>
                      <p class="text-muted mb-0">Pending DAM S3 Events</p>
                    </div>
                    <i class="icon-refresh text-primary icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ imageQueue | number }}</h1>
                      <p class="text-muted mb-0">Images pending analysis</p>
                    </div>
                    <i class="icon-picture text-primary icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ priorityImageQueue | number }}</h1>
                      <p class="text-muted mb-0">Priority Images pending analysis</p>
                    </div>
                    <i class="icon-picture text-primary icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ personQueue | number }}</h1>
                      <p class="text-muted mb-0">Pending Person Matches</p>
                    </div>
                    <i class="icon-people text-primary icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="d-flex justify-content-between card-statistics-item">
                    <div>
                      <h1>{{ personVerification | number }}</h1>
                      <p class="text-muted mb-0">FaceTree Verifications</p>
                    </div>
                    <i class="icon-vector text-primary icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="d-flex justify-content-between card-statistics-item">
                    <div>
                      <h1>{{ elvisQueue | number }}</h1>
                      <p class="text-muted mb-0">Pending DAM Updates</p>
                    </div>
                    <i class="icon-refresh text-primary icon-lg" (click)="fetchStats()"></i>
                  </div>
                </div>

              </div>
              <div *ngIf="showData" class="row">
                <div class="col-md-6 col-lg-3">
                  <div class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ s3Errors | number }}</h1>
                      <p class="text-muted mb-0">Failed S3 Events</p>
                    </div>
                    <i class="icon-energy text-danger icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="d-flex justify-content-between card-statistics-item">
                    <div>
                      <h1>{{ s3ElvisErrors | number }}</h1>
                      <p class="text-muted mb-0">Failed S3 Events DAM</p>
                    </div>
                    <i class="icon-refresh text-danger icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ imageErrors | number }}</h1>
                      <p class="text-muted mb-0">Images failed analysis</p>
                    </div>
                    <i class="icon-picture text-danger icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ priorityImageErrors | number }}</h1>
                      <p class="text-muted mb-0">Priority Images failed analysis</p>
                    </div>
                    <i class="icon-picture text-danger icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="d-flex justify-content-between border-right card-statistics-item">
                    <div>
                      <h1>{{ personErrors | number }}</h1>
                      <p class="text-muted mb-0">Failed Person Matches</p>
                    </div>
                    <i class="icon-people text-danger icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="d-flex justify-content-between card-statistics-item">
                    <div>
                      <h1>{{ personVerificationErrors | number }}</h1>
                      <p class="text-muted mb-0">Failed FaceTee Verifications</p>
                    </div>
                    <i class="icon-vector text-danger icon-lg"></i>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="d-flex justify-content-between card-statistics-item">
                    <div>
                      <h1>{{ elvisErrors | number }}</h1>
                      <p class="text-muted mb-0">Failed DAM Updates</p>
                    </div>
                    <i class="icon-refresh text-danger icon-lg"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row grid-margin">
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">S3 Events Processed</h4>
              <app-loader *ngIf="showS3ProcessedLoader"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="s3-processed" class="mt-auto"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">S3 Event Errors</h4>
              <app-loader *ngIf="showS3ErrorsHistoryLoader"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="s3-errors" class="mt-auto"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">S3 Events Queue Depth</h4>
              <app-loader *ngIf="showS3HistoryLoader"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="s3-depth" class="mt-auto"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row grid-margin">
          <div class="col-md-6 col-lg-4">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Elvis S3 Event Queue Processed</h4>
                <app-loader *ngIf="showElvisS3ProcessedLoader"></app-loader>
                <div class="w-75 mx-auto">
                  <canvas id="elvis-s3-processed" class="mt-auto"></canvas>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Elvis S3 Event Queue Errors</h4>
                <app-loader *ngIf="showElvisS3ErrorHistoryLoader"></app-loader>
                <div class="w-75 mx-auto">
                  <canvas id="elvis-s3-errors" class="mt-auto"></canvas>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Elvis S3 Event Queue Depth</h4>
                <app-loader *ngIf="showElvisS3HistoryLoader"></app-loader>
                <div class="w-75 mx-auto">
                  <canvas id="elvis-s3-depth" class="mt-auto"></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div class="row grid-margin">
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Images Processed</h4>
              <app-loader *ngIf="showImagesProcessedLoader"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="images-processed" class="mt-auto"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Image Errors</h4>
              <app-loader *ngIf="showImagesErrorHistoryLoader"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="images-errors" class="mt-auto"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Images Queue Depth</h4>
              <app-loader *ngIf="showImagesHistoryLoader"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="images-depth" class="mt-auto"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row grid-margin">
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Priority Images Processed</h4>
              <app-loader *ngIf="showPriorityImagesProcessedLoader"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="priority-images-processed" class="mt-auto"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Priority Image Errors</h4>
              <app-loader *ngIf="showPriorityImagesErrorHistoryLoader"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="priority-images-errors" class="mt-auto"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Priority Images Queue Depth</h4>
              <app-loader *ngIf="showPriorityImagesHistoryLoader"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="priority-images-depth" class="mt-auto"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row grid-margin">
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Elvis Updates Processed</h4>
              <app-loader *ngIf="showElvisProcessedLoader"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="elvis-processed" class="mt-auto"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Elvis Update Errors</h4>
              <app-loader *ngIf="showElvisErrorsHistoryLoader"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="elvis-errors" class="mt-auto"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Elvis Updates Queue Depth</h4>
              <app-loader *ngIf="showElvisHistoryLoader"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="elvis-depth" class="mt-auto"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row grid-margin">
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Person Matchings Processed</h4>
              <app-loader *ngIf="showPersonsProcessedLoader"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="persons-processed" class="mt-auto"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Person Matchings Errors</h4>
              <app-loader *ngIf="showPersonsErrorHistoryLoader"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="persons-errors" class="mt-auto"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Person Match Queue Depth</h4>
              <app-loader *ngIf="showPersonsHistoryLoader"></app-loader>
              <div class="w-75 mx-auto">
                <canvas id="persons-depth" class="mt-auto"></canvas>
              </div>
            </div>
          </div>
        </div> -->
      <!-- </div> -->
    </div>
  </div>
</div>
