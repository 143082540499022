import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

// Angular Drag and Drop File
//
// Add this directive to an element to turn it into a dropzone
// for drag and drop of files.
// Example:
//
// <div (appDropZone)="onDrop($event)"></div>
//
// Any files dropped onto the region are then
// returned as a Javascript array of file objects.
// Which in TypeScript is `Array<File>`
//

@Directive({
	selector: '[appDropZone]'
})
export class DropZoneDirective {

	// The directive emits a `fileDrop` event
	// with the list of files dropped on the element
	// as an JS array of `File` objects.
	@Output('appDropZone') fileDrop = new EventEmitter<Array<File>>();

	// Disable dropping on the body of the document.
	// This prevents the browser from loading the dropped files
	// using it's default behaviour if the user misses the drop zone.
	// Set this input to false if you want the browser default behaviour.
	@Input() preventBodyDrop = true;

	// The `drop-zone-active` class is applied to the host
	// element when a drag is currently over the target.
	@HostBinding('class.drop-zone-active')
	active = false;

	static async parseFiles(item: any): Promise<File[]> {
		return new Promise<File[]>(async (resolve, reject) => {
			let files = [];

			if (item.isFile) {
				item.file((file) => {
					files.push(file);
					resolve(files);
				});
			} else if (item.isDirectory) {
				// Get folder contents
				const dirReader = item.createReader();
				console.log('Reading folder contents from: ' + item.name);
				const elements = [];

				const prom = new Promise<void>((res, rej) => {
					const handle = async () => {
						dirReader.readEntries(async (entries) => {
							if (entries.length > 0) {
								console.log('Read ' + entries.length + ' entries from ' + item.name);
								for (let j = 0; j < entries.length; j++) {
									elements.push(entries[j]);
								}
								await handle();
							} else {
								res();
							}
						});
					};
					handle();
				});

				await prom;

				for (const ent of elements) {
					files = files.concat(await DropZoneDirective.parseFiles(ent));
				}
				console.log('Read ' + files.length + ' files from folder');
				resolve(files);
			} else {
				resolve(files);
			}
		});
	}

	@HostListener('drop', ['$event'])
	async onDrop(event: DragEvent) {
		event.preventDefault();
		this.active = false;

		const { dataTransfer } = event;

		if (dataTransfer.items) {
			let files = [];
			console.log(dataTransfer.items.length + ' items were dropped');
			const elements = [];
			for (let i = 0; i < dataTransfer.items.length; i++) {
				if (dataTransfer.items[i].kind === 'file') {
					const item = dataTransfer.items[i].webkitGetAsEntry();
					elements.push(item);
				}
			}
			console.log('Processing ' + elements.length + ' elements');
			let counter = 0;
			for (const item of elements) {
				// If dropped items aren't files, reject them
				console.log('Processing: #' + counter++ + ' - ' + item.name);
				files = files.concat(await DropZoneDirective.parseFiles(item));
			}
			console.log('Done with ' + elements.length + ' elements');
			dataTransfer.items.clear();
			this.fileDrop.emit(files);
		} else {
			const files = dataTransfer.files;
			dataTransfer.clearData();
			this.fileDrop.emit(Array.from(files));
		}
	}





	@HostListener('dragover', ['$event'])
	onDragOver(event: DragEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.active = true;
	}

	@HostListener('dragleave', ['$event'])
	onDragLeave(event: DragEvent) {
		this.active = false;
	}

	@HostListener('body:dragover', ['$event'])
	onBodyDragOver(event: DragEvent) {
		if (this.preventBodyDrop) {
			event.preventDefault();
			event.stopPropagation();
		}
	}
	@HostListener('body:drop', ['$event'])
	onBodyDrop(event: DragEvent) {
		if (this.preventBodyDrop) {
			event.preventDefault();
		}
	}
}
