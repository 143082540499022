import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Person } from 'src/app/model/Person';
import { IdentifiedFace } from 'src/app/model/IdentifiedFace';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessedImageService } from 'src/app/services/images.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { PersonSelectorComponent } from 'src/app/components/person-selector/person-selector.component';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-merge-persons',
  templateUrl: './merge-persons.component.html',
  styleUrls: ['./merge-persons.component.css']
})
export class MergePersonsComponent implements OnInit, AfterViewInit {

  title = 'Merge Persons';

  person1Id: number;

  person1: Person;
  person2: Person;

  person1ProfilePictureUrl: string;
  person2ProfilePictureUrl: string;

  showLoader = false;

  @ViewChild('selector') personSelector: PersonSelectorComponent;

  showMergeLoader = false;

  public allPersons: Person[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private imageService: ProcessedImageService,
    private notifications: NotificationsService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.showLoader = true;
    this.titleService.setTitle('Merge persons');
    this.person1Id = Number(this.route.snapshot.paramMap.get('personId'));
    const p2Id = this.route.snapshot.queryParamMap.get('with');
    if (p2Id) {
      this.imageService.getPerson(Number(p2Id)).subscribe((p) => {
        this.setSelectedPerson(p);
      });
    }
    this.title = 'Fetching image details for ' + this.person1Id;

    this.imageService.getPerson(this.person1Id).subscribe((p) => {
      this.person1 = p;
      this.person1ProfilePictureUrl = 'processed-images/faces/' + p.primaryFaceId + '.png';
      if (p.name) {
        this.title = 'Merging "' + p.name + '" with existing named person';
      } else {
        this.title = 'Merging unknown person with existing named person';
      }
      this.showLoader = false;
    });
    this.imageService.getAllPersons().subscribe(persons => {
      this.allPersons = [];
      for (const p of persons) {
        if (p.name) {
          this.allPersons.push(p);
        }
      }

      this.allPersons.sort((a, b) => a.name.localeCompare(b.name));
    });

  }

  ngAfterViewInit() {
    this.personSelector.selectedPerson.subscribe((p) => {
      this.setSelectedPerson(p);
    });

  }

  switchPersons() {
    const tp = this.person2;
    const tpp = this.person2ProfilePictureUrl;

    this.person2 = this.person1;
    this.person2ProfilePictureUrl = this.person1ProfilePictureUrl;

    this.person1 = tp;
    this.person1ProfilePictureUrl = tpp;
  }

  mergePersons() {
    this.showMergeLoader = true;
    this.imageService.mergePersons(this.person1, this.person2).subscribe((text => {
      this.notifications.showInfo('Merge Completed', text, 'success', 'Close', false).then(() => {
        this.router.navigate(['/persons/' + this.person2.id]);
      });
    }));
  }

  setSelectedPerson(p: Person) {
    if (p) {
      this.showLoader = true;
      console.log('Selected: ' + p.name);
      this.person2 = p;
      this.person2ProfilePictureUrl = 'processed-images/faces/' + p.primaryFaceId + '.png';
      if (p.facesCount) {
        this.showLoader = false;
      } else {
        this.imageService.getPerson(p.id).subscribe(pe => {
          this.person2 = pe;
          this.showLoader = false;
        });
      }
    }
  }

}
