import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessedImageService } from 'src/app/services/images.service';
import { Person } from 'src/app/model/Person';
import { TitleService } from 'src/app/services/title.service';
import { Nationality } from 'src/app/model/Nationality';

@Component({
  selector: 'app-double-named',
  templateUrl: './double-named.component.html',
  styleUrls: ['./double-named.component.css']
})
export class DoubleNamedComponent implements OnInit {

  showLoader = true;

  allPersons: Person[][];
  persons: Person[][];

  nationalities: Nationality[];
  limit = 25;
  limitSize = 25;

  selectedNationality;


  constructor(
    private router: Router,
    private imagesService: ProcessedImageService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Double Named Persons');
    const t = localStorage.getItem('state-view-persons');
    if (t) {
      const temp = JSON.parse(t);
      this.selectedNationality = temp.selectedNationality;
    }
    this.imagesService.getNationalities().subscribe(n => this.nationalities = n);
    this.imagesService.getDoubleNamedPersons().subscribe((list) => {
      this.allPersons = [];
      let c = [];
      let last = '';
      for (const p of list) {
        if (p.name !== last && c.length > 0) {
          c.sort(this.sorter);
          this.allPersons.push(c);
          c = [];
        }
        c.push(p);
        last = p.name;
      }

      if (c.length > 0) {
        c.sort(this.sorter);
        this.allPersons.push(c);
      }
      this.filterPersons();
      this.showLoader = false;
    });
  }

  sorter(p1: Person, p2: Person): number {
    if (p1.namedByAI && !p2.namedByAI) {
      return 1;
    } else if (!p1.namedByAI && p2.namedByAI) {
      return -1;
    } else {
      return p2.facesCount - p1.facesCount;
    }
  }

  filterPersons() {
    this.persons = [];
    for (const p of this.allPersons) {
      // if (!p[0].name || p[0].name.trim().length === 0) {
      //   continue;
      // }
      if (!this.selectedNationality) {
        this.persons.push(p);
      } else {
        if (p[0].nationality === this.selectedNationality) {
          this.persons.push(p);
        } else if (!p[0].nationality) {
          for (const pp of p) {
            if (pp.nationality === this.selectedNationality) {
              this.persons.push(p);
              break;
            }
          }
        }
      }

      if (this.persons.length === this.limit) {
        break;
      }
    }
  }

  setNationality(n: string) {
    this.selectedNationality = n;
    this.filterPersons();
  }

  loadMore() {
    this.limit += this.limitSize;
    this.filterPersons();
  }

  setLimit(l: number) {
    if (l === -1) {
      this.limit = this.allPersons.length + 10;
    }
    this.limit = l;
    this.limitSize = l;
    this.filterPersons();
  }

  loadAll() {
    this.limit = this.allPersons.length + 10;
    this.selectedNationality = undefined;
    this.filterPersons();
  }

  quickMerge(source: Person, target: Person) {
    source.showLoader = true;
    this.imagesService.quickMerge(source, target).subscribe(() => {
      source.showLoader = false;
      source.status = 'PENDING_MERGE';
    });
  }

}
