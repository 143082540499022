import { Component, OnInit, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessedImageService } from 'src/app/services/images.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { Person } from 'src/app/model/Person';
// import { IdentifiedFace } from 'src/app/model/IdentifiedFace';
import { FaceTreeComponent } from 'src/app/components/face-tree/face-tree.component';
import { FaceRelation } from 'src/app/model/FaceRelation';
import { TitleService } from 'src/app/services/title.service';
// import { FaceGridComponent } from 'src/app/components/face-grid/face-grid.component';

@Component({
  selector: 'app-show-person-face-tree',
  templateUrl: './show-person-face-tree.component.html',
  styleUrls: ['./show-person-face-tree.component.css']
})
export class ShowPersonFaceTreeComponent implements OnInit {

  title = 'Loading details...';
  showLoader = true;

  showTree = true;
  showGrid = false;

  personId: number;
  person: Person;

  allfaces: FaceRelation[];

  @ViewChild('faceTree') faceTree: FaceTreeComponent;
  // @ViewChild('faceGrid') faceGrid: FaceGridComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private imageService: ProcessedImageService,
    private changeDetector: ChangeDetectorRef,
    private notifications: NotificationsService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    // this.getPerson();
    this.route.params.subscribe((p) => this.getPerson());
  }

  getPerson() {
    this.showLoader = true;
    this.personId = Number(this.route.snapshot.paramMap.get('personId'));
    this.titleService.setTitle('Face tree #' + this.personId);
    this.title = 'Fetching image details for Person #' + this.personId;

    this.imageService.getPerson(this.personId).subscribe((p) => {
      this.person = p;
      if (p.name) {
        this.title = 'Loading faces where "' + p.name + '" has been identified';
      } else {
        this.title = 'Loading faces for Person #' + p.id + ' (Not yet named)';
      }
      this.loadFaces();
    });
  }

  // switchViewType(showTree: boolean) {
  //   this.showGrid = !showTree;
  //   this.showTree = showTree;
  //   this.changeDetector.detectChanges();
  //   this.updateShown();
  // }

  loadFaces() {
    this.imageService.fetchFaceTreeData(this.personId).subscribe((d) => {
      this.allfaces = d;
      this.updateShown();
    });
  }

  expandAll() {
    // if (this.showGrid) {
    //   this.faceGrid.expandAll();
    // } else if (this.showTree) {
      this.faceTree.expandAll();
    // }
  }

  collapseAll() {
    // if (this.showGrid) {
    //   this.faceGrid.collapseAll();
    // } else if (this.showTree) {
      this.faceTree.collapseAll();
    // }
  }

  updateShown() {
    const relations = this.allfaces;

    // if (this.showGrid) {
    //   this.faceGrid.relations = relations;
    //   if (this.person.name) {
    //     this.title = 'Showing match grid for ' + this.allfaces.length + ' faces for ' + this.person.name;
    //   } else {
    //     this.title = 'Showing match grid for ' + this.allfaces.length + ' faces for Person #' + this.person.id + ' (Not yet named)';
    //   }
    //   this.showLoader = false;
    // } else {
    this.faceTree.relations = relations;

    if (this.person.name) {
      this.title = 'Showing match tree for ' + this.allfaces.length + ' faces for ' + this.person.name + ' (#' + this.person.id + ')';
    } else {
      this.title = 'Showing match tree for ' + this.allfaces.length + ' faces for Person #' + this.person.id + ' (Not yet named)';
    }
    // }

    this.showLoader = false;
  }


}
