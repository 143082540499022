import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProcessedImage } from 'src/app/model/ProcessedImage';
import { Person } from 'src/app/model/Person';
import { IdentifiedFace } from 'src/app/model/IdentifiedFace';
import { Nationality } from '../model/Nationality';
import { AuthenticationService } from './authentication.service';
import { Statistics } from '../model/Statistics';
import { FaceRelation } from '../model/FaceRelation';
import { SearchFacesRequest } from '../model/SearchFacesRequest';
import { SearchFacesResponse } from '../model/SearchFacesResponse';
import { SearchPersonsResponse } from '../model/SearchPersonsResponse';
import { FailedImage } from '../model/FailedImage';
import { KnownName } from '../model/KnownName';
import { ImageEventLogEntry } from '../model/image-event-log-entry';
import { FaceTreeRoot } from '../model/FaceTreeRoot';

@Injectable({
    providedIn: 'root'
  })
export class ProcessedImageService {

    public url = 'v1';

    constructor(public http: HttpClient,
                private authenticationService: AuthenticationService) { }

    clearDatabase(): Observable<string[]> {
        return this.http.get<string[]>(this.url + '/settings/clear-all-data?v=' + new Date().getTime());
    }

    clearAllPersons(): Observable<string[]> {
        return this.http.get<string[]>(this.url + '/settings/clear-persons?v=' + new Date().getTime());
    }

    clearAIVerifications(personId?: number): Observable<string[]> {
        return this.http.get<string[]>(this.url + '/settings/clear-ai-verifications?v=' + new Date().getTime() + ((personId) ? '&personId=' + personId : ''));
    }

    clearAllVerifications(personId?: number): Observable<string[]> {
        return this.http.get<string[]>(this.url + '/settings/clear-all-verifications?v=' + new Date().getTime() + ((personId) ? '&personId=' + personId : ''));
    }

    clearAllUnnamedPersons(): Observable<string[]> {
        return this.http.get<string[]>(this.url + '/settings/clear-unnamed-persons?v=' + new Date().getTime());
    }

    startPendingQueue(reprocess: boolean): Observable<string> {
        return this.http.get<string>(this.url + '/persons/enqueue-pending-matches?reprocess=' + reprocess + '&v=' + new Date().getTime());
    }

    searchFaces(req: SearchFacesRequest): Observable<SearchFacesResponse> {
        if (req.rawSearch) {
            // base64 encode SQL to avoid WAF SQL injection check
            req.rawSearch = btoa(unescape(encodeURIComponent(req.rawSearch)));
        }

        return this.http.post<SearchFacesResponse>(this.url + '/faces/search', req);
    }

    searchPersons(condition: string, limit: number, offset: number): Observable<SearchPersonsResponse> {
        // base64 encode SQL to avoid WAF SQL injection check
        const req = {
            condition: btoa(unescape(encodeURIComponent(condition))),
            limit: limit,
            offset: offset
        };

        return this.http.post<SearchPersonsResponse>(this.url + '/persons/search', req);
    }

    getPersonFaces(id: number, showVerified: boolean, includeRelations: boolean,  order: string, offset: number, limit: number): Observable<IdentifiedFace[]> {
        return this.http.get<IdentifiedFace[]>(this.url + '/persons/' + id + '/faces?offset=' + offset + '&limit=' + limit + '&includeVerified='
                                             + showVerified + '&includeRelations=' + includeRelations + '&order=' + order + '&v=' + new Date().getTime());
    }

    getLatestVerifications(limit: number, offset: number, type: 'ai' | 'user' | 'all'): Observable<IdentifiedFace[]> {
        return this.http.get<IdentifiedFace[]>(this.url + '/persons/verifications?limit=' + limit + '&offset=' + offset + '&type=' + type + '&v=' + new Date().getTime());
    }

    getPersons(region: string, order: 'face_count' | 'created' | 'name', includeNamed: boolean, includeAINamed: boolean, offset: number, limit: number): Observable<Person[]> {
        return this.http.get<Person[]>(this.url + '/persons/list/' + region + '?order=' + order +
                    '&includeNamed=' + includeNamed + '&includeAINamed=' + includeAINamed + '&limit=' + limit + '&offset=' + offset + '&v=' + new Date().getTime());
    }

    getAllPersons(): Observable<Person[]> {
        return this.http.get<Person[]>(this.url + '/allpersons?v=' + new Date().getTime());
    }

    getDoubleNamedPersons(): Observable<Person[]> {
        return this.http.get<Person[]>(this.url + '/persons/double-named/list?v=' + new Date().getTime());
    }

    requestExternalMatching(personId: number): Observable<string> {
        return this.http.get<string>(this.url + '/persons/' + personId + '/request-external-match?v=' + new Date().getTime());
    }

    getKnownNames(): Observable<KnownName[]> {
        return this.http.get<KnownName[]>(this.url + '/persons/known-names/list?v=' + new Date().getTime());
    }

    getPerson(id: number): Observable<Person> {
        return this.http.get<Person>(this.url + '/persons/' + id + '?v=' + new Date().getTime());
    }

    getFace(id: number): Observable<IdentifiedFace> {
        return this.http.get<IdentifiedFace>(this.url + '/faces/' + id + '?v=' + new Date().getTime());
    }

    getFaceBranch(id: number): Observable<FaceRelation[]> {
        return this.http.get<FaceRelation[]>(this.url + '/faces/' + id + '/branch?v=' + new Date().getTime());
    }

    searchFaceMatches(id: number): Observable<FaceRelation[]> {
        return this.http.get<FaceRelation[]>(this.url + '/faces/' + id + '/allmatches?v=' + new Date().getTime());
    }

    updatePerson(person: Person): Observable<string> {
        console.log('Updating person');
        return this.http.post<string>(this.url + '/persons/' + person.id, person);
    }

    deletePerson(id: number) {
        return this.http.delete(this.url + '/persons/' + id);
    }

    ignorePerson(id: number) {
        return this.http.put(this.url + '/persons/' + id + '/ignore', {
            name: this.authenticationService.currentUserValue.fullname
        });
    }

    unignorePerson(id: number) {
        return this.http.put(this.url + '/persons/' + id + '/unignore', {
            name: this.authenticationService.currentUserValue.fullname
        });
    }

    restructureFaceTreeData(id: number) {
        return this.http.post(this.url + '/persons/' + id + '/facetree-consistency', {
            name: this.authenticationService.currentUserValue.fullname
        });
    }

    getLatestImages(reportName: string, order: string, orderDirection: 'asc' | 'desc', region: string, limit: number, offset: number): Observable<ProcessedImage[]> {
        return this.http.get<ProcessedImage[]>(this.url + '/images/reports/' + reportName + '?' +
                            'order=' + order + '&orderDirection=' + orderDirection +
                            '&region=' + region +
                 '&limit=' + limit + '&offset=' + offset + '&v=' + new Date().getTime());
    }

    listFailedImages(limit: number, offset: number): Observable<FailedImage[]> {
        return this.http.get<FailedImage[]>(this.url + '/images/failed?limit=' + limit + '&offset=' + offset + '&v=' + new Date().getTime());
    }

    getImage(imageId: string): Observable<ProcessedImage> {
        console.log('Loading image ' + imageId);
        return this.http.get<ProcessedImage>(this.url + '/images/' + imageId + '?v=' + new Date().getTime());
    }

    reprocessImage(imageId: string): Observable<ProcessedImage> {
        console.log('Reprocessing image ' + imageId);
        return this.http.get<ProcessedImage>(this.url + '/images/' + imageId + '/reprocess?v=' + new Date().getTime());
    }

    setProfileImage(personId: number, faceId: number): Observable<void> {
        console.log('Setting profile image for person ' + personId + ' to ' + faceId);
        return this.http.post<void>(this.url + '/persons/' + personId + '/profile-image', { faceId: faceId });
    }

    getNationalities(): Observable<Nationality[]> {
        return Observable.create(observer => {
            observer.next([
                { name: 'Denmark', code: 'dk', backgroundColor: '#E31836'},
                { name: 'Finland', code: 'fi', backgroundColor: '#FFFFFF'},
                { name: 'Norway', code: 'no', backgroundColor: '#002868'},
                { name: 'Sweden', code: 'se', backgroundColor: '#FECC00'},
                { name: 'All', code: 'metatagger', backgroundColor: '#00CC00'},
            ]);
        });
    }

    reprocessFace(face: IdentifiedFace): Observable<IdentifiedFace> {
        return this.http.get<IdentifiedFace>(this.url + '/images/' + face.image.id + '/' + face.id + '/reprocess?v=' + new Date().getTime());
    }

    deleteFace(face: IdentifiedFace): Observable<void> {
        return this.http.delete<void>(this.url + '/images/' + face.image.id + '/' + face.id + '?v=' + new Date().getTime());
    }

    splitFaceToPerson(face: IdentifiedFace, personId: number): Observable<IdentifiedFace> {
        return this.http.post<IdentifiedFace>(this.url + '/images/' + face.image.id + '/' + face.id + '/assign', {
            personId: personId
        });
    }

    splitFaceBranchToPerson(face: IdentifiedFace, personId: number): Observable<IdentifiedFace> {
        return this.http.post<IdentifiedFace>(this.url + '/faces/' + face.id + '/assign-tree', {
            personId: personId
        });
    }

    verifyFace(face: IdentifiedFace): Observable<IdentifiedFace> {
        return this.http.post<IdentifiedFace>(this.url + '/images/' + face.image.id + '/' + face.id + '/verify', {
            name: this.authenticationService.currentUserValue.fullname
        });
    }

    unverifyFace(face: IdentifiedFace): Observable<IdentifiedFace> {
        return this.http.post<IdentifiedFace>(this.url + '/images/' + face.image.id + '/' + face.id + '/unverify', {
            name: this.authenticationService.currentUserValue.fullname
        });
    }

    markImage(image: ProcessedImage): Observable<ProcessedImage> {
        return this.http.put<ProcessedImage>(this.url + '/images/' + image.id + '/mark', {
            name: this.authenticationService.currentUserValue.fullname
        });
    }

    unmarkImage(image: ProcessedImage): Observable<ProcessedImage> {
        return this.http.put<ProcessedImage>(this.url + '/images/' + image.id + '/mark', {
            name: this.authenticationService.currentUserValue.fullname,
            unmark: true
        });
    }

    mergePersons(source: Person, target: Person): Observable<string> {
        return this.http.post<string>(this.url + '/persons/merge', {
            source: source.id,
            target: target.id
        });
    }

    getPendingVerifications(region: Nationality, order: string, limit: number, offset: number): Observable<IdentifiedFace[]> {
        return this.http.post<IdentifiedFace[]>(this.url + '/persons/verification-queue/search', {
            region: region.code,
            limit: limit,
            order: order,
            offset: offset
        });
    }

    autoUpdateVerifications(): Observable<Statistics> {
        return this.http.post<Statistics>(this.url + '/persons/verification-queue/update', {});
    }

    dryRunAutoUpdates(): Observable<Statistics> {
        return this.http.get<Statistics>(this.url + '/persons/verification-queue/update/dryrun?v=' + new Date().getTime());
    }

    fetchFaceTreeData(personId: number): Observable<FaceRelation[]> {
        return this.http.get<FaceRelation[]>(this.url + '/persons/' + personId + '/facetree');
    }

    startPersonVerifications(personId: number): Observable<string> {
        return this.http.get<string>(this.url + '/verifications/enqueue-person?personId=' + personId + '&v=' + Date.now());
    }

    startReprocessOfAll(personId: number, reprocess: boolean): Observable<string> {
        return this.http.get<string>(this.url + '/persons/enqueue-pending-matches?reprocess=' + reprocess + '&personId=' + personId + '&v=' + Date.now());
    }

    searchPotentialDuplicates(personId: number): Observable<Person[]> {
        return this.http.get<Person[]>(this.url + '/persons/' + personId + '/potential-duplicates?v=' + Date.now());
    }

    searchFaceRoots(personId: number): Observable<FaceTreeRoot[]> {
        return this.http.get<FaceTreeRoot[]>(this.url + '/persons/' + personId + '/facetree-roots?v=' + Date.now());
    }

    fixFaceRoots(personId: number): Observable<FaceTreeRoot[]> {
        return this.http.post<FaceTreeRoot[]>(this.url + '/persons/' + personId + '/facetree-roots', {});
    }

    searchRelatedFaces(personId: number, comparePersonId: number): Observable<FaceRelation[]> {
        return this.http.post<FaceRelation[]>(this.url + '/persons/compare', {
            person1: personId,
            person2: comparePersonId
        });
    }

    quickMerge(source: Person, target: Person): Observable<void> {
        return this.http.post<void>(this.url + '/persons/quick-merge', {
            source: source.id,
            target: target.id
        });
    }

    fetchImageEventLog(imageId: number): Observable<ImageEventLogEntry[]> {
        return this.http.get<ImageEventLogEntry[]>(this.url + '/images/' + imageId + '/events?v=' + Date.now());
    }

    forcePushImagesToElvis(includeAlreadyPushed: boolean, includeNoFace: boolean, uploadedSince: Date, uploadedBefore: Date): Observable<void> {
        return this.http.post<void>(this.url + '/images/force-push', {
            includeAlreadyPushed, includeNoFace, uploadedSince, uploadedBefore
        });
    }

    fetchPushJobStatus(): Observable<{enabled: boolean}> {
        return this.http.get<{enabled: boolean}>(this.url + '/settings/force-push-jobs-status?v=' + Date.now());
    }

    changePushJobStatus(value: boolean): Observable<string> {
        return this.http.post<string>(this.url + '/settings/force-push-jobs-status', {
            enabled: value
        });
    }
}
