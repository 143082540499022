import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ListAllComponent } from '../components/list-all/list-all.component';
import { EditComponent } from '../components/edit/edit.component';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css']
})
export class ManageComponent implements AfterViewInit {

  @ViewChild('editor') editor: EditComponent;
  @ViewChild('list') list: ListAllComponent;


  constructor(
    private titleService: TitleService
  ) { }



  ngAfterViewInit() {
    this.list.editor = this.editor;
    this.editor.saving.subscribe(() => this.list.refresh());

    this.titleService.setTitle('Bug Reports');
  }

}
