import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { MetataggerUser } from '../model/MetataggerUser';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  public url = 'v1';

  constructor(public http: HttpClient,
              private authenticationService: AuthenticationService) { }

  listUsers(): Observable<MetataggerUser[]> {
      return this.http.get<MetataggerUser[]>(this.url + '/users?v=' + new Date().getTime());
  }

}
