import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessedImageService } from 'src/app/services/images.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { FaceTreeComponent } from 'src/app/components/face-tree/face-tree.component';
import { FaceRelation } from 'src/app/model/FaceRelation';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-show-face-branch',
  templateUrl: './show-face-branch.component.html',
  styleUrls: ['./show-face-branch.component.css']
})
export class ShowFaceBranchComponent implements OnInit {

  title = 'Loading details...';
  showLoader = true;

  faceId: number;
  relations: FaceRelation[];

  @ViewChild('faceTree') faceTree: FaceTreeComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private imageService: ProcessedImageService,
    private notifications: NotificationsService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.route.params.subscribe((f) => this.getFaceDetails());
  }

  getFaceDetails() {
    this.showLoader = true;
    this.faceId = Number(this.route.snapshot.paramMap.get('faceId'));
    this.title = 'Fetching image details for Face #' + this.faceId;
    this.titleService.setTitle('Face branch #' + this.faceId);
    this.imageService.getFaceBranch(this.faceId).subscribe((f) => {
      this.relations = f;
      this.updateShown();
    });
  }

  updateShown() {
    this.faceTree.highlightFaceId = this.faceId;
    this.faceTree.relations = this.relations.filter(f => (f != null));
    this.title = 'Showing face tree for Face #' + this.faceId;
    this.showLoader = false;
  }


}
