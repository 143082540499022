import { ChangeDetectorRef, Component } from '@angular/core';
import { HttpEvent, HttpEventType, HttpHeaders, HttpClient } from '@angular/common/http';
import { Nationality } from 'src/app/model/Nationality';
import { ProcessedImageService } from 'src/app/services/images.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import * as sha256 from 'sha256';
import { TitleService } from 'src/app/services/title.service';

@Component({
    selector: 'app-manual-upload',
    templateUrl: './manual-upload.component.html',
    styleUrls: ['./manual-upload.component.css']
})
export class ManualUploadComponent {

    public files = [];
    public progressValue = 0;
    public completed = 0;
    public failedCount = 0;

    regions: Nationality[];
    selectedRegion = 'DK';

    constructor(
        private http: HttpClient,
        private imageService: ProcessedImageService,
        public authService: AuthenticationService,
        private titleService: TitleService,
        private changeDetector: ChangeDetectorRef
    ) {
        this.imageService.getNationalities().subscribe(n => this.regions = n);
        this.titleService.setTitle('Settings: Upload');
    }


    allowDrop(ev) {
        ev.preventDefault();
    }

    setRegion(v: string) {
        if (!v) {
            this.selectedRegion = 'unknown';
        } else {
            this.selectedRegion = v.toUpperCase();
        }
    }

    drag(ev) {
        ev.dataTransfer.setData('text', ev.target.id);
    }

    async onDrop(droppedFiles: Array<File>) {
        console.log({ files: droppedFiles });

        droppedFiles = [...droppedFiles];

        console.log('Handling ' + droppedFiles.length + ' files');

        const seenNames = [];

        for (const file of droppedFiles) {
            let filename = await this.sanitize(file.name);
            let tn = filename;
            let count = 0;
            while (seenNames.indexOf(tn) > -1) {
                tn = 'v' + ++count + '_' + filename;
            }
            seenNames.push(tn);
            filename = tn;
            const t = {
                name: filename,
                origname: file.name,
                type: file.type,
                lastDate: new Date(file.lastModified),
                size: file.size,
                uploadDate: new Date(),
                status: 'Uploading...',
                uploadedPercentage: 0.0
            };
            this.files.push(t);
            const headers = new HttpHeaders({
                'x-amz-acl': 'bucket-owner-full-control'
            });

            const me = this;
            me.progressValue = (((me.completed + me.failedCount) / me.files.length) * 100);
            this.http.put('/uploads/' + this.selectedRegion + '/' + filename, file, { headers: headers, responseType: 'blob', reportProgress: true, observe: 'events' })
                .subscribe((event: HttpEvent<any>) => {
                    switch (event.type) {
                        case HttpEventType.Sent:
                            break;
                        case HttpEventType.Response:
                            t.status = 'Uploaded';
                            t.uploadedPercentage = 100;
                            me.completed++;
                            me.progressValue = (((me.completed + me.failedCount) / me.files.length) * 100);
                            break;
                        case 1: {
                            // tslint:disable-next-line:no-string-literal
                            if (Math.round(t.uploadedPercentage) !== Math.round(event['loaded'] / event['total'] * 100)) {
                                // tslint:disable-next-line:no-string-literal
                                t.uploadedPercentage = (event['loaded'] / event['total'] * 100);
                                if (me.files.length === 1) {
                                    me.progressValue = t.uploadedPercentage;
                                }
                            }
                            break;
                        }
                    }
                    this.changeDetector.detectChanges();
                },
                    error => {
                        console.log(error);
                        t.status = error.message;
                        me.failedCount++;
                    });
        }

    }

    async sanitize(name: string): Promise<string> {
        return sha256(name) + name.substring(name.lastIndexOf('.'));
    }

    public clearTable() {
        this.files = [];
        this.completed = 0;
        this.failedCount = 0;
        this.progressValue = 0;
    }

    public fileOver(event) {
        console.log(event);
    }

    public fileLeave(event) {
        console.log(event);
    }

}
