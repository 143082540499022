import * as moment from 'moment-timezone';



export class S3CrawlerParams {

    bucket: string;
    prefix: string;
    bucketRegion: string;
    reprocessKnown: boolean;

    maxFiles: number;
    startIndex: number;

    continuationToken?: string;

    started?: moment.Moment;
    startedBy?: string;
}
