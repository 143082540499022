import { Component, OnInit } from '@angular/core';
import { Person } from 'src/app/model/Person';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessedImageService } from 'src/app/services/images.service';
import { Nationality } from 'src/app/model/Nationality';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ViewPersonsState } from 'src/app/state/ViewPersonsState';
import { TitleService } from 'src/app/services/title.service';
import { HistoryService } from 'src/app/services/history.service';

@Component({
  selector: 'app-view-persons',
  templateUrl: './view-persons.component.html',
  styleUrls: ['./view-persons.component.css']
})
export class ViewPersonsComponent implements OnInit {

  public allpersons: Person[];
  public persons: Person[];
  public title: string;
  public searchText: string;
  public page: any;

  public showLoader = true;

  public state: ViewPersonsState;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private imagesService: ProcessedImageService,
              private titleService: TitleService) { }

  ngOnInit() {
    const t = localStorage.getItem('state-view-persons');
    this.titleService.setTitle('Persons');

    if (t) {
      this.state = JSON.parse(t);
    } else {
      this.state = new ViewPersonsState();
    }

    this.imagesService.getNationalities().subscribe(n => this.state.nationalities = n);

    for (const n of this.authenticationService.currentUserValue.groups) {
      if (n.length === 2) {
        this.state.selectedNationality = n;
        break;
      }
    }
    if (!this.state.selectedNationality) {
      this.state.selectedNationality = 'dk';
    }

    this.getPersons();
  }

  saveState() {
    localStorage.setItem('state-view-persons', JSON.stringify(this.state));
  }

  getPersons() {
    this.state.offset = 0;
    this.persons = [];
    this.allpersons = [];
    this.updateShown();
    this.showLoader = true;
    this.imagesService.getPersons(this.state.selectedNationality, this.state.order, this.state.showIdentified, this.state.showAIIdentified, this.state.offset, this.state.limit).subscribe((ps) => {
      this.title = `Showing ${ps.length} persons`;
      this.allpersons = ps;
      this.updateShown();
      this.showLoader = false;
    }, (err) => {
      this.title = 'Fetching of persons failed';
      if (err.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        this.title += ' : Client-side error: ' + err.error.message;
        console.error('A Client-side network error occurred:', err.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        // tslint:disable-next-line:triple-equals
        if (err.status != '0') {
          this.title += ' : Server said: [' + err.status + '] ' + err.status;
        } else {
          this.title += ' Server did respond in time. Please try again.';
        }
        console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      }
    }
    );
  }

  loadMorePersons() {
    this.state.offset += this.state.limit;
    this.saveState();
    this.showLoader = true;
    this.imagesService.getPersons(this.state.selectedNationality, this.state.order, this.state.showIdentified, this.state.showAIIdentified, this.state.offset, this.state.limit).subscribe(
      (moreImg) => {
        this.allpersons = this.allpersons.concat(moreImg);
        this.updateShown();
        this.showLoader = false;
      }
    );
  }

  setNationality(n: string) {
    this.state.selectedNationality = n;
    this.getPersons();
  }

  toggleShowIdentified() {
    this.state.showIdentified = !this.state.showIdentified;
    this.getPersons();
  }

  setOrder(order: 'face_count' | 'created' | 'name') {
    this.state.order = order;
    this.getPersons();
  }

  updateShown() {
    this.persons = [];
    if (!this.allpersons) {
      return;
    }
    // for (const p of this.allpersons) {
    //   if (this.state.showIdentified || !p.name) {
    //     if (!this.state.selectedNationality || (this.state.selectedNationality === 'all' || this.state.selectedNationality === p.nationality)) {
    //       this.persons.push(p);
    //     }
    //   }
    // }

    this.persons = this.allpersons;

    if (this.state.order === 'face_count') {
      // this.persons.sort((a, b) => b.facesCount - a.facesCount);
      this.state.orderText = 'Number of faces';
    } else if (this.state.order === 'created') {
      // this.persons.sort((a, b) => new Date(b.lastImageSeen).getTime() - new Date(a.lastImageSeen).getTime());
      this.state.orderText = 'Last seen image';
    } else if (this.state.order === 'name') {
      // this.persons.sort((a, b) => {
      //   if (!a.name && !b.name) {
      //     return 0;
      //   } else if (a.name && !b.name) {
      //     return -1;
      //   } else if (!a.name && b.name) {
      //     return 1;
      //   } else {
      //     return a.name.localeCompare(b.name);
      //   }
      // });
      this.state.orderText = 'Name';
    }
    this.saveState();
  }

  deletePerson(person: Person) {
    this.imagesService.deletePerson(person.id).subscribe(p => {
      const index = this.persons.indexOf(person);
      this.persons.splice(index, 1);
    });
  }

  public showPerson(person) {
    this.router.navigate(['persons/' + person.id]);
  }


}
