import { Component, OnInit } from '@angular/core';
import { ConcurrencySettings } from 'src/app/model/ConcurrencySettings';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SettingsService } from 'src/app/services/settings.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-processing',
  templateUrl: './processing.component.html',
  styleUrls: ['./processing.component.css']
})
export class ProcessingComponent implements OnInit {

  concurrencySettings: ConcurrencySettings;

  saveConcurrencyInProgress = false;
  showLoader = false;
  showConcurrencyLoader = false;

  s3EventsEnabled: boolean;
  s3EventsShowLoader = true;
  s3EventsStatus = 'Fetching value from server';

  faceDetectionEnabled: boolean;
  faceDetectionShowLoader = true;
  faceDetectionStatus = 'Fetching value from server';

  priorityFaceDetectionEnabled: boolean;
  priorityFaceDetectionShowLoader = true;
  priorityFaceDetectionStatus = 'Fetching value from server';

  videoProcessingEnabled: boolean;
  videoProcessingShowLoader = true;
  videoProcessingStatus = 'Fetching value from server';

  personMatcherEnabled: boolean;
  personMatcherShowLoader = true;
  personMatcherStatus = 'Fetching value from server';

  elvisUpdatesEnabled: boolean;
  elvisUpdatesShowLoader = true;
  elvisUpdatesStatus = 'Fetching value from server';

  elvisErrorsEnabled: boolean;
  elvisErrorsShowLoader = true;
  elvisErrorsStatus = 'Fetching value from server';



  constructor(
    public authService: AuthenticationService,
    private settingsService: SettingsService,
    private notificationService: NotificationsService,
    private titleService: TitleService
    ) { }

  ngOnInit() {
    this.showLoader = true;
    this.showConcurrencyLoader = true;
    this.titleService.setTitle('Settings: Processing');
    this.settingsService.getConcurrencySettings().subscribe(s => {
      this.concurrencySettings = s;
      this.showConcurrencyLoader = false;
    });

    this.getQueueStates();

  }

  getQueueStates() {
    this.s3EventsShowLoader = true;
    this.faceDetectionShowLoader = true;
    this.priorityFaceDetectionShowLoader = true;
    this.videoProcessingShowLoader = true;
    this.elvisUpdatesShowLoader = true;
    this.personMatcherShowLoader = true;

    this.settingsService.getQueueStatus('s3-events').subscribe((s) => {
      console.log('S3: ' + s);
      this.s3EventsStatus = s;
      this.s3EventsEnabled = s.startsWith('Enab');
      this.s3EventsShowLoader = false;
    });
    this.settingsService.getQueueStatus('image-processing').subscribe((s) => {
      console.log('Images: ' + s);
      this.faceDetectionStatus = s;
      this.faceDetectionEnabled = s.startsWith('Enab');
      this.faceDetectionShowLoader = false;
    });
    this.settingsService.getQueueStatus('priority-image-processing').subscribe((s) => {
      console.log('Priority Images: ' + s);
      this.priorityFaceDetectionStatus = s;
      this.priorityFaceDetectionEnabled = s.startsWith('Enab');
      this.priorityFaceDetectionShowLoader = false;
    });
    this.settingsService.getQueueStatus('video-processing').subscribe((s) => {
      console.log('Video: ' + s);
      this.videoProcessingStatus = s;
      this.videoProcessingEnabled = s.startsWith('Enab');
      this.videoProcessingShowLoader = false;
    });
    this.settingsService.getQueueStatus('elvis-updates').subscribe((s) => {
      console.log('Elvis: ' + s);
      this.elvisUpdatesStatus = s;
      this.elvisUpdatesEnabled = s.startsWith('Enab');
      this.elvisUpdatesShowLoader = false;
    });
    this.settingsService.getQueueStatus('elvis-errors').subscribe((s) => {
      console.log('Elvis Errors: ' + s);
      this.elvisErrorsStatus = s;
      this.elvisErrorsEnabled = s.startsWith('Enab');
      this.elvisErrorsShowLoader = false;
    });
    this.settingsService.getQueueStatus('persons').subscribe((s) => {
      console.log('Persons: ' + s);
      this.personMatcherStatus = s;
      this.personMatcherEnabled = s.startsWith('Enab');
      this.personMatcherShowLoader = false;
    });
  }


 public saveConcurrencySettings() {
    this.saveConcurrencyInProgress = true;
    this.settingsService.updateConcurrencySettings(this.concurrencySettings).subscribe(s => {
      this.concurrencySettings = s;
      this.saveConcurrencyInProgress = false;
      this.notificationService.showInfo('Concurrency settings was updated', '', 'success', 'Close', false);
    });
  }



  public updateQueueStatus(key: string, prefix: string) {
    this[prefix + 'ShowLoader'] = true;
    this.settingsService.updateQueueStatus(key, this[prefix + 'Enabled']).subscribe((s) => {
      this[prefix + 'Status'] = s;
      this[prefix + 'ShowLoader'] = false;
    });
  }

}
