import { Injectable } from '@angular/core';
import { ProcessedImageService } from './images.service';
import { IdentifiedFace } from '../model/IdentifiedFace';
import { NotificationsService } from './notifications.service';
import { Person } from '../model/Person';
import { FaceLister } from '../interfaces/FaceLister';

@Injectable({
  providedIn: 'root'
})
export class FaceProcessorService {

  constructor(
    private imageService: ProcessedImageService,
    private notifications: NotificationsService
  ) { }


  reprocessFace(face: IdentifiedFace, listener: FaceLister) {
    face.showLoader = true;
    this.imageService.reprocessFace(face).subscribe((updatedFace) => {
      if (face.person.id === updatedFace.person.id) {
        // still same person
        face.personVerified = updatedFace.personVerified;
        face.personVerifiedBy = updatedFace.personVerifiedBy;
        face.personVerifiedDate = updatedFace.personVerifiedDate;

        const msg = 'Face #' + face.id + ' is still attributed to Person #' + updatedFace.person.id + ': '
        + ((updatedFace.person.name) ? updatedFace.person.name : 'Not yet named');
        this.notifications.showInfo('Face seems matched correctly!', msg, 'info', 'Continue', false);

      } else {
        // face now attributed to new person
        const msg = 'Face from image #' + face.image.id + ' is now attributed to: '
        + ((updatedFace.person && updatedFace.person.name) ? updatedFace.person.name :  'Person ' + updatedFace.person.id);
        this.notifications.showInfo(
          'Face was rematched!',
          msg, 'success', 'Continue', false);
        listener.removeFace(face.id);
        listener.updateShown();
      }
      face.showLoader = false;
    }, (err) => {
      console.error(err);
      face.showLoader = false;
    });
  }

  deleteFace(face: IdentifiedFace, listener: FaceLister) {
    face.showLoader = true;
    this.imageService.deleteFace(face).subscribe(e => {
        listener.removeFace(face.id);
        listener.updateShown();
    });
  }

  splitFace(face: IdentifiedFace) {
    face.showLoader = true;
    this.imageService.splitFaceToPerson(face, undefined).subscribe((f) => {
      face.showLoader = false;
      window.open('persons/' + f.person.id, '_self');
    });
  }

  splitFaceBranch(face: IdentifiedFace, listener: FaceLister) {
    face.showLoader = true;
    this.imageService.splitFaceBranchToPerson(face, undefined).subscribe((f) => {
      face.showLoader = false;
      console.log('New Person was created: ' + f.person.id);
      window.open('persons/' + f.person.id, '_blank');
      listener.removeFace(face.id);
    });
  }

  assignFace(face: IdentifiedFace, person: Person, listener: FaceLister) {
    if (!person) {
      this.notifications.showInfo('Select Person first', '', 'warning', 'close', false);
      return;
    }
    face.showLoader = true;
    this.imageService.splitFaceToPerson(face, person.id).subscribe((f) => {
      listener.updateFace(f);
      listener.updateShown();
      this.notifications.showInfo('Face moved', 'Face #' + f.id + ' is now assigned to ' + person.name, 'success', 'Close', false);
      face.showLoader = false;
    });
  }


}
