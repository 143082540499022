<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <h1>{{title}}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <app-loader *ngIf="showLoader"></app-loader>
        </div>
      </div>
      <div class="row">
        <h1 class="text-danger" *ngIf="person && person.ignored">!!! This Person was marked as ignored by {{ person.ignoredBy }} on {{ person.ignoreDate | date:'yyyy-MMM-dd HH:mm' | uppercase }} !!!</h1>
        <div class="col-md-6 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12" *ngIf="person">
                  <h2 *ngIf="!editNameMode">{{ (person.name) ? person.name : 'Not yet named' }}
                    <button (click)="editName()" class="btn btn-outline-primary btn-sm"><i
                        class="icon-pencil"></i></button>
                    <button *ngIf="!person.name && authService.hasSubscriptionLevel(levels.GOLD)"  (click)="requestExternalMatch()" class="btn btn-outline-primary btn-sm" alt="Request external matching"><i
                        class="icon-magnifier"></i></button></h2>
                  <input *ngIf="editNameMode" type="text" [(ngModel)]="tempName" size="50" />
                  <button *ngIf="editNameMode" (click)="saveName()" class="btn btn-outline-success btn-sm"><i
                      class="icon-check"></i></button>
                  <button *ngIf="editNameMode" (click)="cancelEditName()" class="btn btn-outline-danger btn-sm"><i
                      class="icon-trash"></i></button>
                </div>
                <div class="col-6" *ngIf="person">
                  <p *ngIf="person.namedByAI">Named by AI on {{ person.namedByAIAt | date:'yyyy-MMM-dd HH:mm' | uppercase }}</p>
                  <p *ngIf="person.namedByExternal">Named by external matching on {{ person.namedByExternalAt | date:'yyyy-MMM-dd HH:mm' | uppercase }}</p>
                  <img [src]="profilePictureUrl" /><br />
                  <br />
                  <a *ngIf="person.primaryFaceImageId" class="btn btn-outline-primary"
                    [routerLink]="'/images/' + person.primaryFaceImageId">View Image</a>
                </div>
                <div class="col-6" *ngIf="person">
                  <h4>Responsible company</h4>

                  <div class="dropdown">
                    <button type="button" class="btn btn-outline-primary icon-btn dropdown-toggle"
                      id="selectNationalityButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="icon-flag" *ngIf="!person.nationality"></i>
                      <i class="flag-icon flag-icon-{{person.nationality}}" *ngIf="person.nationality"></i>
                    </button>

                    <div class="dropdown-menu" aria-labelledby="selectNationalityButton">
                      <h6 class="dropdown-header">Select primary company</h6>
                      <a class="dropdown-item" (click)="setNationality(undefined)">Unknown</a>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item" *ngFor="let n of nationalities" (click)="setNationality(n.code)"><i
                          class="flag-icon flag-icon-{{n.code}}"></i> {{n.name}}</a>
                    </div>
                  </div>
                  <h4>Description
                    <button *ngIf="!editDescriptionMode" (click)="editDescription()"
                      class="btn btn-outline-primary btn-sm"><i class="icon-pencil"></i></button>

                  </h4>
                  <span *ngIf="!editDescriptionMode">{{ person.description }}</span>
                  <textarea *ngIf="editDescriptionMode" type="text" [(ngModel)]="tempDesc" cols="40"
                    rows="5"></textarea>
                  <button *ngIf="editDescriptionMode" (click)="saveDescription()"
                    class="btn btn-outline-success btn-sm"><i class="icon-check"></i></button>
                  <button *ngIf="editDescriptionMode" (click)="cancelEditDescription()"
                    class="btn btn-outline-danger btn-sm"><i class="icon-trash"></i></button>
                  <h4>Details</h4>
                  <ul>
                    <li *ngIf="faces">Seen on {{ person.facesCount }} face{{ (person.facesCount === 1) ? '' : 's' }} </li>
                    <li *ngIf="faces">{{ person.verifiedCount }} verified face{{ (person.verifiedCount === 1) ? '' : 's' }}</li>
                    <li *ngIf="faces">{{ person.facesCount - person.verifiedCount }} un-verified face{{ ((person.facesCount - person.verifiedCount)=== 1) ? '' : 's' }}</li>
                    <li>Created {{ person.created | date:'yyyy-MMM-dd HH:mm' | uppercase}}</li>
                    <li>Last updated {{ person.lastUpdate | date:'yyyy-MMM-dd HH:mm' | uppercase}}</li>
                    <li>Last seen {{ person.lastImageSeen | date:'yyyy-MMM-dd HH:mm' | uppercase}}</li>
                  </ul>
                  <button *ngIf="person" (click)="getPerson()"
                    class="btn btn-outline-primary btn-sm"><i class="icon-refresh"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div>
                    <div>
                      <h2>Actions</h2>
                      <a *ngIf="person && faceTreeRootCount < 2" [routerLink]="'/persons/' + this.personId + '/facetree'"><button
                          class="btn btn-outline-success btn-block">{{ (faceTreeRootCount == -1) ? 'Verifying Face Tree hierarchy...' : 'Show full Face Tree' }}</button></a>
                      <a *ngIf="person && faceTreeRootCount > 1" [routerLink]="'/persons/' + this.personId + '/facetree-roots'"><button
                          class="btn btn-outline-success btn-block">Show {{faceTreeRootCount}} Face Tree Roots found for Person</button></a>

                      <a *ngIf="person && authService.hasSubscriptionLevel(levels.SILVER)" [routerLink]="'/persons/' + this.personId + '/duplicates'"><button
                          class="btn btn-outline-success btn-block">{{ (potentialDuplicateCount > -1) ? 'Show ' + potentialDuplicateCount + ' Potential Duplicates' : 'Looking for Potential Duplicates...'}}</button></a>
                      <button *ngIf="person && authService.hasSubscriptionLevel(levels.SILVER)" class="btn btn-outline-primary btn-block" [swal]="{ title: 'Are you sure?',
                      text: 'All faces for this person will be attempted auto verified from top of face-tree. Updated images will be pushed to DAM.',
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonClass: 'btn btn-outline-warning',
                      cancelButtonClass: 'btn btn-outline',
                      confirmButtonText: 'Start Auto Verification'
                     }" (confirm)="startAutoVerificationProcess()">Attempt Auto Verification on all faces</button>
                      <button *ngIf="person" class="btn btn-outline-primary btn-block" (click)="mergePerson()">Merge
                        with
                        existing
                        person</button>
                      <button *ngIf="person && authService.hasSubscriptionLevel(levels.SILVER)" class="btn btn-outline-warning btn-block" [swal]="{ title: 'Are you sure?',
                      text: 'All not verified images will be unmatched and all images will be reprocessed using current settings.  !!! This CANNOT be undone !!!',
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonClass: 'btn btn-outline-warning',
                      cancelButtonClass: 'btn btn-outline',
                      confirmButtonText: 'Reprocess All Images'
                     }" (confirm)="reprocessAllImages()">Reprocess all images with this person</button>
                      <button *ngIf="person && !person.ignored" class="btn btn-outline-warning btn-block" [swal]="{ title: 'Are you sure?',
                      text: 'Person will be ignored, hidden from UI and new faces will not be updated in DAM',
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonClass: 'btn btn-outline-warning',
                      cancelButtonClass: 'btn btn-outline',
                      confirmButtonText: 'Ignore Person'
                     }" (confirm)="ignorePerson(person)">Ignore this person</button>
                      <button *ngIf="person && person.ignored" class="btn btn-outline-warning btn-block" [swal]="{ title: 'Are you sure?',
                      text: '',
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonClass: 'btn btn-outline-warning',
                      cancelButtonClass: 'btn btn-outline',
                      confirmButtonText: 'Un-Ignore Person'
                     }" (confirm)="unignorePerson(person)">Un-Ignore this person</button>
                      <button *ngIf="person && authService.hasAdminAccess()  && authService.hasSubscriptionLevel(levels.GOLD)" class="btn btn-outline-warning btn-block" [swal]="{ title: 'Are you sure?',
                      text: 'All faces assigned to Person will have their match data reset and the face tree will be rebuild. This may result in AI verifications being added or removed. The process takes several minutes but you can navigate away from the person once it is started !!! This CANNOT be undone !!!',
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonClass: 'btn btn-outline-warning',
                      cancelButtonClass: 'btn btn-outline',
                      confirmButtonText: 'Restructure Face Tree'
                     }" (confirm)="restructureFaceTreeData(person)">Restructure Entire Tree</button>
                      <button *ngIf="person" class="btn btn-outline-danger btn-block" [swal]="{ title: 'Are you sure?',
                      text: 'Person will be removed and all faces will be unassigned',
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonClass: 'btn btn-outline-danger',
                      cancelButtonClass: 'btn btn-outline',
                      confirmButtonText: 'Delete Person'
                     }" (confirm)="deletePerson(person)">Delete this
                        person</button>
                      <button *ngIf="person && person.name" class="btn btn-outline-danger btn-block" [swal]="{ title: 'Are you sure?',
                      text: 'All AI made verifications will be removed and relations have to be reprocessed with current thresholds !!! This CANNOT be undone !!!',
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonClass: 'btn btn-outline-danger',
                      cancelButtonClass: 'btn btn-outline',
                      confirmButtonText: 'Remove AI Verifications'
                    }" (confirm)="resetAIVerifications(person.id)">Reset all AI verifications</button>
                      <button *ngIf="person && person.name" class="btn btn-outline-danger btn-block" [swal]="{ title: 'Are you sure?',
                        text: 'All verifications will be removed and relations have to be reprocessed with current thresholds !!! This CANNOT be undone !!!',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonClass: 'btn btn-outline-danger',
                        cancelButtonClass: 'btn btn-outline',
                        confirmButtonText: 'Remove Verifications'
                      }" (confirm)="resetAllVerifications(person.id)">Reset all verifications</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="row" *ngIf="person">
          <div class="col-12 grid-margin">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">

                    Include Verified
                      <label class="switch">
                        <input type="checkbox" [(ngModel)]="showVerified" (change)="toggleShowVerified()">
                        <span class="slider round"></span>
                      </label>

                    <div class="dropdown" style="display: inline-block;">
                      <button type="button" class="btn btn-outline-primary icon-btn dropdown-toggle"
                        id="selectOrderButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Order by {{orderText}}
                      </button>

                      <div class="dropdown-menu" aria-labelledby="selectOrderButton">
                        <h6 class="dropdown-header">Order faces by</h6>
                        <a class="dropdown-item" (click)="setOrder('confidence-asc')">Confidence (lowest first)</a>
                        <a class="dropdown-item" (click)="setOrder('confidence-desc')">Confidence (highest first)</a>
                        <a class="dropdown-item" (click)="setOrder('seen-desc')">Newest</a>
                        <a class="dropdown-item" (click)="setOrder('seen-asc')">Oldest</a>
                      </div>
                    </div>
                    <button class="btn btn-outline-primary" [swal]="{ title: 'Are you sure?',
                      text: 'Reprocess will be called for every single face',
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonClass: 'btn btn-outline-warning',
                      cancelButtonClass: 'btn btn-outline',
                      confirmButtonText: 'Reprocess All'
                     }" (confirm)="reprocessAllForPerson()">Reprocess All Shown Faces</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <app-face-menu #faceMenu [listener]="this"></app-face-menu>
                <app-loader *ngIf="showFacesLoader"></app-loader>
                <div class="row" *ngIf="faces">
                  <div class="col-12">
                    <h2>{{ faces.length }} Faces</h2>
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Face</th>
                          <th>Confidence</th>
                          <th>Seen</th>
                          <th>Verified</th>
                          <th>Verified By</th>
                          <th>Image</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let face of faces" (contextmenu)="showFaceContextMenu($event, face)">
                          <td style="align-content: center">
                            <a [routerLink]="'/faces/' + face.id">
                              <img [src]="'processed-images/faces/' + face.id + '.png'" *ngIf="!face.showLoader" class="image" />
                            </a>
                            <app-inline-loader *ngIf="face.showLoader"></app-inline-loader>
                            <a [routerLink]="'/faces/' + face.id">
                              <br />{{ face.id }}
                            </a>
                          </td>
                          <td>{{face.personConfidence | number }} %</td>
                          <td>{{ face.created | date:'yyyy-MMM-dd HH:mm' | uppercase  }}</td>
                          <td>
                            <button *ngIf="!face.personVerified" class="btn btn-outline-success"
                              (click)="verifyFace(face)">Verify</button>
                            {{ face.personVerified ? 'Yes' : ''}}</td>
                          <td><span *ngIf="face.personVerified">{{ face.personVerifiedBy }} on
                              {{ face.personVerifiedDate | date:'yyyy-MMM-dd HH:mm' | uppercase }}</span></td>
                          <td><a class="btn btn-outline-primary" [routerLink]="'/images/' + face.image.id">View</a></td>
                          <td>
                            <button class="btn btn-outline-primary" (click)="useImageAsProfile(face.id)">Use as
                              profile</button>
                            <button class="btn btn-outline-primary" (click)="reprocessFace(face)" *ngIf="authService.hasSubscriptionLevel(levels.SILVER)">Reprocess</button>
                            <button class="btn btn-outline-success" [swal]="{ title: 'Create new Person?',
                              text: 'Face will be assigned to new person',
                              type: 'info',
                              showCancelButton: true,
                              confirmButtonClass: 'btn btn-outline-success',
                              cancelButtonClass: 'btn btn-outline',
                              confirmButtonText: 'Create New Person'
                            }" (confirm)="createNewPerson(face)">Split</button>

                            <swal #selectPersonSwal title="Select existing person" type="info" [showCancelButton]="true"
                              confirmButtonClass="btn btn-outline-success" cancelButtonClass="btn btn-outline"
                              confirmButtonText="Assign Face">
                              <div *swalPartial>
                                <div class="card" style="background-color: white;">
                                  <div class="card-body" style="height: 400px;">
                                    <app-person-selector #personSelector [onSelection]="setSelectedPerson"
                                      [backReference]="this" [white]="true"></app-person-selector>
                                    <div *ngIf="selectedPerson">
                                      <h3>{{ selectedPerson.name }}</h3>
                                      <img [src]="'processed-images/faces/' + selectedPerson.primaryFaceId + '.png'" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </swal>
                            <a class="btn btn-outline-success" [routerLink]="'/faces/' + face.id + '/facetree'">Face
                              Tree</a>
                            <button class="btn btn-outline-success" [swal]="selectPersonSwal"
                              (confirm)="assignToKnownPerson(face)" (cancel)="cancelAssignToKnown()">Assign</button>

                            <button class="btn btn-outline-danger" [swal]="{ title: 'Are you sure?',
                                        text: 'Face will be ignored unless image is reprocessed',
                                        type: 'warning',
                                        showCancelButton: true,
                                        confirmButtonClass: 'btn btn-outline-danger',
                                        cancelButtonClass: 'btn btn-outline',
                                        confirmButtonText: 'Delete Face'
                                       }" (confirm)="deleteFace(face)">Delete</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <app-loader *ngIf="showFacesLoader"></app-loader>
                  <button class="btn btn-outline-primary" (click)="loadMoreFaces()">Load More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
