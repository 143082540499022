import {
    Component,
    Input,
    OnInit,
    OnDestroy,
    OnChanges,
    AfterViewInit,
    ViewChild,
    ElementRef,
    ViewEncapsulation,
    SimpleChanges,
    ChangeDetectorRef,
}from '@angular/core';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ImageRoute } from 'src/app/model/image-route';
import { MetataggerRouteProvider } from 'src/app/model/metatagger-route-provider';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EnvironmentsService } from 'src/app/services/environments.service';
import { ImageRoutingService } from 'src/app/services/image-routing.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TitleService } from 'src/app/services/title.service';

import EditorRender from './editor-render.jsx'

const editorName = 'editorComponentContainer';

@Component({
    selector: 'editor-wrapper',
    template: `<div #${editorName}></div>`,
    styleUrls : ['./editor/Editor.css'],
    encapsulation: ViewEncapsulation.None

})

export class EditorWrapper implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @ViewChild(editorName, {static: false}) containerRef: ElementRef;

    public rootId = 'feeling-form-root';
    private hasViewLoaded = false;

    routes: ImageRoute[];
    providers: MetataggerRouteProvider[];
    updateMode = false;
    temp: ImageRoute = new ImageRoute();
    showLoader: boolean;
    tempstring: string;

    constructor(
        public authService: AuthenticationService,
        private notificationService: NotificationsService,
        private envService: EnvironmentsService,
        private titleService: TitleService,
        private imageRoutingService: ImageRoutingService,
        private changeDetector: ChangeDetectorRef
      ) { }


    ngOnInit(): void {
        this.titleService.setTitle('Image Routing');
        this.getRoutes()
        this.imageRoutingService.listProviders().subscribe((p) => {
            p.sort((a, b) => a.name.localeCompare(b.name));
            this.providers = p;
            this.render();
        });
    }

    addRoute(imageRoute : ImageRoute){
        this.imageRoutingService.createRoute(imageRoute).subscribe((s) => {
            this.notificationService.showInfo('Server said', s, 'info', 'Close', false);
        })
    }

    deleteRoute(id: string){
      console.log(id)
      this.imageRoutingService.deleteRoute(id)
    }

    updateRoute(imageRoute : ImageRoute){
      this.imageRoutingService.updateRoute(imageRoute).subscribe((s) => {
        this.notificationService.showInfo('Server said', s, 'info', 'Close', false);
      })
    }

    getRoutes() {
        this.routes = undefined;
        this.imageRoutingService.listRoutes().subscribe((r) => {
          this.routes = r;
          this.matchRoutesWithProviders();
          this.showLoader = false;
          console.log('Routes refreshed - found ' + this.routes.length);
          this.changeDetector.detectChanges();
        });
        this.render();
      }

      matchRoutesWithProviders() {
        if (this.routes && this.providers) {
          for (const r of this.routes) {
            let found = false;
            for (const p of this.providers) {
              if (p.id == r.provider) {
                r.providerDetails = p;
                found = true;
                break;
              }
            }
            if (!found) {
              r.providerDetails = {
                name: 'Unknown - ' + r.provider
              } as MetataggerRouteProvider;
            }
          }
        }
        this.render();
    }

    ngOnDestroy() {
        ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement)
    }


    ngAfterViewInit() {
        this.hasViewLoaded = true;
        this.render();
    }

    ngOnChanges(changes: SimpleChanges):void {
        this.render();
    }

    hasProvidersDetails(){
      let hasProviders = true
      this.routes.forEach(route => {
        if (route.providerDetails == undefined){
          hasProviders = false
        }
      });
      return hasProviders
    }

    private render() {
        if (this.hasViewLoaded != true || this.providers === undefined || this.routes === undefined ) {
            return;
        }
        if(!this.hasProvidersDetails()){
          this.matchRoutesWithProviders()
          return
        }
        React.version;
        ReactDOM.render(
            <div><EditorRender providers={this.providers} routes={this.routes} insertRoute={this.addRoute.bind(this)} deleteRoute={this.deleteRoute.bind(this)} updateRoute={this.updateRoute.bind(this)}/></div>,
            this.containerRef.nativeElement
        );
    }


}
