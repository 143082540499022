import { Component, OnInit } from '@angular/core';
import { Statistics } from 'src/app/model/Statistics';
import { StatisticsService } from 'src/app/services/statistics.service';
import { Nationality } from 'src/app/model/Nationality';
import { ProcessedImageService } from 'src/app/services/images.service';
import { IdentifiedFace } from 'src/app/model/IdentifiedFace';
import { TitleService } from 'src/app/services/title.service';


declare function Chart(canvas: any, settings: any): void;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  overallStats: Statistics;
  pendingStats: Statistics;
  pendingNamingStats: Statistics;
  pendingUnnamedStats: Statistics;
  uploadPerRegionStats: Statistics;


  imageUploadStats: Statistics;
  currentMonthStats: Statistics;

  latestAIVerifications: IdentifiedFace[];
  latestUserVerifications: IdentifiedFace[];

  regions: Nationality[];

  constructor(
    private imagesService: ProcessedImageService,
    private statisticsService: StatisticsService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Dashboard');
    this.imagesService.getNationalities().subscribe(n => {
      this.regions = n;
    });
    this.statisticsService.getOverallStatistics().subscribe(o => this.overallStats = o);
    this.statisticsService.getPendingStatistics().subscribe(p => {
      this.pendingStats = p;
      this.generatePendingChart(p, 'pending');
    });
    this.statisticsService.getPendingNamingStatistics().subscribe(p => {
      this.pendingNamingStats = p;
      this.generatePendingChart(p, 'pending-naming');
    });
    this.statisticsService.getPendingUnnamedStatistics().subscribe(p => {
      this.pendingUnnamedStats = p;
      this.generatePendingChart(p, 'pending-unnamed');
    });
    this.statisticsService.getPerRegionImageUploadStatistics().subscribe(p => {
      this.uploadPerRegionStats = p;
      this.generatePendingChart(p, 'uploaded');
    });
    this.statisticsService.getImageUploadStatistics().subscribe(i => {
      this.imageUploadStats = i;
      this.generateUploadHistoryChart();
    });
    this.statisticsService.getCurrentMonthStatistics().subscribe(c => this.currentMonthStats = c);
    this.imagesService.getLatestVerifications(5, 0, 'ai').subscribe(l => {
      for (const i of l) {
        while (i.personVerifiedDate && new Date(i.personVerifiedDate).getTime() > new Date().getTime()) {
          i.personVerifiedDate = new Date(new Date(i.personVerifiedDate).getTime() - (1000 * 60 * 60));
        }
      }
      this.latestAIVerifications = l;
    });
    this.imagesService.getLatestVerifications(5, 0, 'user').subscribe(l => {
      for (const i of l) {
        while (i.personVerifiedDate && new Date(i.personVerifiedDate).getTime() > new Date().getTime()) {
          i.personVerifiedDate = new Date(new Date(i.personVerifiedDate).getTime() - (1000 * 60 * 60));
        }
      }
      this.latestUserVerifications = l;
    });
  }

  getOverallStats(key: string): number {
    if (this.overallStats) {
      return this.overallStats[key];
    } else {
      return 0;
    }
  }

  getPendingStats(key: string, values: Statistics): number {
    if (values && values[key]) {
      const t = values[key];
      return t;
    } else {
      return 0;
    }
  }

  getCurrentMonthUploads(): number {
    const k = new Date().toISOString().substr(0, 7);
    return this.getMonthUploads(k);
  }

  getMonthUploads(k: string): number {
    if (this.imageUploadStats) {
      let t = this.imageUploadStats[k];

      if (t) {
        return t;
      } else {
        const year = k.substring(0, 4);
        let month = k.substring(5);
        if (month.length === 2) {
          month = Number(month) + '';
        }
        k = year + '-' + month;
        t = this.imageUploadStats[k];
        if (t) {
          return t;
        } else {
          return 0;
        }
      }
    } else {
      return 0;
    }
  }

  getCurrentMonthUploadChange(): number {
    const k = new Date().toISOString().substr(0, 7);
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    const klast = lastMonth.toISOString().substr(0, 7);


    const current = this.getMonthUploads(k);
    const last = this.getMonthUploads(klast);

    if (last === 0) {
      return 100;
    } else {
      return Number(((current / last) * 100).toFixed(0));
    }
  }

  getRegionName(code: string): string {
    const temp = this.regions.filter(r => r.code === code);
    if (temp && temp.length > 0) {
      return temp[0].name;
    } else {
      return '';
    }
  }

  getCurrentMonthStats(key: string): number {
    if (this.currentMonthStats && this.currentMonthStats[key]) {
      return this.currentMonthStats[key];
    } else {
      return 0;
    }
  }

  getPendingTotal(values: Statistics): number {
    if (values) {
      let t = 0;
      // tslint:disable-next-line:forin
      for (const k in values) {
        t += values[k];
      }
      return t;
    } else {
      return 0;
    }
  }

  generatePendingChart(values: Statistics, prefix: string) {
    const pendingReportData = {
      datasets: [{
        data: this.regions.map(r => this.getPendingStats(r.code, values)),
        backgroundColor: this.regions.map(r => r.backgroundColor),
        borderWidth: 0
      }],

      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: this.regions.map(r => r.name)
    };

    // tslint:disable-next-line:no-string-literal
    if (values['unknown']) {
      pendingReportData.datasets[0].data.push(this.getPendingStats('unknown', values));
      pendingReportData.labels.push('No Company specified');
    }

    const pendingReportOptions = {
      responsive: true,
      maintainAspectRatio: true,
      animation: {
        animateScale: true,
        animateRotate: true
      },
      legend: {
        display: false
      },
      legendCallback: (chartElement) => {
        const text = [];
        text.push('<ul class="legend' + chartElement.id + '">');
        for (let i = 0; i < chartElement.data.datasets[0].data.length; i++) {
          text.push('<li><span class="legend-label" style="background-color:' + chartElement.data.datasets[0].backgroundColor[i] + '"></span>');
          if (chartElement.data.labels[i]) {
            text.push(chartElement.data.labels[i]);
          }
          text.push('<span class="legend-percentage ml-auto">' + chartElement.data.datasets[0].data[i] + ' pending</span>');
          text.push('</li>');
        }
        text.push('</ul>');
        return text.join('');
      },
      cutoutPercentage: 70
    };

    const pendingReportCanvas = document.getElementById(prefix + '-report') as HTMLCanvasElement;
    if (pendingReportCanvas) {
      const pendingReportChart = new Chart(pendingReportCanvas.getContext('2d'), {
        type: 'doughnut',
        data: pendingReportData,
        options: pendingReportOptions
      });
      document.getElementById(prefix + '-report-legend').innerHTML = pendingReportChart.generateLegend();
    }
  }

  generateUploadHistoryChart() {
    let lab = [];
    let dat = [];

    for (const k in this.imageUploadStats) {
      if (k.endsWith('-Change')) {
        continue;
      }
      lab.push(k);
    }
    lab.sort((a, b) => {
      let year_a = Number(a.substring(0, 4));
      let year_b = Number(b.substring(0, 4));

      let month_a = Number(a.substring(5));
      let month_b = Number(b.substring(5));

      if (year_a == year_b) {
        return month_a - month_b;
      } else {
        return year_a - year_b;
      }

    });
    dat = lab.map(l => this.imageUploadStats[l]);

    lab = lab.map(l => {
      const year = l.substring(0, 4);
      let month = l.substring(5);
      if (month.length === 1) {
        month = '0' + month;
      }
      return year + '-' + month;
    });

    if (lab.length === 1) {
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      let last = lastMonth.toISOString().substr(0, 7);
      if (last === lab[0]) {
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        last = lastMonth.toISOString().substr(0, 7);
      }

      lab.unshift(last);

      dat.unshift(0);
    }

    // check if current month is included
    const thisMonth = new Date().toISOString().substr(0, 7);
    if (lab[lab.length - 1] !== thisMonth) {
      lab.push(thisMonth);
      dat.push(0);
    }

    const data = {
      labels: lab,
      datasets: [{
          data: dat,
          backgroundColor: [
            '#22548e'
          ],
          borderColor: [
            '#22548e'
          ],
          borderWidth: 0,
          fill: false,
        }
      ]
    };
    const options = {
      scales: {
        yAxes: [{
          display: true,
          gridLines: {
            drawBorder: false,
            lineWidth: 0,
            color: 'rgba(0,0,0,0)'
          },
          ticks: {
            fontColor: '#686868',
            beginAtZero: true
          }
        }],
        xAxes: [{
          gridLines: {
            drawBorder: false,
            lineWidth: 0,
            color: 'rgba(0,0,0,0)'
          }
        }]
      },
      legend: {
        display: false
      },
      elements: {
        point: {
          radius: 0
        }
      },
      stepsize: 1
    };
    const activityChartCanvas = document.getElementById('chart-activity') as HTMLCanvasElement;
    if (activityChartCanvas) {
      const activityChart = new Chart(activityChartCanvas.getContext('2d'), {
        type: 'line',
        data: data,
        options: options
      });
    }
  }

}
