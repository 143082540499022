import { Component, OnInit } from '@angular/core';
import { ImageMatchSettings } from 'src/app/model/ImageMatchSettings';
import { Nationality } from 'src/app/model/Nationality';
import { Statistics } from 'src/app/model/Statistics';
import { Environment } from 'src/app/model/Environment';
import { ProcessedImageService } from 'src/app/services/images.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SettingsService } from 'src/app/services/settings.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { StatisticsService } from 'src/app/services/statistics.service';
import { EnvironmentsService } from 'src/app/services/environments.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-person-matching',
  templateUrl: './person-matching.component.html',
  styleUrls: ['./person-matching.component.css']
})
export class PersonMatchingComponent implements OnInit {

  currentSettings: ImageMatchSettings;
  results: string[];
  queueResult: string;
  directMatchUpdateLabel: string;
  ignoredMatchUpdateLabel: string;
  verifyUnknownLabel: string;
  useReadReplicasUpdateLabel: string;

  reSearchCollection = false;

  autoUpdateTitle = 'Fetching details on pending verifications...';

  autoVerifyInProgress = false;
  showPendingLoader = false;
  showQueueLoader = false;
  showVerifyUnknownSettingLoader = false;
  showMatchSettingLoader = false;
  showIgnoredSettingLoader = false;
  showUseReadReplicaLoader = false;
  saveInProgress = false;
  showLoader = false;
  showDeleteLoader = false;
  showAINamingLoader = false;
  showExternalNamingLoader = false;

  regions: Nationality[];
  currentStatistics: Statistics;
  pendingStatistics: Statistics;
  environment: Environment;


  constructor(
    private imagesService: ProcessedImageService,
    public authService: AuthenticationService,
    private settingsService: SettingsService,
    private notificationService: NotificationsService,
    private statisticsService: StatisticsService,
    private envService: EnvironmentsService,
    private titleService: TitleService
    ) { }

  ngOnInit() {
    this.showLoader = true;
    this.titleService.setTitle('Settings: Person Matching');
    this.showMatchSettingLoader = true;
    this.showIgnoredSettingLoader = true;
    this.showUseReadReplicaLoader = true;
    this.imagesService.getNationalities().subscribe(n => {
      this.regions = n;
    });
    this.updateSettings();
    this.envService.selectedEnv.subscribe((e) => {
      this.environment = e;
    });


    this.getStats();
  }

  updateSettings() {
    this.queueResult = 'Fetching latest values from server...';
    this.settingsService.getImageMatcherSettings().subscribe(s => {
      this.currentSettings = s;
      this.showLoader = false;
      this.showMatchSettingLoader = false;
      this.showIgnoredSettingLoader = false;
      this.showUseReadReplicaLoader = false;
      this.queueResult = 'Currently ' + s.imagesPendingPersonMatching + ' images are pending Person Matching';
    });
  }


  getStats() {
    this.showPendingLoader = true;
    this.pendingStatistics = undefined;
    this.currentStatistics = undefined;
    this.getPendingCounts();
    this.getDryRunCounts();
  }

  getPendingCounts() {
    this.showPendingLoader = true;
    this.statisticsService.getPendingStatistics().subscribe(stats => {
      this.currentStatistics = stats;
      this.checkPendingStatus();
    });
  }

  getDryRunCounts() {
    this.showPendingLoader = true;
    this.imagesService.dryRunAutoUpdates().subscribe(stats => {
      this.pendingStatistics = stats;
      this.checkPendingStatus();
    });
  }

  checkPendingStatus() {
    if (this.pendingStatistics && this.currentStatistics) {
      this.showPendingLoader = false;
      this.autoUpdateTitle = 'Update Auto Verified Faces';
      this.imagesService.getNationalities().subscribe(n => {
        this.regions = n;
      });
    }
  }

  save() {
    this.saveInProgress = true;
    this.settingsService.updateImageMatcherSettings(this.currentSettings).subscribe(s => {
      this.currentSettings = s;
      this.saveInProgress = false;

      this.getStats();
    });
  }


  public getPendingCount(code: string) {
    if (this.currentStatistics && this.currentStatistics[code]) {
      return this.currentStatistics[code];
    } else {
      return 0;
    }
  }

  public getDryRunRemovedCount(code: string) {
    if (this.pendingStatistics && this.pendingStatistics.deletedVerifications && this.pendingStatistics.deletedVerifications[code]) {
      return this.pendingStatistics.deletedVerifications[code];
    } else {
      return 0;
    }
  }

  public getDryRunAddedCount(code: string) {
    if (this.pendingStatistics && this.pendingStatistics.verifications && this.pendingStatistics.verifications[code]) {
      return this.pendingStatistics.verifications[code];
    } else {
      return 0;
    }
  }

  public updateAutoVerified() {
    this.autoVerifyInProgress = true;
    this.imagesService.autoUpdateVerifications().subscribe(stats => {
      this.getStats();
      this.notificationService.showInfo('Auto Verified Faces was Updated', '', 'success', 'Close', false);
      this.autoVerifyInProgress = false;
    });
  }

  public updateDirectPersonMatchSetting() {
    this.showMatchSettingLoader = true;
    this.settingsService.updateDirectPersonMatchSetting(this.currentSettings.sendToPersonMatching).subscribe((s) => {
      this.showMatchSettingLoader = false;
      this.directMatchUpdateLabel = s;
    });
  }

  public updateIgnoredMatchSetting() {
    this.showIgnoredSettingLoader = true;
    this.settingsService.updateMatchIgnoredFacesSetting(this.currentSettings.matchIgnoredFaces).subscribe((s) => {
      this.showIgnoredSettingLoader = false;
      this.ignoredMatchUpdateLabel = s;
    });
  }

  public updateUseReadReplicasSetting() {
    this.showUseReadReplicaLoader = true;
    this.settingsService.updateUseReadReplicasDuringPersonMatchingSetting(this.currentSettings.useReadReplicasDuringPersonMatching).subscribe((s) => {
      this.showUseReadReplicaLoader = false;
      this.useReadReplicasUpdateLabel = s;
    });
  }


  public resetAIVerifications() {
    this.showDeleteLoader = true;
    this.imagesService.clearAIVerifications().subscribe((res) => {
      this.results = res;
      this.showDeleteLoader = false;
    });
  }

  public resetAllVerifications() {
    this.showDeleteLoader = true;
    this.imagesService.clearAllVerifications().subscribe((res) => {
      this.results = res;
      this.showDeleteLoader = false;
    });
  }

  public resetAllPersons() {
    this.showDeleteLoader = true;
    this.imagesService.clearAllPersons().subscribe((res) => {
      this.results = res;
      this.showDeleteLoader = false;
    });
  }


  public startPendingQueue() {
    this.showQueueLoader = true;
    this.imagesService.startPendingQueue(this.reSearchCollection).subscribe((res) => {
      this.queueResult = res;
      this.showQueueLoader = false;
    });
  }


  public resetAllUnnamedPersons() {
    this.showDeleteLoader = true;
    this.imagesService.clearAllUnnamedPersons().subscribe((res) => {
      this.results = res;
      this.showDeleteLoader = false;
    });
  }

  public removeAIVerificationsAndReprocess() {
    this.showDeleteLoader = true;
    this.settingsService.removeAIVerificationsAndReprocess().subscribe((res) => {
      this.results = [res];
      this.showDeleteLoader = false;
    });
  }

  public restartVerificationForAll() {
    this.showDeleteLoader = true;
    this.settingsService.redoVerifications().subscribe((res) => {
      this.results = [res];
      this.showDeleteLoader = false;
    });

  }

  public startAINaming() {
    this.showAINamingLoader = true;
    this.settingsService.queueUnamedForAINaming().subscribe((res) => {
      this.notificationService.showInfo('Started process to send unnamed Persons to AI naming', '', 'success', 'Close', false);
      this.showAINamingLoader = false;
    });
  }

  public clearAINaming() {
    this.showAINamingLoader = true;
    this.settingsService.clearAllAINaming().subscribe((res) => {
      this.notificationService.showInfo('Cleared all AI assigned named from Persons - statistics on dashboard may take up to 15 minutes to reflect this.', '', 'success', 'Close', false);
      this.showAINamingLoader = false;
    });
  }

  public startExternalNaming() {
    this.showExternalNamingLoader = true;
    this.settingsService.queueUnamedForExternalNaming().subscribe((res) => {
      this.notificationService.showInfo('Started process to send unnamed Persons to external naming', '', 'success', 'Close', false);
      this.showExternalNamingLoader = false;
    });
  }

  public clearExternalNaming() {
    this.showExternalNamingLoader = true;
    this.settingsService.clearAllExternalNaming().subscribe((res) => {
      this.notificationService.showInfo('Cleared all external named from Persons - statistics on dashboard may take up to 15 minutes to reflect this.', '', 'success', 'Close', false);
      this.showExternalNamingLoader = false;
    });
  }

}
