import { Injectable } from '@angular/core';

declare const Swal: any;

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor() { }

  public showInfo(title: string, message: string, icon: 'success' | 'warning' | 'info' | 'error',
                  buttonText: string, showCancel: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      console.log(message);
      Swal.fire({
        titleText: title,
        html: message,
        type: icon,
        footer: '<span>Metatagger.io - by <a href="https://www.keycore.dk/" target="_blank">KeyCore</a></span>',
        showCloseButton: true,
        showCancelButton: showCancel,
        confirmButtonText: buttonText,
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-outline-secondary'
        },
        buttonsStyling: false,
      }).then(res => {
        if (res.value) {
          resolve();
        } else {
          reject();
        }
      });
    });
  }
  public showQuestion(title: string, message: string,
                      icon: 'success' | 'warning' | 'info' | 'error',
                      confirmText: string, rejectText: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      console.log(message);
      Swal.fire({
        titleText: title,
        html: message,
        type: icon,
        footer: '<span>Metatagger.io - by <a href="https://www.keycore.dk/" target="_blank">KeyCore</a></span>',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: confirmText,
        cancelButtonText: rejectText,
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-outline-secondary'
        },
        buttonsStyling: false,
      }).then(res => {
        if (res.value) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

}
