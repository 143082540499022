<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <h1>Upload Images Directly</h1>
      <div class="row">
        <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div id="holder" (appDropZone)="onDrop($event)">
                    <div class="loader-wrapper" id="loader-wrapper">
                      <div class="loader" id="loader"></div>
                      <p id="loader-text" class="loader-text">Drop images or videos here</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="upload-table">
                    <h4>Set Primary Company</h4>

                    <div class="form-group">
                      <div class="dropdown">
                          Uploading for company: <i class="flag-icon flag-icon-{{selectedRegion.toLowerCase()}}"></i><button type="button" class="btn btn-outline-primary icon-btn dropdown-toggle"
                          id="selectRegionButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="icon-flag"></i>
                        </button>

                        <div class="dropdown-menu" aria-labelledby="selectRegionButton">
                          <h6 class="dropdown-header">Select editorial region</h6>
                          <a class="dropdown-item" (click)="setRegion(undefined)">Unknown</a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item" *ngFor="let n of regions" (click)="setRegion(n.code)"><i
                              class="flag-icon flag-icon-{{n.code}}"></i> {{n.name}}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="upload-table">
                    <h2 *ngIf="files && files.length > 0">Uploading a total of {{ files.length }} files</h2>
                    <div class="row" *ngIf="files && files.length > 0">
                      <div class="progress progress-xl">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 100%"
                          [attr.aria-valuenow]="progressValue" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <h4>{{ completed }} ({{ progressValue | number:'1.0-0' }} %) of files uploaded - {{ failedCount }} files failed</h4>
                    </div>
                    <div class="form-group">
                      <button class="btn btn-primary" (click)="clearTable()">Clear</button>
                    </div>
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Original Name</th>
                          <th>UploadedName</th>
                          <th>Type</th>
                          <th>Size</th>
                          <th>Upload Time</th>
                          <th>Last Modified</th>
                          <th>Progress</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody class="upload-name-style">
                        <tr *ngFor="let item of files; let i=index">
                          <td><strong>{{ item.origname }}</strong></td>
                          <td><strong>{{ item.name }}</strong></td>
                          <td><strong>{{ item.type }}</strong></td>
                          <td><strong>{{ item.size | bytes }}</strong></td>
                          <td><strong>{{ item.uploadDate | date:'yyyy-MMM-dd HH:mm' | uppercase  }}</strong></td>
                          <td><strong>{{ item.lastDate | date:'yyyy-MMM-dd HH:mm' | uppercase  }}</strong></td>
                          <td><strong>{{ item.uploadedPercentage | number:'1.0-0' }} %</strong></td>
                          <td><strong>{{ item.status }}</strong></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
