import * as React from 'react';
import { Button, Popup } from 'semantic-ui-react'


class RouteMenu extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        routes : this.props.routes,
        counter : 0
      };
      this.renderRoute = this.renderRoute.bind(this);
    }

    getRandomID() {
      let max = 1000
      return Math.floor(Math.random() * Math.floor(max));
    }

    renderRouteInformation(route){
      return(
        <div className="pop-up">
          <span></span>
          <span className="route-information-header">Description:</span>
          <br/>
          <span className="route-information">{route.description}</span>
          <br/>
          <span className="link-header">Link: </span>
          <span className="link"> {route.website}</span>
        </div>
      )
    }


    renderRoute(route){
      return(
        <div onClick={ () => this.props.insertRoute(route)} className="RouteInput" key={route.name}>
          <Popup content={()=>this.renderRouteInformation(route)} trigger={
            <div className="center-text-container">
            <span className="route-details-header"> {route.name}</span>
            </div>
          }
          />
          <span className="route-details-name">Unit cost: </span>
          <span className="route-details"> {route.costUnit}</span>
          <br />
          <span className="route-details-name">Cost per unit: </span>
          <span className="route-details"> {route.cost}</span>
          <br/>
        </div>
      )
    }

    startRoute(){
      return(
        <div onClick={ () => this.props.insertStartingPoint()} className="startRoute">
          <div className="center-text-container">
          <span className="route-details-header">Starting point</span>
          </div>
        </div>
      )

    }

    render() {
      return (
        <div>
        <div className="menuTop">
        <div className="center-text-container">
        <h2 className="routeMenuHeader">Providers</h2></div>
        </div>
        <div>
          <div className="startRouteWrapper">
            {this.startRoute()}
            </div>
          </div>
          <div className="routeScroller">
            <div className="routeMenuInput">
            <div className="center-text-container">
              {this.state.routes.map(route => {
                return (this.renderRoute(route))
              })}
              </div>
              </div>
            </div>
          </div>
      );
    }
}
export default RouteMenu;
