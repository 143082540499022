import * as moment from 'moment-timezone';


export class BugReport {

    public id: number;
    public subject: string;
    public description: string;

    public priority: 'High' | 'Normal' | 'Low';
    public reporter: string;

    public status: 'Reported' | 'In-progress' | 'Closed-Pending-Deploy' | 'Fixed' | 'Wont-Fix';
    public type: 'Bug' | 'Feature-Request' | 'Question';

    public response: string;
    public lastUpdate: moment.Moment;
    public lastUpdateBy: string;

    public created: moment.Moment;
    public childCount: number;
    public parentId?: number;

    public faceId?: number;
    public imageId?: number;
    public personId?: number;

    public requestId: string;
    public logStream: string;
    public logGroup: string;
}

