import { Component, OnInit, ViewChild } from '@angular/core';
import { Person } from 'src/app/model/Person';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessedImageService } from 'src/app/services/images.service';
import { IdentifiedFace } from 'src/app/model/IdentifiedFace';
import { Nationality } from 'src/app/model/Nationality';
import { NotificationsService } from 'src/app/services/notifications.service';
import { PersonSelectorComponent } from 'src/app/components/person-selector/person-selector.component';
import { FaceLister } from 'src/app/interfaces/FaceLister';
import { TitleService } from 'src/app/services/title.service';
import { HistoryService } from 'src/app/services/history.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SubscriptionLevel } from 'src/app/model/Environment';
import {ContextMenuComponent, ContextMenuService} from '@perfectmemory/ngx-contextmenu';

declare function swal(details: any): any;

@Component({
  selector: 'app-person-details',
  templateUrl: './person-details.component.html',
  styleUrls: ['./person-details.component.css']
})
export class PersonDetailsComponent implements OnInit, FaceLister {

  @ViewChild('faceMenu') contextMenu: ContextMenuComponent<any>;
  @ViewChild('personSelector') personSelector: PersonSelectorComponent;

  public levels = SubscriptionLevel;

  public displayedColumns = ['id', 'personConfidence', 'personVerified', 'personVerifiedBy', 'imageId', 'actId'];
  public dataSource: any;

  editNameMode = false;
  tempName: string;
  potentialDuplicateCount = 0;
  faceTreeRootCount = 0;

  editDescriptionMode = false;
  tempDesc: string;

  showLoader = true;

  profilePictureUrl: string;

  nationalities: Nationality[];

  public selectedPerson: Person;
  public person: Person;
  public allfaces: IdentifiedFace[];
  public faces: IdentifiedFace[];

  public personId: number;
  public title: string;

  offset = 0;
  limit = 100;

  showFacesLoader = false;
  showVerified = false;
  order: 'confidence-asc' | 'confidence-desc' | 'seen-asc' | 'seen-desc' = 'confidence-asc';
  orderText = 'Newest';

  oldName: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private imageService: ProcessedImageService,
    private notifications: NotificationsService,
    private contextMenuService: ContextMenuService<any>,
    private titleService: TitleService,
    public authService: AuthenticationService,
    private historyService: HistoryService
  ) { }

  ngOnInit() {
    this.imageService.getNationalities().subscribe(n => this.nationalities = n);
    this.getPerson();
    this.route.params.subscribe((p) => this.getPerson());
  }


  showFaceContextMenu($event: MouseEvent, face: IdentifiedFace): void {
    console.log('Showing face menu for #' + face.id);
    this.contextMenuService.show(
       this.contextMenu,
      {
      x: $event.x,
      y: $event.y,
      value: face,
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

  getPerson() {
    this.potentialDuplicateCount = -1;
    this.faceTreeRootCount = -1;
    this.personId = Number(this.route.snapshot.paramMap.get('personId'));
    this.title = 'Fetching person details for person with id ' + this.personId;
    this.titleService.setTitle('Person #' + this.personId);
    this.lookForPotentialDuplicates();
    this.lookForMultipleRoots();
    this.imageService.getPerson(this.personId).subscribe((p) => {
      this.person = p;
      this.historyService.addEntry('Person #' + this.personId + ((p.name) ? ' - ' + p.name : ''), '/persons/' + this.personId);
      this.profilePictureUrl = 'processed-images/faces/' + p.primaryFaceId + '.png';
      if (p.name) {
        this.title = 'Showing faces where "' + p.name + '" has been identified';
        this.titleService.setTitle('#' + this.personId + ' ' + p.name);
      } else {
        this.title = 'Showing faces linked to unknown person';
        this.titleService.setTitle('#' + this.personId + ' Unnamed');
      }
      this.showLoader = false;
    });
    this.offset = 0;
    this.loadFaces();
  }

  requestExternalMatch() {
    this.showLoader = true;
    this.imageService.requestExternalMatching(this.personId).subscribe((s) => {
      this.notifications.showInfo('External Match Request', s, 'info', 'Close', false);
      this.getPerson();
    });
  }

  loadFaces() {
    this.showFacesLoader = true;
    this.faces = [];
    this.imageService.getPersonFaces(this.personId, this.showVerified, false, this.order, this.offset, this.limit).subscribe((p) => {
      this.allfaces = p;
      this.updateShown();
    });
  }

  loadMoreFaces() {
    this.showFacesLoader = true;
    this.offset += this.limit;
    this.imageService.getPersonFaces(this.personId, this.showVerified, false, this.order, this.offset, this.limit).subscribe((p) => {
      this.allfaces = this.allfaces.concat(p);
      this.updateShown();
    });
  }

  toggleShowVerified() {
    if (this.showVerified) {
      this.setOrder('confidence-desc');
    } else {
      this.loadFaces();
    }
  }


  setOrder(order: 'confidence-asc' | 'confidence-desc' | 'seen-asc' | 'seen-desc') {
    this.offset = 0;
    this.order = order;

    switch (order) {
      case 'confidence-asc': {
        this.orderText = 'Confidence (lowest first)';
        break;
      }
      case 'confidence-desc': {
        this.orderText = 'Confidence (highest first)';
        break;
      }
      case 'seen-desc': {
        this.orderText = 'Newest';
        break;
      }
      case 'seen-asc': {
        this.orderText = 'Oldest';
        break;
      }
    }

    this.loadFaces();
  }

  setSelectedPerson(p: Person, me: any) {
    console.log('Selected: ' + p.name);
    me.selectedPerson = p;
  }

  updateShown() {
    this.faces = this.allfaces;
    this.showFacesLoader = false;
  }

  editName() {
    this.editNameMode = true;
    this.tempName = this.person.name;
  }

  cancelEditName() {
    this.editNameMode = false;
  }

  async saveName() {
    if (this.tempName && this.tempName.indexOf(';') > -1) {
      try {
        await this.notifications.showInfo('Illegal Character detected', 'You have specified a name with semicolon ";"', 'warning', 'Continue with Update', true);
      } catch (err) {
        console.log('User Cancelled');
        return;
      }
    }
    this.oldName = this.person.name;
    this.person.name = this.tempName.trim();
    this.showLoader = true;
    console.log('Changing: ' + this.person.name);
    this.editNameMode = false;
    const responseHandler = async (msg) => {
      this.showLoader = false;
      this.person.forceNameChange = false;
      console.log(msg);
      if (msg === 'Updated Person') {
        this.title = 'Showing faces where "' + this.person.name + '" has been identified';
        this.loadFaces();
      } else {
        try {
          await this.notifications.showInfo('Unable to update Person', msg, 'warning', 'Force Update?', true);
          this.showLoader = true;
          this.person.forceNameChange = true;
          this.imageService.updatePerson(this.person).subscribe(responseHandler);
        } catch (err) {
          console.log('User Cancelled');
          this.person.name = this.oldName;
          return;
        }
      }
    };
    this.imageService.updatePerson(this.person).subscribe(responseHandler);
  }
  editDescription() {
    this.editDescriptionMode = true;
    this.tempDesc = this.person.description;
  }

  cancelEditDescription() {
    this.editDescriptionMode = false;
  }

  public resetAIVerifications(id: number) {
    this.showLoader = true;
    this.imageService.clearAIVerifications(id).subscribe((res) => {
      this.showLoader = false;
      this.getPerson();
    });
  }

  public resetAllVerifications(id: number) {
    this.showLoader = true;
    this.imageService.clearAllVerifications(id).subscribe((res) => {
      this.showLoader = false;
      this.getPerson();
    });
  }

  saveDescription() {
    this.person.description = this.tempDesc;
    this.showLoader = true;
    this.editDescriptionMode = false;
    console.log('Changed: ' + this.person.name);
    this.imageService.updatePerson(this.person).subscribe(() => {
      this.showLoader = false;
    });
  }

  setNationality(value: string) {
    this.person.nationality = value;
    this.showLoader = true;
    console.log('Changed: ' + this.person.name);
    this.imageService.updatePerson(this.person).subscribe(() => {
      this.showLoader = false;
    });

  }

  lookForPotentialDuplicates() {
    this.imageService.searchPotentialDuplicates(this.personId).subscribe(p => {
      this.potentialDuplicateCount = p.length;
    });
  }

  lookForMultipleRoots() {
    this.imageService.searchFaceRoots(this.personId).subscribe(p => {
      this.faceTreeRootCount = p.length;
    });
  }

  viewImage(imageId: number) {
    this.router.navigate(['images/' + imageId]);
  }

  useImageAsProfile(faceId: number) {
    this.showLoader = true;
    this.imageService.setProfileImage(this.personId, faceId).subscribe((d) => {
      this.getPerson();
    });
  }

  reprocessFace(face: IdentifiedFace) {
    face.showLoader = true;
    this.imageService.reprocessFace(face).subscribe((updatedFace) => {
      if (face.person.id === updatedFace.person.id) {
        // still same person
        face.personVerified = updatedFace.personVerified;
        face.personVerifiedBy = updatedFace.personVerifiedBy;
        face.personVerifiedDate = updatedFace.personVerifiedDate;

        const msg = 'Face #' + face.id + ' is still attributed to Person #' + updatedFace.person.id + ': '
        + ((updatedFace.person.name) ? updatedFace.person.name : 'Not yet named');
        this.notifications.showInfo('Face seems matched correctly!', msg, 'info', 'Continue', false);

      } else {
        // face now attributed to new person
        const msg = 'Face from image #' + face.image.id + ' is now attributed to: '
        + ((updatedFace.person && updatedFace.person.name) ? updatedFace.person.name :  'Person ' + updatedFace.person.id);
        this.notifications.showInfo(
          'Face was rematched!',
          msg,
                 'success', 'Continue', false);
        this.allfaces = this.allfaces.filter((f) => f.id !== face.id);
        this.updateShown();
      }
      face.showLoader = false;
    }, (err) => {
      console.error(err);
      face.showLoader = false;
    });
  }

  deletePerson(person: Person) {
    this.showLoader = true;
    this.imageService.deletePerson(person.id).subscribe(v => {
      this.router.navigate(['persons']);
    });
  }

  ignorePerson(person: Person) {
    this.showLoader = true;
    this.imageService.ignorePerson(person.id).subscribe(v => {
      this.getPerson();
    });
  }

  unignorePerson(person: Person) {
    this.showLoader = true;
    this.imageService.unignorePerson(person.id).subscribe(v => {
      this.getPerson();
    });
  }

  restructureFaceTreeData(person: Person) {
    this.showLoader = true;
    this.imageService.restructureFaceTreeData(person.id).subscribe(v => {
      this.getPerson();
    });
  }

  mergePerson() {
    this.router.navigate(['merge/' + this.personId]);
  }

  reprocessAllForPerson() {
    for (const f of this.allfaces) {
      this.reprocessFace(f);
    }
  }

  deleteFace(face: IdentifiedFace) {
    face.showLoader = true;
    this.imageService.deleteFace(face).subscribe(e => {
      this.allfaces = this.allfaces.filter((f) => f.id !== face.id);
      this.updateShown();
    });
  }

  verifyFace(face: IdentifiedFace) {
    face.showLoader = true;
    this.imageService.verifyFace(face).subscribe(updatedFace => {
      face.personVerified = updatedFace.personVerified;
      face.personVerifiedBy = updatedFace.personVerifiedBy;
      face.personVerifiedDate = updatedFace.personVerifiedDate;

      if (updatedFace.listOfVerified) {
        for (const id of updatedFace.listOfVerified) {
          for (const f of this.allfaces) {
            if (f.id === id) {
              f.personVerified = true;
              f.personVerifiedBy = 'AI';
              f.personVerifiedDate = new Date();
            }
          }
        }
      }

      face.showLoader = false;

      this.updateShown();
    });
  }

  createNewPerson(face: IdentifiedFace) {
    face.showLoader = true;
    this.imageService.splitFaceToPerson(face, undefined).subscribe((f) => {
      face.showLoader = false;
      this.router.navigate(['persons/' + f.person.id]);
    });
  }

  assignToKnownPerson(face: IdentifiedFace) {
    if (!this.selectedPerson) {
      this.notifications.showInfo('Select Person first', '', 'warning', 'close', false);
      return;
    }
    face.showLoader = true;
    this.imageService.splitFaceToPerson(face, this.selectedPerson.id).subscribe((f) => {
      this.allfaces = this.allfaces.filter((fa) => fa.id !== face.id);
      this.updateShown();
      this.notifications.showInfo('Face moved', 'Face #' + f.id + ' is now assigned to ' + this.selectedPerson.name, 'success', 'Close', false);
      face.showLoader = false;
    });
  }

  cancelAssignToKnown() {
    this.selectedPerson = undefined;
  }

  removeFace(id: number) {
    this.allfaces = this.allfaces.filter((f) => f.id !== id);
    this.updateShown();
    console.log('Removed face: ' + id);
  }

  updateFace(face: IdentifiedFace) {
    if (face.person.id !== this.person.id) {
      this.removeFace(face.id);
    } else {
      this.faces.filter((f) => f.id === face.id)[0].showLoader = false;
    }
  }

  startAutoVerificationProcess() {
    this.imageService.startPersonVerifications(this.personId).subscribe(msg => {
      this.notifications.showInfo('Verification Reprocess Requested', msg, 'info', 'Close', false);
    });
  }

  async reprocessAllImages() {
    // const reprocess = await this.notifications.showQuestion('Re-search AWS Rekognition?', 'Should all image relations be updated by re-searching the face collection?',
    // 'info', 'Yes', 'No');

    const reprocess = true;

    this.imageService.startReprocessOfAll(this.personId, reprocess).subscribe(msg => {
      this.notifications.showInfo('Face-Match Reprocess Requested', msg, 'info', 'Close', false);
    });
  }

}
