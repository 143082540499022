import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { CognitoService } from '../services/cognito.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private cognito: CognitoService
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        if (this.authenticationService) {
            const currentUser = this.authenticationService.currentUserValue;
            if (currentUser && currentUser.token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `${currentUser.token}`
                    }
                });

                if (currentUser.refreshToken && (!(currentUser.lastRefresh) || currentUser.lastRefresh < Date.now() - (1000 * 60 * 30))) {
                    if (!(currentUser.lastRefresh)) {
                        console.log('User has never been refreshed - starting process to do so');
                    }
                    const cog = this.cognito;
                    if (cog && cog.refreshTokens) {
                        setTimeout(() => {
                            console.log('Refreshing token');
                            cog.refreshTokens(currentUser).subscribe((u) => {
                                console.log('User appear refreshed');
                            }, (err) => {
                                console.log('Error refreshing token: ' + err);
                            });
                        }, 1);
                    }
                }
            }
        }
        return next.handle(request);
    }
}
