import { Component, OnInit, Input } from '@angular/core';
import { ProcessedImageService } from 'src/app/services/images.service';
import { BehaviorSubject } from 'rxjs';
import { Person } from 'src/app/model/Person';

declare var Bloodhound: any;
declare var $: any;

@Component({
  selector: 'app-person-selector',
  templateUrl: './person-selector.component.html',
  styleUrls: ['./person-selector.component.css']
})
export class PersonSelectorComponent implements OnInit {

  showLoader = true;
  persons: Person[];
  selectedValue: string;

  public selectedPerson: BehaviorSubject<Person> = new BehaviorSubject<Person>(undefined);

  @Input() onSelection: (p: Person, backReference: any) => void;
  @Input() backReference: any;
  @Input() white: boolean;

  constructor(
    private personService: ProcessedImageService
  ) { }

  ngOnInit() {
    this.getPersons();
  }

  getPersons() {
    this.personService.getAllPersons().subscribe((p) => {
      this.persons = p.filter((per) => per.name != null);

      // const temp = this.persons.map((per) => ({name: per.name, id: per.id}));
      const temp = this.persons.map((per) => per.name);

      const typ = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        // identify: (per) => per.id,
        local: temp
      });

      const field = $('.typeahead');

      field.typeahead({
        hint: true,
        highlight: true, /* Enable substring highlighting */
        minLength: 1 /* Specify minimum characters required for showing result */
    },
    {
        name: 'persons',
          source: typ
        });
      const me = this;
      field.on('change', (e) => {
        me.personChanged(e);
      });

      this.showLoader = false;
    });
  }


  personChanged(e) {
    console.log(e);
    const value = e.currentTarget.value;

    console.log(value);
    const p = this.persons.filter((per) => per.name === value);

    if (p && p.length > 0) {
      this.selectedPerson.next(p[0]);
      console.log('Selected: ' + p[0].name + ' (#' + p[0].id + ')');
      if (this.onSelection) {
        this.onSelection(p[0], this.backReference);
      }
    }
  }



}
