import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderComponent } from './widgets/loader/loader.component';
import { LatestPhotosComponent } from './pages/images/latest-photos/latest-photos.component';
import { ViewPhotoComponent } from './pages/images/view-photo/view-photo.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { GravatarComponent } from './widgets/gravatar/gravatar.component';
import { NgMathPipesModule } from 'angular-pipes';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule } from '@angular/forms';
import { WindowRef } from './helpers/WindowRef';
import { JwtInterceptor, ErrorInterceptor } from './helpers';
import { ManualUploadComponent } from './pages/other/manual-upload/manual-upload.component';
import { DropZoneDirective } from './pages/other/manual-upload/drop-zone-directive';
import { ViewPersonsComponent } from './pages/persons/view-persons/view-persons.component';
import { StatisticsComponent } from './pages/other/statistics/statistics.component';
import { DatamodelComponent } from './pages/other/datamodel/datamodel.component';
import { PersonDetailsComponent } from './pages/persons/person-details/person-details.component';
import { ProfileSettingsComponent } from './pages/profile-settings/profile-settings.component';
import { InlineLoaderComponent } from './widgets/inline-loader/inline-loader.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ErrorsHandler } from './helpers/ErrorHandler';
import { MergePersonsComponent } from './pages/persons/merge-persons/merge-persons.component';
import { VerificationQueueComponent } from './pages/persons/verification-queue/verification-queue.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ListUsersComponent } from './pages/other/list-users/list-users.component';
import { ScanS3BucketComponent } from './pages/other/scan-s3-bucket/scan-s3-bucket.component';
import { PersonSelectorComponent } from './components/person-selector/person-selector.component';
import { FaceMenuComponent } from './components/face-menu/face-menu.component';
import { MonitoringComponent } from './pages/other/monitoring/monitoring.component';
import { ViewFaceComponent } from './pages/faces/view-face/view-face.component';
import { PairsPipe } from './helpers/PairPipe';
import { DurationPipe } from './helpers/duration-pipe';
import { FaceTreeComponent } from './components/face-tree/face-tree.component';
import { DoubleNamedComponent } from './pages/persons/double-named/double-named.component';
import { SearchLogsComponent } from './pages/other/search-logs/search-logs.component';
import { SearchBadFacesComponent } from './pages/faces/search-bad-faces/search-bad-faces.component';
import { RawFaceSearchComponent } from './pages/faces/raw-face-search/raw-face-search.component';
import { SqlEditorComponent } from './components/sql-editor/sql-editor.component';
import { ProcessingComponent } from './pages/other/settings/processing/processing.component';
import { ElvisComponent } from './pages/other/settings/elvis/elvis.component';
import { FaceMatchingComponent } from './pages/other/settings/face-matching/face-matching.component';
import { PersonMatchingComponent } from './pages/other/settings/person-matching/person-matching.component';
import { ResetSystemComponent } from './pages/other/settings/reset-system/reset-system.component';
import { RawPersonSearchComponent } from './pages/persons/raw-search/raw-search.component';
import { DelayedResponsesInterceptor } from './helpers/delayedresponses.interceptor';
import { ShowPersonFaceTreeComponent } from './pages/persons/show-person-face-tree/show-person-face-tree.component';
import { ShowFaceBranchComponent } from './pages/faces/show-face-branch/show-face-branch.component';
import { FailedImagesComponent } from './pages/other/failed-images/failed-images.component';
import { KnownNamesComponent } from './pages/other/known-names/known-names.component';
import { SearchComponent } from './pages/persons/search/search.component';
import { GridComponent } from './pages/test/grid/grid.component';
import { ListNotFoundComponent } from './pages/other/list-not-found/list-not-found.component';
import { IgnoredComponent } from './pages/persons/ignored/ignored.component';
import { PotentialDuplicatesComponent } from './pages/persons/potential-duplicates/potential-duplicates.component';
import { ComparePersonsComponent } from './pages/persons/compare-persons/compare-persons.component';
import { ManageComponent } from './pages/bugreports/manage/manage.component';
import { ListAllComponent } from './pages/bugreports/components/list-all/list-all.component';
import { EditComponent } from './pages/bugreports/components/edit/edit.component';
import { ListBatchJobsComponent } from './pages/other/batch/list-batch-jobs/list-batch-jobs.component';
import { MomentModule } from 'ngx-moment';
import { FacetreeRootsComponent } from './pages/persons/facetree-roots/facetree-roots.component';
import { CompareLogsComponent } from './pages/other/compare-logs/compare-logs.component';
import { NumberSuffixPipe } from './components/number-suffix/number-suffix.pipe';
import { ElvisPushComponent } from './pages/other/elvis-push/elvis-push.component';
import { NgBytesPipeModule } from 'angular-pipes';
import { SubscriptionLevelComponent } from './pages/other/subscription-level/subscription-level.component';
import { ImageRoutingComponent } from './pages/other/image-routing/image-routing.component';
import { EditorWrapper } from './pages/other/image-routing/editor-wrapper';
import {ContextMenuModule} from '@perfectmemory/ngx-contextmenu';


@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    LatestPhotosComponent,
    ViewPhotoComponent,
    TopMenuComponent,
    GravatarComponent,
    ManualUploadComponent,
    DropZoneDirective,
    LoginComponent,
    ViewPersonsComponent,
    StatisticsComponent,
    DatamodelComponent,
    PersonDetailsComponent,
    ProfileSettingsComponent,
    InlineLoaderComponent,
    MergePersonsComponent,
    VerificationQueueComponent,
    DashboardComponent,
    ListUsersComponent,
    ScanS3BucketComponent,
    PersonSelectorComponent,
    FaceMenuComponent,
    MonitoringComponent,
    ViewFaceComponent,
    PairsPipe,
    FaceTreeComponent,
    ShowFaceBranchComponent,
    DoubleNamedComponent,
    SearchLogsComponent,
    SearchBadFacesComponent,
    RawFaceSearchComponent,
    SqlEditorComponent,
    ProcessingComponent,
    ElvisComponent,
    FaceMatchingComponent,
    PersonMatchingComponent,
    ResetSystemComponent,
    RawPersonSearchComponent,
    ShowPersonFaceTreeComponent,
    FailedImagesComponent,
    KnownNamesComponent,
    SearchComponent,
    GridComponent,
    ListNotFoundComponent,
    IgnoredComponent,
    PotentialDuplicatesComponent,
    ComparePersonsComponent,
    ManageComponent,
    ListAllComponent,
    EditComponent,
    ListBatchJobsComponent,
    DurationPipe,
    FacetreeRootsComponent,
    CompareLogsComponent,
    NumberSuffixPipe,
    ElvisPushComponent,
    EditComponent,
    SubscriptionLevelComponent,
    ImageRoutingComponent,
    EditorWrapper,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgMathPipesModule,
    NgBytesPipeModule,
    NgbDatepickerModule,
    MomentModule,
    FormsModule,
    SweetAlert2Module.forRoot(),
    ContextMenuModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler
    },
    WindowRef,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DelayedResponsesInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'en-US' }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
