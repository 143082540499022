<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <h1>Settings</h1>
                  <h3>{{currentUser.fullname}}</h3>
                  <h6>{{currentUser.email}}</h6>
                  <h6>Customer: {{customer}}</h6>
                  <h6>Level: {{levels[currentLevel]}}</h6>
                  <h6>Username: {{currentUser.username}}</h6>
                  <h6>Member of:</h6>
                  <div *ngFor="let g of currentUser.groups" class="badge badge-primary">{{g}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div>
                    <h2>Current Token information</h2>
                    <div class="form-group">
                      Token expires: {{ tokenExpiry | date:'yyyy-MMM-dd  HH:mm:ss' }} ({{ tokenExpiry | amTimeAgo }})
                    </div>
                    <div class="form-group">
                      <button (click)="refreshView()" class="btn btn-outline-primary">Parse Token</button>
                      <button (click)="refreshToken()" class="btn btn-outline-primary"
                        style="margin-left: 20px;">Refresh
                        Token</button>
                    </div>
                    <div class="input-group">
                        <label class="switch">
                          <input type="checkbox" [(ngModel)]="currentUser.rememberMe" (change)="updateRememberMe()">
                          <span class="slider round"></span>
                        </label>
                        <p>Keep me logged in</p>
                      </div>
                    <div class="form-group">
                      <app-loader *ngIf="showLoader"></app-loader>
                      <h2 style="color: red">{{status}}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>
  </div>
</div>