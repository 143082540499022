import { Nationality } from '../model/Nationality';


export class VerificationQueueState {

    public showAlreadyVerified = false;
    public selectedRegion: Nationality;
    public offset = 0;
    public limit = 100;
    public order: 'created_desc' | 'created_asc' | 'name' = 'created_desc';
    public orderText = 'Process Date - Newest First';

}
