import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { S3CrawlerParams } from '../model/S3CrawlerParams';
import { S3TestResponse } from '../model/S3TestResponse';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class S3crawlerService {

  public url = 'v1';

  constructor(
    private http: HttpClient
  ) { }

  testSettings(values: S3CrawlerParams): Observable<S3TestResponse> {
    return this.http.post<S3TestResponse>(this.url + '/s3crawler/test', values);
  }

  startCrawler(values: S3CrawlerParams): Observable<string> {
    return this.http.post<string>(this.url + '/s3crawler/start', values);
  }

  loadHistory(): Observable<S3CrawlerParams[]> {
    return this.http.get<S3CrawlerParams[]>(this.url + '/s3crawler/history?v=' + new Date().getTime());
  }

}
