import { Component, OnInit, ViewChild } from '@angular/core';
import { ProcessedImage } from 'src/app/model/ProcessedImage';
import { Router } from '@angular/router';
import { ProcessedImageService } from 'src/app/services/images.service';
import { Nationality } from 'src/app/model/Nationality';
import { APP_BASE_HREF } from '@angular/common';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { IdentifiedFace } from 'src/app/model/IdentifiedFace';
import { FaceLister } from 'src/app/interfaces/FaceLister';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TitleService } from 'src/app/services/title.service';
import {ContextMenuComponent, ContextMenuService} from '@perfectmemory/ngx-contextmenu';


class SortOption {
  name: string;
  orderBy: string;
  orderDirection: 'asc' | 'desc';
}

class ReportName {
  name: string;
  text: string;
}

@Component({
  selector: 'app-latest-photos',
  templateUrl: './latest-photos.component.html',
  styleUrls: ['./latest-photos.component.css']
})
export class LatestPhotosComponent implements OnInit, FaceLister {

  private allimages: ProcessedImage[];
  public images: ProcessedImage[];
  public searchText: string;
  public page: any;
  showLoader = false;
  showFacesLoader = false;
  showIdentified = true;

  // orderDirection: 'asc' | 'desc' = 'desc';
  // order: 'faces' | 'processed' | 'unknown_faces' | 'known_faces' | 'name' = 'created';
  orderText: string;
  regions: Nationality[];
  selectedRegion: string;

  reportText = 'All images';
  reportName = 'latest-all';

  sortOptions: SortOption[];
  reports: ReportName[];

  @ViewChild('faceMenu') contextMenu: ContextMenuComponent<any>;
  order: SortOption;

  constructor(
    public imagesService: ProcessedImageService,
    private router: Router,
    private contextMenuService: ContextMenuService<any>,
    private notificationService: NotificationsService,
    private titleService: TitleService,
    public authenticationService: AuthenticationService) {
      this.sortOptions = [];
      this.reports = [];

      this.reports.push({
        name: 'latest',
        text: 'Images with faces'
      });
      this.reports.push({
        name: 'latest-all',
        text: 'All images'
      });
      this.reports.push({
        name: 'no-humans',
        text: 'Images without humans'
      });
      this.reports.push({
        name: 'not-elvis',
        text: 'Images not found in DAM'
      });
      this.reports.push({
        name: 'no-faces',
        text: 'Images without faces detected'
      });
      this.reports.push({
        name: 'marked',
        text: 'Images marked as interesting'
      });

      this.sortOptions.push({
        name: 'Number of Faces - Ascending',
        orderBy: 'faces',
        orderDirection: 'asc'
      });
      this.sortOptions.push({
        name: 'Number of Faces - Descending',
        orderBy: 'faces',
        orderDirection: 'desc'
      });
      this.sortOptions.push({
        name: 'Number of unknown Faces - Ascending',
        orderBy: 'unknown_faces',
        orderDirection: 'asc'
      });
      this.sortOptions.push({
        name: 'Number of unknown Faces - Descending',
        orderBy: 'unknown_faces',
        orderDirection: 'desc'
      });
      this.sortOptions.push({
        name: 'Number of known Faces - Ascending',
        orderBy: 'known_faces',
        orderDirection: 'asc'
      });
      this.sortOptions.push({
        name: 'Number of known Faces - Descending',
        orderBy: 'known_faces',
        orderDirection: 'desc'
      });
      this.sortOptions.push({
        name: 'Last Processed Time - Ascending',
        orderBy: 'processed',
        orderDirection: 'asc'
      });
      this.sortOptions.push({
        name: 'Last Processed Time - Descending',
        orderBy: 'processed',
        orderDirection: 'desc'
      });
      this.sortOptions.push({
        name: 'First Seen Time - Ascending',
        orderBy: 'created',
        orderDirection: 'asc'
      });
      this.order = {
        name: 'First Seen Time - Descending',
        orderBy: 'created',
        orderDirection: 'desc'
      };
      this.order = this.order;
      this.orderText = this.order.name;
      this.sortOptions.push(this.order);
      this.sortOptions.push({
        name: 'Filename - Ascending',
        orderBy: 'name',
        orderDirection: 'asc'
      });
      this.sortOptions.push({
        name: 'Filename - Descending',
        orderBy: 'name',
        orderDirection: 'desc'
      });
  }

  offset = 0;
  limit = 25;
  title = 'Fetching latest images...';

  initialAttempts = 0;

  ngOnInit() {
    this.imagesService.getNationalities().subscribe(n => {
      this.regions = n.filter(r => this.authenticationService.hasAccessTo(r.code));
      if (!this.authenticationService.hasAdminAccess()) {
        this.selectedRegion = this.regions[0].code;
      } else {
        this.selectedRegion = 'All';
      }
    });
    console.log('Calling from ngOnInit');
    this.getImages();
    this.initialAttempts = 1;
    this.titleService.setTitle('Latest Images');
  }


  showFaceContextMenu($event: MouseEvent, face: IdentifiedFace, image: ProcessedImage): void {
    console.log('Showing face menu for #' + face.id);
    face.image = image;
    this.contextMenuService.show(
       this.contextMenu,
      {
      x: $event.x,
      y: $event.y,
      value: face,
    });
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }



  public loadMoreImages() {
    this.offset += this.limit;
    this.showLoader = true;
    console.log('Fetching more images');
    this.imagesService.getLatestImages(this.reportName,  this.order.orderBy, this.order.orderDirection, this.selectedRegion, this.limit, this.offset).subscribe(
      (moreImg) => {
        this.allimages = this.allimages.concat(moreImg);
        this.updateShown();
      }
    );
  }

  public getImages(): void {
    console.log('Updating images');
    this.images = null; // for show spinner each time
    this.offset = 0;
    this.showLoader = true;
    this.imagesService.getLatestImages(this.reportName, this.order.orderBy, this.order.orderDirection, this.selectedRegion, this.limit, this.offset).subscribe(images => {
      console.log('Images fetched');
      this.allimages = images;
      this.updateShown();
    });
  }

  public showImageDetails(imageId: string) {
    this.router.navigate(['images/' + imageId]);
  }

  public showPersonDetails(person) {
    if (person.id) {
      this.router.navigate(['persons/' + person.id]);
    } else {
      this.notificationService.showInfo('Face not assigned to Person', 'Face has not yet been assigned to a Person', 'warning', 'Close', false);
    }
  }

  setReport(report: ReportName) {
    this.reportName = report.name;

    this.reportText = report.text;
    console.log('Calling from setReport');
    this.getImages();
  }

  setRegion(n: string) {
    this.selectedRegion = n;
    console.log('Calling from setRegion');
    this.getImages();
  }

  toggleShowIdentified() {
    this.showIdentified = !this.showIdentified;
    console.log('Calling from toggleShowIdentified');
    this.getImages();
  }

  setOrder(order: SortOption) {
    this.order = order;
    this.orderText = order.name;

    console.log('Calling from setOrder');
    this.getImages();
  }

  hasIdentifiedFace(image: ProcessedImage): boolean {
    if (!image.faces) {
      return false;
    }

    return image.faces.filter(f => (f.person.name)).length > 0;
  }

  updateShown() {
    console.log('Updating shown');
    this.images = [];
    if (!this.allimages || this.allimages.length === 0) {
      this.showLoader = false;
      return;
    } else {
      this.images = this.allimages;
    }
    this.showLoader = false;
  }

  removeFace(id: number) {
    const image = this.allimages.filter((i) => {
      return (i.faces.filter((f) => f.id === id).length > 0);
    })[0];

    image.faces = image.faces.filter((f) => f.id !== id);
    image.showLoader = false;

    this.updateShown();
  }

  updateFace(face: IdentifiedFace) {
    const image = this.allimages.filter((i) => {
      return (i.faces.filter((f) => f.id === face.id).length > 0);
    })[0];

    image.faces = image.faces.filter((f) => f.id !== face.id);
    image.faces.push(face);
    image.showLoader = false;

    this.updateShown();
  }

  public drawBoundingBox(image: ProcessedImage, box: any) {
    console.log(JSON.stringify(box));

    const c: HTMLCanvasElement = document.getElementById('canvas_' + image.id) as HTMLCanvasElement;
    const img: HTMLImageElement = document.getElementById('img_' + image.id) as HTMLImageElement;


    c.height = img.height;
    c.width = img.width;


    c.hidden = false;
    const ctx = c.getContext('2d');

    ctx.clearRect(0, 0, c.width, c.height);
    ctx.imageSmoothingEnabled = true;
    ctx.beginPath();
    ctx.rect(c.width * box.x, c.height * box.y, c.width * box.width, c.height * box.height);
    ctx.lineWidth = 2;
    ctx.strokeStyle = 'blue';

    ctx.stroke();
  }

  public removeBoundingBox(image: ProcessedImage) {
    const c: HTMLCanvasElement = document.getElementById('canvas_' + image.id) as HTMLCanvasElement;
    const img: HTMLImageElement = document.getElementById('img_' + image.id) as HTMLImageElement;

    c.hidden = true;
    img.hidden = false;
    // c. = 'display: hidden';
  }

  // public onPageChanged(event) {
  //   this.page = event;
  //   this.getImages();
  //   document.getElementById('main-content').scrollTop = 0;
  // }

  // public openImageDialog(user) {
  //   //   const dialogRef = this.dialog.open(ProcessedImageComponent, {
  //   //       data: user
  //   //   });

  //   //   dialogRef.afterClosed().subscribe(u => {
  //   //       if (u) {
  //   //           // (user.id) ? this.updateUser(user) : this.addUser(user);
  //   //       }
  //   //   });
  // }

  public activeFaces(faces: IdentifiedFace[]): IdentifiedFace[] {
    return faces.filter(f => (!f.ignored && !f.person.ignored));
  }

  public countIgnoredFaces(image: ProcessedImage): number {
    if (!image.faces) {
      return 0;
    }
    return image.faces.filter(f => f.ignored).length;
  }

}
