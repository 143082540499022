import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CustomerManager } from '../model/customer-manager';
import { SubscriptionLevel } from '../model/Environment';
import { AuthenticationService } from '../services/authentication.service';


@Injectable({ providedIn: 'root' })
export class SilverGuard implements CanActivate {
    constructor(
        private router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentLevel = CustomerManager.SubscriptionLevel;
        if (currentLevel && currentLevel >= SubscriptionLevel.SILVER) {
            // level accepted
            return true;
        }

        // not accepted
        this.router.navigate(['/subscription'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
