import { Component, OnInit } from '@angular/core';
import { Person } from 'src/app/model/Person';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessedImageService } from 'src/app/services/images.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TitleService } from 'src/app/services/title.service';
import { FaceTreeRoot } from 'src/app/model/FaceTreeRoot';

@Component({
  selector: 'app-facetree-roots',
  templateUrl: './facetree-roots.component.html',
  styleUrls: ['./facetree-roots.component.css']
})
export class FacetreeRootsComponent implements OnInit {

  title = '';
  showLoader = true;
  personId: number;
  person: Person;

  roots: FaceTreeRoot[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private imageService: ProcessedImageService,
    private notifications: NotificationsService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.getData();
    this.imageService.getPerson(this.personId).subscribe(p => {
      this.person = p;
    });
    this.route.params.subscribe((p) => this.getData());
  }

  getData() {
    this.personId = Number(this.route.snapshot.paramMap.get('personId'));
    this.showLoader = true;
    this.title = 'Looking for facetree roots for person #' + this.personId;
    this.titleService.setTitle('FaceTree Roots for Person #' + this.personId);

    this.imageService.searchFaceRoots(this.personId).subscribe(r => {
      this.roots = r;
      this.roots.sort((a, b) => b.childCount - a.childCount);

      this.title = 'Found ' + r.length + ' facetree roots for #' + this.personId;

      this.showLoader = false;
    });
  }

  consolidate() {
    this.showLoader = true;
    this.title = 'Attempting to consolidate facetree roots for person #' + this.personId;
    this.imageService.fixFaceRoots(this.personId).subscribe(r => {
      this.roots = r;
      this.roots.sort((a, b) => b.childCount - a.childCount);

      this.title = 'Found ' + r.length + ' facetree roots for #' + this.personId;

      this.showLoader = false;
    });
  }

}
