import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { BugReport } from '../model/BugReport';

@Injectable({
  providedIn: 'root'
})
export class BugReportService {

  public url = 'v1';

  constructor(public http: HttpClient,
              private authenticationService: AuthenticationService) { }

  listAll(parentId?: number): Observable<BugReport[]> {
    if (parentId) {
      return this.http.get<BugReport[]>(this.url + '/bugreports?parentId=' + parentId + '&v=' + Date.now());
    } else {
      return this.http.get<BugReport[]>(this.url + '/bugreports?v=' + Date.now());
    }
  }

  save(bug: BugReport): Observable<void> {
    if (bug.id) {
      return this.http.post<void>(this.url + '/bugreports/' + bug.id, bug);
    } else {
      return this.http.put<void>(this.url + '/bugreports', bug);
    }
  }

}
