<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
      <div class="content-wrapper">
        <h1>{{title}}</h1>
        <div class="row">
          <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
            <div class="card">
              <div class="card-body">
                <app-loader *ngIf="!logs"></app-loader>
                <table class="table table-stribed">
                  <thead>
                    <tr>
                      <th style="width: 300px;">Time</th>
                      <th>Event</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let r of logs;let j = index">
                      <td>{{ r.time | date:'yyyy-MMM-dd HH:mm:ss' | uppercase }} ({{ r.time | amTimeAgo }})</td>
                      <td>
                        <div *ngIf="r.message.length > maxLength" style="cursor: pointer;" id="partial_{{j}}" (click)="showDetails(j)">
                            <pre>{{r.message.substring(0, maxLength)}} ...
            [click to expand]</pre>
                          </div>
                          <div *ngIf="r.message.length > maxLength" id="full_{{j}}" [hidden]="true">
                            <a style="cursor: pointer;" (click)="showDetails(j)">Collapse</a>
                            <pre>{{r.message}}</pre>
                            <a style="cursor: pointer;" (click)="showDetails(j)">Collapse</a>
                          </div>
                          <div *ngIf="r.message.length <= maxLength"><pre>{{r.message}}</pre></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>