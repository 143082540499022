<div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
      <div class="row w-100 mx-auto">
        <div class="col-lg-4 mx-auto">
          <img src="assets/images/metatagger-logo-01.png" width="400" />
          <h3>Subscription Level</h3>
            <p>{{ errorText }}</p>
          <p class="footer-text text-center"><span
              class="text-muted d-block text-center text-sm-left d-sm-inline-block">Created by <a
                href="https://www.keycore.dk/" target="_blank">KeyCore</a> {{ year}} - All rights reserved -
              Advanced and intelligent AWS solutions <i class="icon-heart text-danger"></i></span></p>
        </div>
      </div>
    </div>
  </div>
