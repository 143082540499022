import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { S3CrawlerParams } from 'src/app/model/S3CrawlerParams';
import { S3crawlerService } from 'src/app/services/s3crawler.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-scan-s3-bucket',
  templateUrl: './scan-s3-bucket.component.html',
  styleUrls: ['./scan-s3-bucket.component.css']
})
export class ScanS3BucketComponent implements OnInit {

  public showLoader: boolean;

  settings: S3CrawlerParams;
  history: S3CrawlerParams[];
  verified = false;

  constructor(
    public authService: AuthenticationService,
    private notificationService: NotificationsService,
    private s3CrawlerService: S3crawlerService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.settings = new S3CrawlerParams();
    this.settings.bucketRegion = 'eu-west-1';
    this.titleService.setTitle('Settings: Scan S3');
    this.s3CrawlerService.loadHistory().subscribe((h) => this.history = h);
  }


  testSettings() {
    this.showLoader = true;
    this.settings.continuationToken = undefined;
    this.settings.started = undefined;
    this.settings.startedBy = undefined;
    this.s3CrawlerService.testSettings(this.settings).subscribe(res => {
      if (res.verified) {
        console.log('Test was successful');
        this.verified = true;
        this.notificationService.showInfo('Test was successful', res.status, 'success', 'Close', false);
      } else {
        console.log('Test failed: ' + res.status);
        this.notificationService.showInfo('Access Check Failed', res.status, 'error', 'Close', false);
        this.verified = false;
      }
      this.showLoader = false;
    });
  }

  startCrawler() {
    this.showLoader = true;
    this.s3CrawlerService.startCrawler(this.settings).subscribe(res => {
      this.notificationService.showInfo('Started', res, 'success', 'Close', false);
      this.showLoader = false;
    });

  }

  setValues(v: S3CrawlerParams) {
    this.settings = JSON.parse(JSON.stringify(v));
  }

}
