<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <h1>Force update of image details in Elvis</h1>
      <div class="row">
        <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Define images to include in task</h4>
              <p class="card-description">Select which images should be pushed to Elvis</p>
              <form class="form-sample">
                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-5 col-form-label" for="bucketName">Include images already pushed to Elvis</label>
                    <div class="form-check mx-sm-2">
                      <label class="form-check-label">
                        <input type="checkbox" name="reprocess" class="form-check-input" [(ngModel)]="includeAlreadyPushed">
                        <i class="input-helper"></i>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-5 col-form-label" for="bucketName">Include images with no faces detected</label>
                    <div class="form-check mx-sm-2">
                      <label class="form-check-label">
                        <input type="checkbox" name="reprocess" class="form-check-input" [(ngModel)]="includeNoFaces">
                        <i class="input-helper"></i>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-5 col-form-label" for="bucketName">Only include images uploaded after</label>
                    <div class="col-4">
                      <div id="datepicker-popup" class="input-group date datepicker">
                        <input type="text" class="form-control" [(ngModel)]="dateFrom" name="dateFrom" style="max-width: 80 px;" ngbDatepicker #df="ngbDatepicker">
                        <span class="input-group-addon input-group-append border-left" (click)="df.toggle()">
                          <span class="icon-calendar input-group-text"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-5 col-form-label" for="bucketName">Only include images uploaded before</label>
                    <div class="col-4">
                      <div id="datepicker-popup" class="input-group date datepicker">
                        <input type="text" class="form-control" [(ngModel)]="dateTo" name="dateTo" style="max-width: 80 px;" ngbDatepicker #dt="ngbDatepicker">
                        <span class="input-group-addon input-group-append border-left" (click)="dt.toggle()">
                          <span class="icon-calendar input-group-text"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <button class="btn btn-outline-primary mb-2" (click)="startPushJob()" type="submit"
                [disabled]="!authService.hasAdminAccess() || status" style="margin: 0px;">Start
                Pushing Elvis Updates</button>
                <br />
                <span style="color: #FF0000">{{ status  }}</span>
              <div class="form-group">
                <app-loader *ngIf="showLoader"></app-loader>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Elvis Force Push Jobs</h4>
              <p class="card-description">Control if push jobs are allowed to run. Disabling will stop all currently running jobs and prevent
                 new ones from being started. Already queued updates will still be relayed to Elvis - depending on Elvis queue settings.</p>
                 <div class="form-group">
                  <label>Process Force Push Jobs</label>
                  <app-inline-loader *ngIf="toggleShowLoader"></app-inline-loader>
                  <div class="input-group" *ngIf="!toggleShowLoader">
                    <label class="switch">
                      <input type="checkbox" [(ngModel)]="jobsEnabled" (change)="toggleJobStatus()">
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>