import { Component, OnInit, Input } from '@angular/core';
import {Md5} from 'ts-md5/dist/md5';

@Component({ 
  selector: 'app-gravatar',
  templateUrl: './gravatar.component.html',
  styleUrls: ['./gravatar.component.css']
})
export class GravatarComponent implements OnInit {

  constructor() { }

  @Input() email: string;

  hash: string;

  ngOnInit() {
    const md5 = new Md5();
    this.hash = md5.appendStr(this.email).end().toString();
  }

}
 