import { SubscriptionLevel } from "./Environment";


export class CustomerManager {

    private static customer: string;

    public static get CustomerName(): string {
        return CustomerManager.customer;
    }

    public static set CustomerName(v: string) {
        CustomerManager.customer = v;
    }

    private static level: SubscriptionLevel;

    public static get SubscriptionLevel(): SubscriptionLevel {
        return CustomerManager.level;
    }

    public static set SubscriptionLevel(v: SubscriptionLevel) {
        CustomerManager.level = v;
    }

}
