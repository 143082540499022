<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
      <div class="content-wrapper">
        <h1>Adjust System wide settings</h1>
        <div class="row">
          <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-6" *ngIf="true">
                    <h3>Face match settings</h3>
                    <app-loader *ngIf="showLoader"></app-loader>
                    <div *ngIf="currentSettings">
                      <h4>Face Quality</h4>
                      <div class="form-group">
                        <label>Only use face if confidence it is a face is at least</label>
                        <div class="input-group">
                          <input class="form-control" type="number" [(ngModel)]="currentSettings.faceConfidenceThreshold"
                            max="100" step="0.01" style="text-align: right" [readOnly]="!authService.hasAdminAccess()" />
                          <div class="input-group-append">
                            <span class="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Only use face if sharpness is at least</label>
                          <div class="input-group">
                        <input class="form-control" type="number" [(ngModel)]="currentSettings.sharpnessThreshold" max="100"
                          step="1" style="text-align: right" [readOnly]="!authService.hasAdminAccess()" />
                          <div class="input-group-append">
                            <span class="input-group-text">%</span>
                          </div>
                          </div>
                      </div>
                      <div class="form-group">
                        <label>Only use face if brightness is at least</label>
                        <div class="input-group">
                          <input class="form-control" type="number" [(ngModel)]="currentSettings.brightnessThreshold"
                            max="100" step="0.1" style="text-align: right" [readOnly]="!authService.hasAdminAccess()" />
                          <div class="input-group-append">
                            <span class="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                      <h4>Relative values (relative to other faces on image)</h4>
                      <div class="form-group">
                        <label>Only use face if its difference in sharpness relative to the highest valued face on image is at most</label>
                        <div class="input-group">
                          <input class="form-control" type="number" [(ngModel)]="currentSettings.maximumSharpnessDifference" step="5" max="100"
                          style="text-align: right" [readOnly]="!authService.hasAdminAccess()" />
                          <div class="input-group-append">
                            <span class="input-group-text">pp</span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Only use face if its size relative to the largest face on image is at least</label>
                        <div class="input-group">
                          <input class="form-control" type="number" [(ngModel)]="currentSettings.minimumRelativeFaceSize" step="5" max="100"
                            style="text-align: right" [readOnly]="!authService.hasAdminAccess()" />
                          <div class="input-group-append">
                            <span class="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                      <h4>Use Image Labels</h4>
                      <div class="form-group">
                        <label>Only use face if a human was detected in image with a confidence of at least</label>
                        <div class="input-group">
                          <input class="form-control" type="number" [(ngModel)]="currentSettings.humanInImageThreshold" step="1" max="100"
                            style="text-align: right" [readOnly]="!authService.hasAdminAccess()" />
                          <div class="input-group-append">
                            <span class="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                      <h4>Face Pose Values</h4>
                      <div class="form-group">
                        <label>Only use face if absolute face position Roll is less than (0 to disable this filter)</label>
                        <div class="input-group">
                          <input class="form-control" type="number" [(ngModel)]="currentSettings.maxRoll" step="1" max="180"
                            style="text-align: right" [readOnly]="!authService.hasAdminAccess()" />
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Only use face if absolute face position Yaw is less than (0 to disable this filter)</label>
                        <div class="input-group">
                          <input class="form-control" type="number" [(ngModel)]="currentSettings.maxYaw" step="1" max="180"
                            style="text-align: right" [readOnly]="!authService.hasAdminAccess()" />
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Only use face if absolute face position Pitch is less than (0 to disable this filter)</label>
                        <div class="input-group">
                          <input class="form-control" type="number" [(ngModel)]="currentSettings.maxPitch" step="1" max="180"
                            style="text-align: right" [readOnly]="!authService.hasAdminAccess()" />
                        </div>
                      </div>
                      <div class="form-group">
                        <button class="btn btn-outline-primary btn-block" (click)="save()" *ngIf="!saveInProgress"
                          type="submit" [disabled]="!authService.hasAdminAccess()" style="margin: 0px;">Save</button>
                        <app-loader *ngIf="saveInProgress"></app-loader>
                      </div>
                    </div>
                  </div>
                  <div class="col-6" *ngIf="true">
                    <h3>Face Pose Explained</h3>
                    <img src="/assets/images/roll_figure.png" style="align-items: center; vertical-align: middle; width: 100%;"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>