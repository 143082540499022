<h2 *ngIf="showLoader">{{text}}</h2>
<app-loader *ngIf="showLoader"></app-loader>
<div class="chart" id="facetree">
    <h2 *ngIf="errorText" style="color: red;">{{errorText}}</h2>
</div>
<app-face-menu #faceMenu [listener]="this"
    [showAssignItem]="false" 
    [showSplitItem]="false" 
    [showDeleteItem]="false" 
    [showReprocessItem]="false" 
    [showSplitBranchItem]="true"
    ></app-face-menu>
