

export class TreeNode {

    image: string;
    children?: TreeNode[];
    collapsed: boolean;
    collapsable: boolean;
    text?: {
        name?: string;
        title?: string;
        desc?: string;
    };
    HTMLid?: string;
    HTMLclass?: string;
    innerHTML?: string;

}

export class TreeChartConfig {

    container: string;
    animateOnInit: boolean;

    node: {
        collapsable: boolean;
    };

    animation: {
        nodeAnimation: string;
        nodeSpeed: number;
        connectorsAnimation: string,
        connectorsSpeed: number;
    };

    connectors: {
        type?: 'curve' | 'bCurve' | 'step' | 'straight';
        style?: {
            'arrow-end'?: string;
            cursor?: string;
            fill?: string;
            'fill-opacity'?: number;
            opacity?: number;
            stroke?: string;
            'stroke-dasharray'?: string;
            'stroke-linecap'?: string;
            'stroke-opacity'?: number;
            'stroke-width'?: number;
        }

        stackIndent?: number
    };

    callback?: {
        onCreateNode?: () => void;
        onCreateNodeCollapseSwitch?: () => void;
        onAfterAddNode?: () => void;
        onBeforeAddNode?: () => void;
        onAfterPositionNode?: () => void;
        onBeforePositionNode?: () => void;
        onToggleCollapseFinished?: () => void;
        onAfterClickCollapseSwitch?: () => void;
        onBeforeClickCollapseSwitch?: () => void;
        onTreeLoaded?: () => void;
    };
}

export class TreeModel {

    constructor() {
        this.nodeStructure = new TreeNode();
        this.chart = new TreeChartConfig();
        this.chart.animation = {
            nodeAnimation: 'easeOutBounce',
            nodeSpeed: 50,
            connectorsAnimation: 'bounce',
            connectorsSpeed: 50
        };
    }

    nodeStructure: TreeNode;
    chart: TreeChartConfig;

}

