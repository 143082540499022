<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12">
          <h1>Persons</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                    <span>Show named: </span>
                    <label class="switch">

                      <input type="checkbox" [(ngModel)]="state.showIdentified" (change)="getPersons()">
                      <span class="slider round"></span>
                    </label>
                    <span> Show AI named: </span>
                    <label class="switch">

                      <input type="checkbox" [(ngModel)]="state.showAIIdentified" (change)="getPersons()">
                      <span class="slider round"></span>
                    </label>

                  <div class="dropdown" style="display: inline-block;">
                      <button type="button" class="btn btn-outline-primary icon-btn dropdown-toggle"
                      id="selectOrderButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Order by {{state.orderText}}
                    </button>

                    <div class="dropdown-menu" aria-labelledby="selectOrderButton">
                      <h6 class="dropdown-header">Order persons by</h6>
                      <a class="dropdown-item" (click)="setOrder('face_count')">Most faces</a>
                      <a class="dropdown-item" (click)="setOrder('created')">Newest</a>
                      <a class="dropdown-item" (click)="setOrder('name')">Name</a>
                    </div>
                  </div>
                  <div class="dropdown" style="display: inline-block;">
                      <button type="button" class="btn btn-outline-primary icon-btn dropdown-toggle"
                      id="selectNationalityButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Filter by region <i *ngIf="state.selectedNationality && state.selectedNationality != 'All'" class="flag-icon flag-icon-{{state.selectedNationality}}"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="selectNationalityButton">
                      <h6 class="dropdown-header">Show</h6>
                      <a [ngClass]="(state.selectedNationality == 'all') ? 'dropdown-item active' : 'dropdown-item'" (click)="setNationality('all')">All</a>
                      <a class="dropdown-item" (click)="setNationality(null)">Unknown</a>
                      <div class="dropdown-divider"></div>
                      <a [ngClass]="(state.selectedNationality == n.code) ? 'dropdown-item active' : 'dropdown-item'"
                        *ngFor="let n of state.nationalities" (click)="setNationality(n.code)"><i
                        class="flag-icon flag-icon-{{n.code}}"></i> {{n.name}}</a>
                      </div>
                    </div>
                    <a class="btn btn-outline-primary" [routerLink]="'/persons/double-named'">Show Persons named more than once</a>
                    <a class="btn btn-outline-primary" [routerLink]="'/persons/search'" style="margin-left: 10px;">Search named Persons</a>
                    <a class="btn btn-outline-primary" [routerLink]="'/persons/ignored'" style="margin-left: 10px;">View Ignored Persons</a>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <app-loader *ngIf="showLoader"></app-loader>
      <div class="row" *ngIf="persons">
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <h2 *ngIf="persons.length > 0">Showing {{persons.length}} persons - click to edit</h2>
                  <h2 *ngIf="persons.length == 0">No Persons to show</h2>
                  <div class="row persons-grid">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12" *ngFor="let person of persons">
                       <a [routerLink]="'/persons/' + person.id">
                        <figure class="effect-text-in" style="height: 245px;">
                          <img [src]="'processed-images/faces/' + person.primaryFaceId + '.png'" />
                          <figcaption>
                            <h4 style="cursor: pointer;" [ngClass]="(person.name) ? 'known' : 'unknown'">
                              <i class="flag-icon flag-icon-{{person.nationality}}" *ngIf="person.nationality"></i>
                              {{ (person.name) ? person.name : 'Unknown (' + person.facesCount + ' faces)' }}</h4>
                            <p>Person id: {{ person.id }}<br /> So far identified on {{ person.facesCount }}
                              image{{ (person.facesCount > 1 ? 's' : '')}}.<br />Last seen on
                              {{ person.lastImageSeen | date:'yyyy-MMM-dd HH:mm' | uppercase  }}
                              {{ person.description }}</p>
                          </figcaption>
                        </figure>
                       </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                    <app-loader *ngIf="showLoader"></app-loader>
                  <button class="btn btn-outline-primary" (click)="loadMorePersons()">Load More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>