import { Component, OnInit, ViewChild } from '@angular/core';
import { IdentifiedFace } from 'src/app/model/IdentifiedFace';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessedImageService } from 'src/app/services/images.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ProcessedImage } from 'src/app/model/ProcessedImage';
import { FaceRelation } from 'src/app/model/FaceRelation';
import { FaceLandmark } from 'src/app/model/FaceLandmark';
import { FaceMenuComponent } from 'src/app/components/face-menu/face-menu.component';
import { TitleService } from 'src/app/services/title.service';
import { HistoryService } from 'src/app/services/history.service';
import {ContextMenuService} from '@perfectmemory/ngx-contextmenu';

@Component({
  selector: 'app-view-face',
  templateUrl: './view-face.component.html',
  styleUrls: ['./view-face.component.css']
})
export class ViewFaceComponent implements OnInit {

  @ViewChild('faceMenu') contextMenu: FaceMenuComponent;

  title = 'Showing Face Details';
  showLoader = true;
  showFaceMatchLoader = false;
  face: IdentifiedFace;
  faceId: number;

  matchedToPerson = false;
  rootFaceForPerson: FaceRelation;

  matches: FaceRelation[];

  matchSearchResults: FaceRelation[];

  outlineToggle = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private imageService: ProcessedImageService,
    private notifications: NotificationsService,
    private titleService: TitleService,
    private historyService: HistoryService
  ) { }

  ngOnInit() {
    this.route.params.subscribe((p) => this.getFace());
  }

  getFace() {
    this.faceId = Number(this.route.snapshot.paramMap.get('faceId'));
    this.titleService.setTitle('Face #' + this.faceId);
    this.historyService.addEntry('Face #' + this.faceId, '/faces/' + this.faceId);
    this.title = 'Fetching face details...';
    this.imageService.getFace(this.faceId).subscribe(f => {
      this.face = f;
      this.face.landmarks.sort((a, b) => a.kind.localeCompare(b.kind));
      this.title = 'Showing face #' + f.id;

      if (f.person && f.person.id) {
        this.matchedToPerson = true;
      }

      this.matches = [];
      if (f.relations) {
        for (const rel of f.relations) {
          if (!rel.relatedAwsFaceId) {
            this.rootFaceForPerson = rel;
          } else {
            this.matches.push(rel);
          }
        }
        this.matches.sort((a, b) => b.confidence - a.confidence);
      }

      this.showLoader = false;
    });
  }


  showFullFaceContextMenu($event: MouseEvent, face: IdentifiedFace): void {
    console.log('Showing full face menu for #' + face.id);
    this.contextMenu.showAssignItem = true;
    this.contextMenu.showDeleteItem = true;
    this.contextMenu.showDetailsItem = false;
    this.contextMenu.showReprocessItem = true;
    this.contextMenu.showSplitItem = true;
    this.contextMenu.showSplitBranchItem = false;
    this.contextMenu.showContextMenu($event, face);
  }

  showFaceContextMenu($event: MouseEvent, face: FaceRelation): void {
    console.log('Showing face menu for #' + face.relatedFaceId);
    this.contextMenu.showAssignItem = false;
    this.contextMenu.showDeleteItem = false;
    this.contextMenu.showDetailsItem = true;
    this.contextMenu.showReprocessItem = false;
    this.contextMenu.showSplitItem = false;
    this.contextMenu.showSplitBranchItem = false;
    this.contextMenu.showContextMenu($event, { id: face.relatedFaceId } as IdentifiedFace);
  }

  public showPerson(person) {
    this.router.navigate(['persons/' + person.id]);
  }

  public showImage(id) {
    this.router.navigate(['images/' + id]);
  }


  public drawBoundingBox(box: any) {
    const c: HTMLCanvasElement = document.getElementById('imgcanvas') as HTMLCanvasElement;
    const img: HTMLImageElement = document.getElementById('fullimg') as HTMLImageElement;

    if (img) {
      const hfactor = img.height / img.naturalHeight;
      const wfactor = img.width / img.naturalWidth;

      c.height = img.height;
      c.width = img.width;

      c.hidden = false;
      c.style.display = 'block';
      img.hidden = true;

      const ctx = c.getContext('2d');

      const height = img.height;
      const width = img.width;
      ctx.imageSmoothingEnabled = false;
      let w = width * box.width * wfactor;
      let h = height * box.height * hfactor;
      let x = width * box.x * wfactor;
      let y = height * box.y * hfactor;

      x -= (w / 2);
      y -= (h / 2);
      h *= 2;
      w *= 2;

      ctx.clearRect(0, 0, c.width, c.height);

      ctx.drawImage(img, 0, 0, c.width, c.height);
      ctx.beginPath();
      ctx.rect(x, y, w, h);
      ctx.lineWidth = 2;
      ctx.strokeStyle = 'red';
      ctx.stroke();
    }
  }
  public drawLandmark(x: number, y: number) {
    const c: HTMLCanvasElement = document.getElementById('imgcanvas') as HTMLCanvasElement;
    const img: HTMLImageElement = document.getElementById('fullimg') as HTMLImageElement;

    const hfactor = img.height / img.naturalHeight;
    const wfactor = img.width / img.naturalWidth;

    const height = c.height;
    const width = c.width;

    x = x * width;
    y = y * height;

    const size = 10;
    const ctx = c.getContext('2d');

    ctx.beginPath();
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 2;

    ctx.moveTo(x - size, y - size);
    ctx.lineTo(x + size, y + size);

    ctx.moveTo(x + size, y - size);
    ctx.lineTo(x - size, y + size);
    ctx.stroke();
  }

  public doDrawCircle(x: number, y: number) {
    const c: HTMLCanvasElement = document.getElementById('imgcanvas') as HTMLCanvasElement;
    const img: HTMLImageElement = document.getElementById('fullimg') as HTMLImageElement;

    const hfactor = img.height / img.naturalHeight;
    const wfactor = img.width / img.naturalWidth;

    const height = c.height;
    const width = c.width;

    x = x * width;
    y = y * height;

    const size = 10;
    const ctx = c.getContext('2d');

    ctx.beginPath();
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 2;

    ctx.arc(x, y, size, 0, 2 * Math.PI, false);

    ctx.stroke();
  }

  public doDrawDot(x: number, y: number) {
    const c: HTMLCanvasElement = document.getElementById('imgcanvas') as HTMLCanvasElement;
    const img: HTMLImageElement = document.getElementById('fullimg') as HTMLImageElement;

    const hfactor = img.height / img.naturalHeight;
    const wfactor = img.width / img.naturalWidth;

    const height = c.height;
    const width = c.width;

    x = x * width;
    y = y * height;

    const size = 5;
    const ctx = c.getContext('2d');

    ctx.beginPath();
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 2;

    ctx.arc(x, y, size, 0, 2 * Math.PI, false);

    ctx.stroke();
  }

  public doDrawLine(x1: number, y1: number, x2: number, y2: number) {
    const c: HTMLCanvasElement = document.getElementById('imgcanvas') as HTMLCanvasElement;
    const img: HTMLImageElement = document.getElementById('fullimg') as HTMLImageElement;

    const hfactor = img.height / img.naturalHeight;
    const wfactor = img.width / img.naturalWidth;

    const height = c.height;
    const width = c.width;

    x1 = x1 * width;
    y1 = y1 * height;
    x2 = x2 * width;
    y2 = y2 * height;

    const size = 0;
    const ctx = c.getContext('2d');

    ctx.beginPath();
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 2;

    ctx.moveTo(x1 - size, y1 - size);
    ctx.lineTo(x2 + size, y2 + size);

    ctx.stroke();
  }

  public drawMark(m: FaceLandmark) {
    if (m) {
      this.drawLandmark(m.x, m.y);
    }
  }

  public drawCircle(m: FaceLandmark) {
    if (m) {
      this.doDrawCircle(m.x, m.y);
    }
  }

  public drawLine(m: FaceLandmark, m2: FaceLandmark) {
    if (m) {
      this.doDrawLine(m.x, m.y, m2.x, m2.y);
    }
  }

  public drawDot(m: FaceLandmark) {
    if (m) {
      this.doDrawDot(m.x, m.y);
    }
  }

  public outlineFace() {
    if (this.outlineToggle) {
      this.outlineToggle = false;
      this.drawBoundingBox(this.face.imageLocation);
    } else {
      this.drawCircle(this.getDetails('eyeLeft'));
      this.drawCircle(this.getDetails('eyeRight'));
      this.drawDot(this.getDetails('nose'));
      this.drawLine(this.getDetails('mouthLeft'), this.getDetails('mouthRight'));
      this.outlineToggle = true;
    }
  }

  public getDetails(name: string): FaceLandmark {
    return this.face.landmarks.filter(l => l.kind === name)[0];
  }

  removeLandmarks() {
    if (this.face) {
      this.drawBoundingBox(this.face.imageLocation);
    }
  }

  searchFaceMatches() {
    this.showFaceMatchLoader = true;
    this.imageService.searchFaceMatches(this.face.id).subscribe((mat) => {
      this.matchSearchResults = mat;
      this.showFaceMatchLoader = false;
    });
  }

  reprocessFace(face: IdentifiedFace) {
    face.showLoader = true;
    this.imageService.reprocessFace(face).subscribe((updatedFace) => {
      if (face.person.id === updatedFace.person.id) {
        // still same person
        face.personVerified = updatedFace.personVerified;
        face.personVerifiedBy = updatedFace.personVerifiedBy;
        face.personVerifiedDate = updatedFace.personVerifiedDate;

        const msg = 'Face #' + face.id + ' is still attributed to Person #' + updatedFace.person.id + ': '
          + ((updatedFace.person.name) ? updatedFace.person.name : 'Not yet named');
        this.notifications.showInfo('Face seems matched correctly!', msg, 'info', 'Continue', false);

      } else {
        // face now attributed to new person
        const msg = 'Face from image #' + face.image.id + ' is now attributed to: '
          + ((updatedFace.person && updatedFace.person.name) ? updatedFace.person.name : 'Person ' + updatedFace.person.id);
        this.notifications.showInfo(
          'Face was rematched!',
          msg,
          'success', 'Continue', false);
      }
      face.showLoader = false;
    }, (err) => {
      console.error(err);
      face.showLoader = false;
    });
  }

}
