import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UsersService } from 'src/app/services/users.service';
import { MetataggerUser } from 'src/app/model/MetataggerUser';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.css']
})
export class ListUsersComponent implements OnInit {

  users: MetataggerUser[];

  constructor(
    public authService: AuthenticationService,
    private usersService: UsersService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Settings: Users');
    this.usersService.listUsers().subscribe(u => this.users = u);
  }

}
