import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/model/user';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ErrorLogHandlerService } from 'src/app/services/error-log-handler.service';
import { CaughtError } from 'src/app/model/CaughtError';
import $ from 'jquery';
import { CognitoService } from 'src/app/services/cognito.service';
import { HistoryService } from 'src/app/services/history.service';
import { HistoryEntry } from 'src/app/model/history-entry';
import { SubscriptionLevel } from 'src/app/model/Environment';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css']
})
export class TopMenuComponent implements OnInit {

  public user: User;

  public levels = SubscriptionLevel;

  elvisId = '';
  errorsShown: CaughtError[];
  lastCount = 0;
  showTokenLoader = false;
  history: HistoryEntry[];

  constructor(
    public authenticationService: AuthenticationService,
    private cognitoService: CognitoService,
    private router: Router,
    public errorService: ErrorLogHandlerService,
    private historyService: HistoryService
  ) { }

  ngOnInit() {
    this.user = this.authenticationService.currentUserValue;
    this.authenticationService.currentUser.subscribe((u) => {
      this.user = u;
      // console.log('User changed in menu: ' + JSON.stringify(u));
    });
    this.errorService.errors.subscribe(err => {
      this.lastCount = err.length;
      this.errorsShown = err;

      while (this.errorsShown.length > 5) {
        this.errorsShown.shift();
      }

      this.errorsShown = this.errorsShown.reverse();
    });
    this.router.events.subscribe(() => {
      this.updateMenuLayout();
    });
    this.historyService.entries.subscribe(h => this.history = h);
  }

  clearHistory() {
    this.historyService.clear();
  }

  searchElvisId() {
    if (this.elvisId.startsWith('p=') || this.elvisId.startsWith('p:')) {
      this.router.navigate(['/persons/' + this.elvisId.substring(2)]);
    } else if (this.elvisId.startsWith('i=') || this.elvisId.startsWith('i:')) {
      this.router.navigate(['/images/' + this.elvisId.substring(2)]);
    } else if (this.elvisId.startsWith('f=') || this.elvisId.startsWith('f:')) {
      this.router.navigate(['/faces/' + this.elvisId.substring(2)]);
    } else {
      this.router.navigate(['/images/elvis/' + this.elvisId]);
    }
    this.elvisId = '';
  }

  updateMenuLayout() {
    const current = location.pathname; // .split('/').slice(-1)[0].replace(/^\/|\/$/g, '');
    $('.navbar.horizontal-layout .nav-bottom .page-navigation .nav-item').each(function() {
      const $this = $(this);
      if (current === '') {
        // for root url
        if ($this.find('.nav-link').attr('href') && $this.find('.nav-link').attr('href').indexOf('index.html') !== -1) {
          $(this).find('.nav-link').parents('.nav-item').last().addClass('active');
          $(this).addClass('active');
        } else {
          $(this).removeClass('active');
        }
      } else {
        // for other url
        if ($this.find('.nav-link').attr('href') && $this.find('.nav-link').attr('href').endsWith(current)) {
          $(this).find('.nav-link').parents('.nav-item').last().addClass('active');
          $(this).addClass('active');
        } else {
          $(this).removeClass('active');
        }
      }
    });

    $('.navbar.horizontal-layout .navbar-menu-wrapper .navbar-toggler').on('click', () => {
      $('.navbar.horizontal-layout .nav-bottom').toggleClass('header-toggled');
    });

    // Navigation in mobile menu on click
    const navItemClicked = $('.page-navigation >.nav-item');
    navItemClicked.on('click', function(event) {
      if (window.matchMedia('(max-width: 991px)').matches) {
        if (!($(this).hasClass('show-submenu'))) {
          navItemClicked.removeClass('show-submenu');
        }
        $(this).toggleClass('show-submenu');
      }
    });

  }

  refreshToken() {
    this.showTokenLoader = true;
    const u = this.authenticationService.currentUser;

    this.cognitoService.refreshTokens(u.value).subscribe((user) => {
      const p = user.token.split('.')[1];
      this.showTokenLoader = false;
    }, (err) => {
      this.router.navigate(['/profile']);
    });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

}
