<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <h1>Scan S3 Bucket for files</h1>
      <div class="row">
        <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Define crawler task</h4>
              <p class="card-description">Enter details and verify settings to start crawler. Bucket can be in another
                AWS region and/or AWS Account (but of course Metatagger must be granted access)</p>
              <form class="form-sample">
                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="bucketName">Bucket Region</label>
                    <div class="col-sm-9">
                      <input name="bucketRegion" class="form-control" id="bucketRegion" type="text"
                        [(ngModel)]="settings.bucketRegion" [readOnly]="!authService.hasAdminAccess()"
                        placeholder="" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="bucketName">Bucket to scan</label>
                    <div class="col-sm-9">
                      <input name="bucketName" class="form-control" id="bucketName" type="text"
                        [(ngModel)]="settings.bucket" [readOnly]="!authService.hasAdminAccess()"
                        placeholder="" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="prefix">Prefix to scan (leave blank to scan from root)</label>
                    <div class="col-sm-9">
                      <input name="bucketPrefix" class="form-control" type="text" [(ngModel)]="settings.prefix"
                        [readOnly]="!authService.hasAdminAccess()" placeholder="" id="prefix" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                      <label class="col-sm-3 col-form-label" for="prefix">Max files to process</label>
                      <div class="col-sm-9">
                        <input name="maxFiles" class="form-control" id="maxFiles" type="number"
                        [(ngModel)]="settings.maxFiles" [readOnly]="!authService.hasAdminAccess()"
                        placeholder="" />

                        </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                      <label class="col-sm-3 col-form-label" for="prefix">Starting index</label>
                      <div class="col-sm-9">
                        <input name="start" class="form-control" id="start" type="number"
                        [(ngModel)]="settings.startIndex" [readOnly]="!authService.hasAdminAccess()"
                        placeholder="" />

                        </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                      <label class="col-sm-3 col-form-label" for="prefix">Reprocess known files</label>
                      <div class="form-check mx-sm-2">
                          <label class="form-check-label">
                            <input type="checkbox" name="reprocess" class="form-check-input" [(ngModel)]="settings.reprocessKnown">
                          </label>
                        </div>
                  </div>
                </div>
              </form>
              <button class="btn btn-outline-success mb-2" (click)="testSettings()" type="submit"
                [disabled]="!authService.hasAdminAccess()" style="margin: 0px;">Test Access</button>

              <button class="btn btn-outline-primary mb-2" (click)="startCrawler()" type="submit"
                [disabled]="!authService.hasAdminAccess() || !verified" style="margin: 0px;">Start
                Crawler</button>
              <div class="form-group">
                <app-loader *ngIf="showLoader"></app-loader>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">S3 Crawler History</h4>
              <app-loader *ngIf="!history"></app-loader>
              <table class="table table-stribed">
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>Region</th>
                    <th>Bucket</th>
                    <th>Prefix</th>
                    <th>Max Files</th>
                    <th>Started By</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let h of history">
                    <td>{{ h.started | date:'yyyy-MMM-dd HH:mm' | uppercase }} ({{ h.started | amTimeAgo }})</td>
                    <td>{{ h.bucketRegion }}</td>
                    <td>{{ h.bucket }}</td>
                    <td>{{ h.prefix }}</td>
                    <td>{{ h.maxFiles }}</td>
                    <td>{{ h.startedBy }}</td>
                    <td>
                      <button class="btn btn-outline-primary" (click)="setValues(h)">Prepare Rerun</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
