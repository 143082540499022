import { Injectable } from '@angular/core';
import { HistoryEntry } from '../model/history-entry';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  // tslint:disable-next-line: variable-name
  private _entries: HistoryEntry[] = [];
  entries: BehaviorSubject<HistoryEntry[]> = new BehaviorSubject<HistoryEntry[]>(this._entries);

  constructor() {
    const t = localStorage.getItem('element-history');
    if (t) {
      this._entries = JSON.parse(t);
      this.entries.next(this._entries);
    }

  }

  public addEntry(title: string, url: string) {
    if (this._entries.length === 0 || this._entries[0].title !== title) {
      this._entries = this._entries.filter(h => h.title !== title);

      this._entries.unshift({title, url});

      if (this._entries.length > 20) {
        this._entries = this._entries.splice(20);
      }

      this.entries.next(this._entries);
      localStorage.setItem('element-history', JSON.stringify(this._entries));
    }
  }

  public clear() {
    this._entries = [];
    this.entries.next(this._entries);
  }

}
