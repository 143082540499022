import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ImageRoute } from '../model/image-route';
import { MetataggerRouteProvider } from '../model/metatagger-route-provider';


@Injectable({
  providedIn: 'root'
})
export class ImageRoutingService {


  public url = 'v1';

  constructor(public http: HttpClient) { }

  listProviders(): Observable<MetataggerRouteProvider[]> {
      return this.http.get<MetataggerRouteProvider[]>(this.url + '/routes/providers?v=' + new Date().getTime());
  }

  listRoutes(): Observable<ImageRoute[]> {
      return this.http.get<ImageRoute[]>(this.url + '/routes?v=' + new Date().getTime());
  }

  createRoute(route: ImageRoute): Observable<string> {
    return this.http.put<string>(this.url + '/routes', route);
  }

  updateRoute(route: ImageRoute): Observable<string> {
      return this.http.post<string>(this.url + '/routes/' + route.id, route);
  }

  deleteRoute(id: string): Observable<string> {
      return this.http.delete<string>(this.url + '/routes/' + id, {});
  }

  getRoute(id: string): Observable<ImageRoute> {
      return this.http.get<ImageRoute>(this.url + '/routes/' + id + '?v=' + Date.now(), {});
  }

  getPaths(): Observable<ImageRoute[]> {
    //skal returnere en liste med alle paths 
    return this.http.get<ImageRoute[]>(this.url + '/routes?v=' + new Date().getTime());
    }

    getPath(id: number): Observable<ImageRoute[]> {
        //skal returnere en path ud fra dens id. 
        return this.http.get<ImageRoute[]>(this.url + '/routes?v=' + new Date().getTime());
    }
}
