import { Component, OnInit } from '@angular/core';
import { ProcessedImageService } from 'src/app/services/images.service';
// import { GridOptions } from 'ag-grid-community';
// import { MoreActionsComponent } from 'src/app/components/face-grid/more/more-actions/more-actions.component';
import { ProcessedImage } from 'src/app/model/ProcessedImage';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-list-not-found',
  templateUrl: './list-not-found.component.html',
  styleUrls: ['./list-not-found.component.css']
})
export class ListNotFoundComponent implements OnInit {

  columnDefs = [
    {headerName: 'ID', field: 'id',  sortable: true, filter: true },
    {headerName: 'Status', field: 'status',  sortable: true, filter: true },
    {headerName: 'Seen', field: 'created',  sortable: true, filter: true },
    {headerName: 'DAM Id', field: 'allerImageId',  sortable: true, filter: true},
    {headerName: 'Bucket', field: 'originalLocation.bucket',  sortable: true, filter: true},
    {headerName: 'Prefix', field: 'originalLocation.key', width: 250, sortable: true, filter: true },
  ];

  images: ProcessedImage[] = [];

  // gridOptions: GridOptions = {
  //   columnDefs: this.columnDefs,
  //   rowData: this.images,
  //   treeData: false,
  //   animateRows: true,
  //   defaultColDef: {
  //     resizable: true,
  //     suppressSizeToFit: true
  //   },
  //   rowSelection: 'single',
  //   groupSelectsChildren: true,
  //   suppressRowClickSelection: true,
  //   onGridReady: (params) => {
  //     params.api.resetRowHeights();
  //     params.api.sizeColumnsToFit();
  //   },
  //   onGridSizeChanged: (params) => {
  //     params.api.sizeColumnsToFit();
  //   },
  //   frameworkComponents: {
  //     moreRenderer: MoreActionsComponent
  //   }
  // };


  constructor(
    private imagesService: ProcessedImageService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Settings: Images not found in DAM');
    this.imagesService.getLatestImages('not-elvis', 'processed', 'desc', 'All', 100, 0).subscribe(
      (moreImg) => {
        this.images = moreImg;
      }
    );
  }

}
