import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TitleService } from 'src/app/services/title.service';
import $ from 'jquery';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ProcessedImageService } from 'src/app/services/images.service';

const ONE_DAY = 1000 * 60 * 60 * 24;


@Component({
  selector: 'app-elvis-push',
  templateUrl: './elvis-push.component.html',
  styleUrls: ['./elvis-push.component.css']
})
export class ElvisPushComponent implements OnInit {

  status: string;
  includeAlreadyPushed = false;
  includeNoFaces = false;
  internalFromDate = new Date(Date.now() - (60 * ONE_DAY));
  internalToDate = new Date(Date.now());
  dateFrom: NgbDateStruct = { year: this.internalFromDate.getFullYear(), month: this.internalFromDate.getMonth() + 1, day: this.internalFromDate.getDate() };
  dateTo: NgbDateStruct = { year: this.internalToDate.getFullYear(), month: this.internalToDate.getMonth() + 1, day: this.internalToDate.getDate() };
  showLoader = false;

  toggleShowLoader = false;
  jobsEnabled = false;

  constructor(
    public authService: AuthenticationService,
    private notificationService: NotificationsService,
    private imageService: ProcessedImageService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Settings: Elvis Push');
    this.fetchJobEnabledStatus();
  }

  startPushJob() {
    this.internalFromDate = new Date(this.dateFrom.year, this.dateFrom.month - 1, this.dateFrom.day);
    this.internalToDate = new Date(this.dateTo.year, this.dateTo.month - 1, this.dateTo.day);

    this.showLoader = true;
    this.imageService.forcePushImagesToElvis(this.includeAlreadyPushed, this.includeNoFaces, this.internalFromDate, this.internalToDate).subscribe(() => {
      this.showLoader = false;
      this.status = 'Job started';
    });
  }

  fetchJobEnabledStatus() {
    this.toggleShowLoader = true;
    this.imageService.fetchPushJobStatus().subscribe((e) => {
      this.jobsEnabled = e.enabled;
      this.toggleShowLoader = false;
    });
  }

  toggleJobStatus() {
    this.toggleShowLoader = true;
    this.imageService.changePushJobStatus(this.jobsEnabled).subscribe((e) => {
      this.toggleShowLoader = false;
    });
  }

}
