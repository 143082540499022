<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <h1>{{title}}</h1>
      <div class="row">
        <div class="col-6 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <app-loader *ngIf="!logs1"></app-loader>

              <div class="col-12">
                <div class="form-group row">
                  <label class="col-3 col-form-label" for="faceId">Log Group</label>
                  <div class="col-9">
                    <input name="logGroup1" class="form-control" id="logGroup1" type="text" [(ngModel)]="logGroup1"
                      [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group row">
                  <label class="col-3 col-form-label" for="imageId">Log Stream</label>
                  <div class="col-9">
                    <input name="logStream1" class="form-control" id="logStream1" type="text" [(ngModel)]="logStream1"
                      [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group row">
                  <label class="col-3 col-form-label" for="personId">Request Id</label>
                  <div class="col-9">
                    <input name="requestId1" class="form-control" id="requestId1" type="text" [(ngModel)]="requestId1"
                    [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <button class="btn btn-outline-success mb-2" (click)="search1()" type="submit" [disabled]="!authService.hasHandleBugsAccess()" style="margin: 0px;">Search</button>
              </div>
              <table class="table table-stribed">
                <thead>
                  <tr>
                    <th style="width: 300px;">Time</th>
                    <th>Event</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let r of logs1;let j = index">
                    <td>{{ r.time | date:'yyyy-MMM-dd HH:mm:ss' | uppercase }} ({{ r.time | amTimeAgo }})</td>
                    <td>
                      <div *ngIf="r.message.length > maxLength" style="cursor: pointer;" id="partial_1_{{j}}" (click)="showDetails(1,j)">
                          <pre style="color: #cec6c6">{{r.message.substring(0, maxLength)}} ...
          [click to expand]</pre>
                        </div>
                        <div *ngIf="r.message.length > maxLength" id="full_1_{{j}}" [hidden]="true">
                          <a style="cursor: pointer;" (click)="showDetails(1, j)">Collapse</a>
                          <pre style="color: #cec6c6">{{r.message}}</pre>
                          <a style="cursor: pointer;" (click)="showDetails(1, j)">Collapse</a>
                        </div>
                        <div *ngIf="r.message.length <= maxLength"><pre style="color: #cec6c6">{{r.message}}</pre></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-6 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <app-loader *ngIf="!logs2"></app-loader>

              <div class="col-12">
                <div class="form-group row">
                  <label class="col-3 col-form-label" for="faceId">Log Group</label>
                  <div class="col-9">
                    <input name="logGroup2" class="form-control" id="logGroup2" type="text" [(ngModel)]="logGroup2"
                      [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group row">
                  <label class="col-3 col-form-label" for="imageId">Log Stream</label>
                  <div class="col-9">
                    <input name="logStream2" class="form-control" id="logStream2" type="text" [(ngModel)]="logStream2"
                      [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group row">
                  <label class="col-3 col-form-label" for="personId">Request Id</label>
                  <div class="col-9">
                    <input name="requestId2" class="form-control" id="requestId2" type="text" [(ngModel)]="requestId2"
                    [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <button class="btn btn-outline-success mb-2" (click)="search2()" type="submit" [disabled]="!authService.hasHandleBugsAccess()" style="margin: 0px;">Search</button>
              </div>
              <table class="table table-stribed">
                <thead>
                  <tr>
                    <th style="width: 300px;">Time</th>
                    <th>Event</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let r of logs2;let j = index">
                    <td>{{ r.time | date:'yyyy-MMM-dd HH:mm:ss' | uppercase }} ({{ r.time | amTimeAgo }})</td>
                    <td>
                      <div *ngIf="r.message.length > maxLength" style="cursor: pointer;" id="partial_2_{{j}}" (click)="showDetails(2, j)">
                          <pre style="color: #cec6c6">{{r.message.substring(0, maxLength)}} ...
          [click to expand]</pre>
                        </div>
                        <div *ngIf="r.message.length > maxLength" id="full_2_{{j}}" [hidden]="true">
                          <a style="cursor: pointer;" (click)="showDetails(2, j)">Collapse</a>
                          <pre style="color: #cec6c6">{{r.message}}</pre>
                          <a style="cursor: pointer;" (click)="showDetails(2, j)">Collapse</a>
                        </div>
                        <div *ngIf="r.message.length <= maxLength"><pre style="color: #cec6c6">{{r.message}}</pre></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>