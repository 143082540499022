<div class="container-fluid page-body-wrapper full-page-wrapper">
  <div class="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
    <div class="row w-100 mx-auto">
      <div class="col-lg-4 mx-auto">
        <img src="assets/images/metatagger-logo-01.png" width="400" />
        <h3>{{ title }}</h3>
        <div class="auto-form-wrapper">
          <div class="form-group" *ngIf="firstFactor">
            <label class="label">Username</label>
            <div class="input-group">
              <input type="text" class="form-control" placeholder="" [(ngModel)]="username" />
              <div class="input-group-append">
                <span class="input-group-text"><i class="icon-check-success" *ngIf="username.length > 1"></i></span>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="changePassword">
            <label class="label">Fullname</label>
            <div class="input-group">
              <input type="text" class="form-control" placeholder="" [(ngModel)]="fullname" />
              <div class="input-group-append">
                <span class="input-group-text"><i class="icon-check-success" *ngIf="fullname.length > 1"></i></span>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="firstFactor || confirmPassword">
            <label class="label">Password</label>
            <div class="input-group">
              <input type="password" class="form-control" placeholder="" [(ngModel)]="password"
                (keyup)="validatePassword($event)" />
              <div class="input-group-append">
                <span class="input-group-text"><i class="icon-check-success" *ngIf="password.length > 3"></i></span>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="changePassword || confirmPassword">
            <label class="label">Repeat Password</label>
            <div class="input-group">
              <input type="password" class="form-control" placeholder="" [(ngModel)]="verifyPassword"
                (keyup)="validatePassword()" />
              <div class="input-group-append">
                <span class="input-group-text"><i class="icon-check-success"
                    *ngIf="password.length > 2 && password == verifyPassword"></i></span>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="askForMFA">
            <label class="label">MFA Code {{mfaDetails}}</label>
            <div class="input-group">
              <input type="text" class="form-control" placeholder="" [(ngModel)]="mfaCode" (keyup.enter)="doLogin()"
                autofocus />
              <div class="input-group-append">
                <span class="input-group-text"><i class="icon-check-success" *ngIf="mfaCode.length == 6"></i></span>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="confirmPassword">
            <label class="label">Verification Code</label>
            <div class="input-group">
              <input type="text" class="form-control" placeholder="" [(ngModel)]="verificationCode" />
              <div class="input-group-append">
                <span class="input-group-text"><i class="icon-check-success" *ngIf="mfaCode.length == 6"></i></span>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="changePassword || confirmPassword">
            <label class="label">Password Validation</label>
            <div class="form-group d-flex justify-content-between">
              <div class="form-check form-check-flat mt-0">
                <label class="form-check-label">
                  <i class="icon-check-success font-weight-bold ml-auto px-1 py-1 text-info"
                    *ngIf="password.length > 2 && password == verifyPassword"></i>
                  Password is repeated
                </label>
              </div>
              <div class="form-check form-check-flat mt-0">
                <label class="form-check-label">
                  <i class="icon-check-success font-weight-bold ml-auto px-1 py-1 text-info" *ngIf="hasLowerCase"></i>
                  Has lowercase
                </label>
              </div>
              <div class="form-check form-check-flat mt-0">
                <label class="form-check-label">
                  <i class="icon-check-success font-weight-bold ml-auto px-1 py-1 text-info" *ngIf="hasUpperCase"></i>
                  Has uppercase
                </label>
              </div>
              <div class="form-check form-check-flat mt-0">
                <label class="form-check-label">
                  <i class="icon-check-success font-weight-bold ml-auto px-1 py-1 text-info" *ngIf="hasNumber"></i>
                  Has number
                </label>
              </div>
            </div>
          </div>
          <div class="text-center" *ngIf="message && message.length > 0">
            <label class="label" style="color: #FF0000">{{ message }}</label>
          </div>
          <div class="form-group">
            <button class="btn btn-primary btn-block" (click)="doLogin()" *ngIf="!loginInProgress"
              [disabled]="!buttonEnabled || !environment" type="submit" style="margin: 0px;">{{ buttonText }}</button>
            <app-loader *ngIf="loginInProgress"></app-loader>
          </div>
          <div class="form-group d-flex justify-content-between">
            <div class="form-check form-check-flat mt-0">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" [(ngModel)]="rememberMe">
                Keep me signed in
              </label>
            </div>
            <div class="text-center">
              <a class="text-small forgot-password" *ngIf="askForMFA" (click)="resendMfa()">Resend MFA</a>
              <a class="text-small forgot-password" data-toggle="modal" data-target="#passwordModal"
                *ngIf="firstFactor && username.length > 1" href="#">Forgot Password</a>
            </div>
            <div class="modal fade" id="passwordModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel-3"
              aria-hidden="true">
              <div class="modal-dialog modal-sm" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel-3">Reset Password</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <p>Reset password for user {{ username }}?</p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-success" (click)="forgotPassword()"
                      data-dismiss="modal">Reset</button>
                    <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="footer-text text-center"><span
            class="text-muted d-block text-center text-sm-left d-sm-inline-block">Created by <a
              href="https://www.keycore.dk/" target="_blank">KeyCore</a> {{ year}} - All rights reserved -
            Advanced and intelligent AWS solutions <i class="icon-heart text-danger"></i></span></p>
      </div>
    </div>
  </div>
</div>