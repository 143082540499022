import { Component, OnInit } from '@angular/core';
import { ProcessedImageService } from 'src/app/services/images.service';
import { KnownName } from 'src/app/model/KnownName';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-known-names',
  templateUrl: './known-names.component.html',
  styleUrls: ['./known-names.component.css']
})
export class KnownNamesComponent implements OnInit {

  showLoader = true;
  knownNames: KnownName[];

  constructor(
    public authService: AuthenticationService,
    private imageService: ProcessedImageService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Settings: Known Names');
    this.imageService.getKnownNames().subscribe((k) => {
      this.showLoader = false;
      this.knownNames = k;
    });
  }

}
