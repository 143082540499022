

export class SearchFacesRequest {

    public faceConfidence: number;
    public sharpness: number;
    public brightness: number;
    public humanConfidence: number;

    public limit: number;
    public offset: number;

    public sortDirectionAsc: boolean;
    public useOrInsteadOfAnd: boolean;
    public valuesAreMinimum: boolean;

    public boxSize: number;
    public relativeBoxSize: number;

    public orderBy: 'personConfidence' | 'person' | 'creation' | 'faceConfidence' | 'sharpness' | 'brightness';

    public rawSearch?: string;
}
