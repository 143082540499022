<div class="container-fluid page-body-wrapper full-page-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
      <div class="col-12">
        <img src="/assets/images/aller_poc_er_diagram.png">
      </div>
      </div>
    </div>
  </div>
</div>